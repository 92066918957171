import React, {useMemo, useState, useEffect} from 'react';
import './level-picker-styles.css';
import {getDisplayLabel} from 'util/helper';
import ChevronVertical from "../ChevronVertical";

const DEFAULT_LEVELS_PER_PAGE = 10;
const chevronSize = {
    small: {width: 15, height: 8},
    large: {width: 30, height: 15},
};

const LevelPicker = ({
                         levels,
                         levelsPerPage = DEFAULT_LEVELS_PER_PAGE,
                         onLevelClick,
                         brandingColor,
                         size = 'small',
                         hasBackground = true,
                         selectedFloor,
                     }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [selectedLevel, setSelectedLevel] = useState(levels[0]);
    const numberOfPages = Math.ceil(levels.length / levelsPerPage);
    const disableUpButton = currentPage < 1;
    const disableDownButton = currentPage >= numberOfPages - 1;

    const handleArrowClick = (isDown) => {
        setCurrentPage(prevState => (isDown ? prevState + 1 : prevState - 1));
    };

    const levelMap = useMemo(
        () =>
            Array.from({length: numberOfPages}, (_, index) => {
                if (index !== 0 && index + 1 === numberOfPages) {
                    return levels.slice(levels.length - levelsPerPage);
                }
                const startIndex = index * levelsPerPage;
                return levels.slice(startIndex, startIndex + levelsPerPage);
            }),
        [numberOfPages, levels, levelsPerPage],
    );

    const handleLevelClick = (level, index) => {
        setSelectedLevel(level);
        onLevelClick(level, index);
    };

    useEffect(() => {
        if (isNaN(parseInt(selectedFloor))) return;
        const selectedLevelIndex = levels.indexOf(selectedFloor);
        const correctPage = Math.floor(selectedLevelIndex / levelsPerPage);
        setCurrentPage(correctPage);
        setSelectedLevel(selectedFloor);
    }, [selectedFloor, levelsPerPage]);

    return (
        <div className={`levelPickerContainer ${size} ${hasBackground ? 'hasBackground' : ''}`}>
            <label>Floors</label>
            <button onClick={() => handleArrowClick(false)} disabled={disableUpButton}>
                <ChevronVertical {...chevronSize[size]} orientation="up" disabled={disableUpButton}/>
            </button>
            {levelMap[currentPage] &&
                levelMap[currentPage].map((level, index) => (
                    <span
                        key={`level-${index}`}
                        onClick={() => handleLevelClick(level, index)}
                        style={{color: selectedLevel === level ? `${brandingColor}` : 'black'}}
                    >
            {getDisplayLabel(level)}
          </span>
                ))}
            <button onClick={() => handleArrowClick(true)} disabled={disableDownButton}>
                <ChevronVertical {...chevronSize[size]} orientation="down" disabled={disableDownButton}/>
            </button>
        </div>
    );
};

export default LevelPicker;
