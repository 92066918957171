import { createUseStyles } from "react-jss";
import { motion, AnimatePresence } from "framer-motion";
import React, { useEffect, useMemo, useState } from "react";
import ImageMapper from "react-img-mapper";

import useGlobalUnitsFilter from "hooks/useGlobalUnitsFilter";
import LevelPicker from "./LevelPicker/LevelPicker";

const useStyles = createUseStyles(
  {
    wrapper: (props) => ({
      display: "flex",
      gap: 10,
      opacity: "0",
      alignItems: "center",
      height: props.detailCard || props.landscape ? "auto" : "100%",
      "&.show": {
        opacity: "100%",
      },
    }),
    navbar: (props) => ({
      display: "flex",
      flexDirection: "column",
      gap: 5,

      "& button": {
        border: `1px solid ${props.ThemeData.colours.branding}`,
        background: props.ThemeData.colours.white,
        fontSize: 12,
        padding: 5,
        borderRadius: 5,
        color: props.ThemeData.colours.branding,
        cursor: "pointer",

        "&:hover, &:active, &.active": {
          backgroundColor: props.ThemeData.colours.branding,
          color: props.ThemeData.colours.white,
        },
      },
    }),
  },
  { name: "SiteMap" }
);

const SiteMap = ({ project, ...props }) => {
  const { ThemeData } = project;
  const classes = useStyles({ ThemeData, ...props });

  const isUnit = !props.floorplan;
  const building = project.buildings.filter(
    (b) => b.siteMaps && b.siteMaps.length > 0
  )[0];

  const availabilityColours = props.availabilityColours
    ? Object.fromEntries(
        Object.entries(props.availabilityColours).map(([key, value]) => [
          key,
          `#${value}`,
        ])
      )
    : undefined;

  const sitemaps =
    building &&
    building.siteMaps
      .filter((map) => map.areas.length > 0)
      .map((map) => {
        const areas = map.areas.map((coord) => {
          const unit = project.units.find(
            (unit) => unit.threeDId.toString() === coord.id.toString()
          );

          let fillColor;

          if (availabilityColours) {
            fillColor =
              availabilityColours[unit?.status] || availabilityColours.sold;
          } else {
            fillColor =
              unit?.status === "available"
                ? ThemeData.colours.siteMapHighlightGreen ||
                  "rgba(150, 230, 130, 0.6)"
                : ThemeData.colours.siteMapHighlightRed ||
                  "rgba(255, 150, 140, 0.6)";
          }
          return { ...coord, id: parseInt(coord.id), fillColor };
        });
        return { ...map, areas };
      });

  const floorPlanUnits = useMemo(() => {
    return project.units.filter(
      (unit) => unit.floorPlan.name === props?.floorplan?.name
    );
  }, []);

  const levels = [...new Set(props.floors?.map((floor) => floor?.value))];
  let sortedLevels = levels.sort((a, b) => a - b);

  let floorsLevels = sitemaps?.map((item) => {
    return item.floor;
  });

  const [selectedFloor, setSelectedFloor] = useState(
    isNaN(parseInt(props.selectedFloor))
      ? isUnit
        ? sitemaps?.[0]?.floor
        : levels?.[0]
      : props.selectedFloor
  );
  const [mapAreas, setMapAreas] = useState([]);
  const globalUnitsFilter = useGlobalUnitsFilter();

  useEffect(() => {
    if (!sitemaps || sitemaps.length === 0) return;
    let currentFloor = isNaN(parseInt(props.selectedFloor))
      ? selectedFloor
      : props.selectedFloor;
    const floorAreas = sitemaps?.filter((sm) => sm.floor === currentFloor)?.[0];
    const initAreas = floorAreas ? floorAreas.areas : [];

    if (
      props.interactModalFrom === "filter" &&
      props.unitCustomIdsFiltered.length > 0
    ) {
      const highlightedAreas = initAreas.map((area) => {
        return props.unitCustomIdsFiltered.indexOf(area.id) === -1
          ? { ...area, disabled: false }
          : { ...area, preFillColor: area.fillColor, disabled: false };
      });
      setTimeout(() => setMapAreas(highlightedAreas), 500);
    } else if (props.selectedUnitId !== -1) {
      let highlightedAreas = [];
      if (isUnit) {
        highlightedAreas = initAreas.map((area) => {
          return area.id === props.selectedUnitId
            ? { ...area, preFillColor: area.fillColor }
            : area;
        });
      } else {
        // is floorPlan
        const threeDIds = floorPlanUnits.map((unit) => unit.threeDId);
        highlightedAreas = floorAreas?.areas
          .filter((area) => threeDIds.indexOf(area.id) !== -1)
          .map((area) => ({
            ...area,
            preFillColor: area.fillColor,
            disabled: true,
          }));
      }

      if (props.interactModalFrom !== "sitePlanNav") {
        const gotoFloor = project.units.filter(
          (unit) => unit.threeDId === props.selectedUnitId
        )[0];
        if (gotoFloor) {
          setSelectedFloor(gotoFloor.floor);
        }
      }
      setTimeout(() => setMapAreas(highlightedAreas), 500);
    } else if (props.selectedUnitId === -1) {
      setTimeout(() => setMapAreas(initAreas), 500);
    }
  }, [
    props.selectedFloor,
    selectedFloor,
    props.selectedUnitId,
    props.unitCustomIdsFiltered,
  ]);

  console.log("Selected Floor: ", selectedFloor);

  const siteMapClick = (area) => {
    if (props.unitCustomIdsFiltered.length > 0) {
      globalUnitsFilter.resetFilter();
      props.setUnitCustomIdsFiltered([]);
    }
    props.setInteractModalFrom("sitePlan");
    props.setSelectedUnitId(area.id);
    props?.setIsUnitCardSlideoutOpenToOpen();
  };

  const switchFloor = (floor) => {
    if (floor === selectedFloor) return;

    props?.setSelectedFloor?.(floor);
    setMapAreas([]);
    setSelectedFloor(floor);
    if (props.interactModalFrom !== "filter") {
      props.setInteractModalFrom?.("sitePlanNav");
    }
  };

  const renderImageMapper = (floor, areas, showNav = true) => {
    const imageSource =
      sitemaps && sitemaps.filter((map) => map.floor === floor)[0];
    const ratio = imageSource?.width / imageSource?.height;
    const width = parseInt(ratio * props.browserHeight);

    return (
      <div
        className={`${classes.wrapper} ${
          props.isProximaLoaded || props.siteMapWidget || props.detailCard
            ? "show"
            : ""
        }`}
      >
        <AnimatePresence>
          <motion.div layoutId={floor}>
            {imageSource && (
              <ImageMapper
                src={imageSource.link}
                map={{ name: project.name, areas }}
                onClick={(area) => siteMapClick(area)}
                stayHighlighted={true}
                responsive={true}
                parentWidth={
                  props.imageWidth
                    ? props.imageWidth
                    : props.siteMapWidth < width
                    ? props.siteMapWidth
                    : width
                }
                disabled={!!props.detailCard}
              />
            )}
          </motion.div>
        </AnimatePresence>
        {showNav &&
          building.type !== "townhomes" &&
          sitemaps &&
          sitemaps.length > 1 && (
            <div style={{ marginLeft: 30 }}>
              <LevelPicker
                hasBackground={false}
                levels={levels.length > 1 ? sortedLevels : floorsLevels}
                brandingColor={ThemeData.colours.branding}
                onLevelClick={(level) => switchFloor(level)}
                selectedFloor={selectedFloor}
                levelsPerPage={3}
              />
            </div>
          )}
      </div>
    );
  };

  useEffect(() => {
    const lvls = levels.length > 1 ? sortedLevels : floorsLevels;
    switchFloor(lvls[0]);
  }, []);

  if (!sitemaps) return <></>;

  if (props.detailCard) {
    return renderImageMapper(
      isNaN(parseInt(props.selectedFloor))
        ? selectedFloor
        : props.selectedFloor,
      mapAreas
    );
  } else {
    return renderImageMapper(
      isNaN(parseInt(props.selectedFloor))
        ? selectedFloor
        : props.selectedFloor,
      mapAreas,
      props.showNav
    );
  }
};

export default SiteMap;
