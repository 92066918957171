import React, { useState, useContext } from 'react';

import { createUseStyles } from 'react-jss';

import { motion } from 'framer-motion';

import ConstantData from 'data/ConstantData';
import SiteConfigData from 'data/SiteConfigData';
import UnitCardsParent from './UnitCardsParent';
import UnitCardsParentCarousel from './UnitCardsParentCarousel';
import UnitDetailCardGeneric from './UnitDetailCardGeneric';
import UnitDetailTableItem from './UnitDetailTableItem';
import UnitDetailPane from './UnitDetailPane';
import VirtualTour from './VirtualTour';
import preventClickEventPassthrough from 'util/preventClickEventsPassthrough';
import UnitFcnContext from 'contexts/UnitFcnContext';
import ViewStudy from './ViewStudy';
import FilterPane from './FilterPane';
import { portraitMode } from 'util/helper';
import FloorPlanStore from '../store/floorPlanStore';

const useStyles = createUseStyles(
  {
    wrapper: (props) => ({
      height: props.isPortraitMode ? '100%' : '100%',
      width: '100%',
      boxSizing: 'border-box',
      overflow: 'hidden',
      color: props.ThemeData.colours.white,
      backgroundColor: props.ThemeData.colours.white,
      letterSpacing: '.025em',
    }),
    contentContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      overflow: 'hidden',
    },
    topRightContainer: {
      position: 'absolute',
      top: 60,
      right: 60,
      display: 'flex',
      alignItems: 'center',
    },
    closeX: {
      marginTop: 10,
      marginLeft: 50,
      cursor: 'pointer',
    },
    sendToEmailButton: {
      display: 'inline-block',
      width: 150,
      fontSize: 12,
      letterSpacing: '.1em',
    },
    savedHomesTitle: {
      textAlign: 'left',
      fontSize: 24,
      letterSpacing: '.15em',
    },
    top: {
      flex: '0 0 auto',
    },
    addHomesButton: {
      width: 220,
      fontSize: 12,
      letterSpacing: '.1em',
      borderRadius: 25,
    },
    middle: {
      flex: '1 0 auto',
    },
    unitCard: {
      color: (props) => props.ThemeData.colours.black,
    },
    listModeWrapper: {
      height: 'calc( var( --app-height) - var( --header-height ))',
    },
    listModeUnit: {
      height: '100%',
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      scrollbarWidth: 'none',
    },
    tableWrapper: {
      padding: '0 0 0 10px',
    },
    tableItemRowsWrapper: {
      flexDirection: 'column',
      flexWrap: 'nowrap',
      justifyContent: 'start',
      paddingTop: 0,
    },
    tableItemRow: {
      flex: '0 0 auto',
    },
    bottom: (props) => ({
      flex: '0 0 auto',
      display: 'flex',
      boxSizing: 'border-box',
      paddingTop: 25,
      height: 130,
      borderTop: `1px ${props.ThemeData.colours.white50} solid`,
    }),
  },
  {
    name: 'UnitsGeneric',
  },
);

const RenderUnitsGeneric = (
  isPortraitMode,
  classes,
  error,
  isOpen,
  toggleIsOpen,
  hooks,
  props,
) => {
  if (error) {
    console.log('RenderFloorplans', error.message);
  }

  const { project } = props;
  const [doShowFilter, setDoShowFilter] = useState(false);
  const [floorplanVirtualTourUrl, setFloorplanVirtualTour] = useState(null);
  const [floorplanViewStudyUrl, setFloorplanViewStudy] = useState(null);

  const {
    selectedUnitId,
    setSelectedUnitId,
    setSelectedFloorplanId,
    setIsUnitDetailOpen,
    toggleIsUnitDetailOpen,
  } = useContext(UnitFcnContext);

  const [unitFilterViewMode, setUnitFilterViewMode] = useState(
    ConstantData.unitFilterViewModes.card,
  );
  const { projectSlug } = FloorPlanStore();

  // const {
  // 	isVirtualTourOpen,
  // 	toggleIsVirtualTourOpen,
  // } = useOpenClosePane( "VirtualTour" )

  const virtualTourUrl = floorplanVirtualTourUrl
    ? floorplanVirtualTourUrl
    : project.settings.threeDUrl;

  const viewStudyUrl = floorplanViewStudyUrl || props.viewUrl;

  const renderUnitCardsParent = () => {
    return props.calledByCarousel ? (
      <UnitCardsParentCarousel
        project={project}
        unitFilterViewMode={unitFilterViewMode}
        setUnitFilterViewMode={setUnitFilterViewMode}
        selectedUnitId={selectedUnitId}
        setSelectedUnitId={setSelectedUnitId}
        setSelectedFloorplanId={setSelectedFloorplanId}
        setSelectedUnitData={props.setSelectedUnitData}
        doShowFilter={doShowFilter}
        setDoShowFilter={setDoShowFilter}
        oneTile={props.oneTile}
        twoTile={props.twoTile}
        addFilteredResult={props.addFilteredResult}
        interactModalFrom={props.interactModalFrom}
        setInteractModalFrom={props.setInteractModalFrom}
      >
        {(className, dataUnit) => (
          <UnitDetailCardGeneric
            project={project}
            className={className}
            dataUnit={dataUnit}
            toggleUnitDetailPaneFcn={toggleIsUnitDetailOpen}
            onClickLeftButton={() => {
              setIsUnitDetailOpen(true);
            }}
            toggleVirtualTourPaneFcn={
              (props.isToUseEmbeddedVirtualTour &&
                props.toggleIsVirtualTourOpen) ||
              props.toggleIsVirtualTourOpen
            }
            setFloorplanVirtualTour={setFloorplanVirtualTour}
            setFloorplanViewStudy={setFloorplanViewStudy}
            hooks={hooks}
            isToAllowSelection={props.isToAlwaysAllowSelection}
            toggleIsViewStudySlideUpOpenFcn={
              props.toggleIsViewStudySlideUpOpenFcn
            }
            selectedUnitId={selectedUnitId}
            setSelectedUnitId={setSelectedUnitId}
            setSelectedFloorplanId={setSelectedFloorplanId}
            setSelectedUnitData={props.setSelectedUnitData}
            setInteractModalFrom={props.setInteractModalFrom}
            twoTile={props.twoTile}
            calledByCarousel={props.calledByCarousel}
          />
        )}
      </UnitCardsParentCarousel>
    ) : (
      <UnitCardsParent
        project={project}
        buildingId={props.buildingId ?? project.buildings[0].id}
        unitFilterViewMode={unitFilterViewMode}
        setUnitFilterViewMode={setUnitFilterViewMode}
        selectedUnitId={selectedUnitId}
        setSelectedUnitId={setSelectedUnitId}
        setSelectedFloorplanId={setSelectedFloorplanId}
        setSelectedUnitData={props.setSelectedUnitData}
        doShowFilter={doShowFilter}
        setDoShowFilter={setDoShowFilter}
        oneTile={props.oneTile}
        twoTile={props.twoTile}
        addFilteredResult={props.addFilteredResult}
        calledByFloorplan={props.calledByFloorplan}
        isSiteMapPage={props.isSiteMapPage}
      >
        {(className, dataUnit) => (
          <UnitDetailCardGeneric
            project={project}
            className={className}
            dataUnit={dataUnit}
            toggleUnitDetailPaneFcn={toggleIsUnitDetailOpen}
            onClickLeftButton={() => setIsUnitDetailOpen(true)}
            toggleVirtualTourPaneFcn={
              (props.isToUseEmbeddedVirtualTour &&
                props.toggleIsVirtualTourOpen) ||
              props.toggleIsVirtualTourOpen
            }
            setFloorplanVirtualTour={setFloorplanVirtualTour}
            setFloorplanViewStudy={setFloorplanViewStudy}
            hooks={hooks}
            isToAllowSelection={props.isToAlwaysAllowSelection}
            toggleIsViewStudySlideUpOpenFcn={
              props.toggleIsViewStudySlideUpOpenFcn
            }
            selectedUnitId={selectedUnitId}
            setSelectedUnitId={setSelectedUnitId}
            setSelectedFloorplanId={setSelectedFloorplanId}
            setSelectedUnitData={props.setSelectedUnitData}
            setInteractModalFrom={props.setInteractModalFrom}
            twoTile={props.twoTile}
            calledByFloorplan={props.calledByFloorplan}
          />
        )}
      </UnitCardsParent>
    );
  };
  return (
    <>
      {!isPortraitMode && (
        <FilterPane
          project={project}
          doShowFilter={doShowFilter}
          setDoShowFilter={setDoShowFilter}
          interactModalFrom={props.interactModalFrom}
          setInteractModalFrom={props.setInteractModalFrom}
          setSelectedUnitId={setSelectedUnitId}
        />
      )}
      <motion.div
        className={classes.wrapper}
        onMouseDown={(e) => preventClickEventPassthrough(e)}
        onMouseUp={(e) => preventClickEventPassthrough(e)}
      >
        {unitFilterViewMode === ConstantData.unitFilterViewModes.card && projectSlug !== 'pangea' ? (
          renderUnitCardsParent()
        ) : (
          <UnitCardsParent
            project={project}
            buildingId={props.buildingId ?? project.buildings[0].id}
            oneTile={props.oneTile}
            twoTile={props.twoTile}
            fullItemsWrapperClassName={classes.tableWrapper}
            childWrapperClassName={classes.tableItemRowsWrapper}
            childClassName={classes.tableItemRow}
            unitFilterViewMode={unitFilterViewMode}
            setUnitFilterViewMode={setUnitFilterViewMode}
            unitTableItemColumnsToDisplay={SiteConfigData.unitTableItemColumns}
            doShowFilter={doShowFilter}
            setDoShowFilter={setDoShowFilter}
            doSelectFirstItem={true}
            selectedUnitId={selectedUnitId}
            setSelectedUnitId={setSelectedUnitId}
            setSelectedFloorplanId={setSelectedFloorplanId}
            setSelectedUnitData={props.setSelectedUnitData}
            setInteractModalFrom={props.setInteractModalFrom}
            calledByFloorplan={props.calledByFloorplan}
            isSiteMapPage={props.isSiteMapPage}
            dualPaneLeftPane={
              props.isToShowDualPaneForTableDisplay && (
                <div className={classes.listModeUnit}>
                  {props.selectedUnitData && (
                    <UnitDetailPane
                      project={project}
                      dataUnit={props.selectedUnitData}
                      setSelectedUnitId={setSelectedUnitId}
                      setSelectedFloorplanId={setSelectedFloorplanId}
                      toggleIsUnitDetailOpen={toggleIsUnitDetailOpen}
                    />
                  )}
                </div>
              )
            }
          >
            {(className, dataUnit, itemIdx, isFirstItem, toggleSavedHome) => (
              <UnitDetailTableItem
                project={project}
                className={className}
                dataUnit={dataUnit}
                itemIdx={itemIdx}
                isFirstItem={isFirstItem}
                toggleSavedHome={toggleSavedHome}
                unitTableItemColumnsToDisplay={
                  SiteConfigData.unitTableItemColumns
                }
                unitFilterViewMode={unitFilterViewMode}
                isToAllowSelection={true}
                selectedUnitId={selectedUnitId}
                setSelectedUnitId={setSelectedUnitId}
                setSelectedFloorplanId={setSelectedFloorplanId}
                setSelectedUnitData={props.setSelectedUnitData}
                setInteractModalFrom={props.setInteractModalFrom}
                toggleIsUnitDetailOpen={toggleIsUnitDetailOpen}
              />
            )}
          </UnitCardsParent>
        )}
      </motion.div>
      {props.isToUseEmbeddedVirtualTour && virtualTourUrl && (
        <VirtualTour
          project={project}
          isOpen={props.isVirtualTourOpen}
          src={virtualTourUrl}
          toggleVirtualTourFcn={props.toggleIsVirtualTourOpen}
          doUseHeader={false}
        />
      )}
      {viewStudyUrl && (
        <ViewStudy
          project={project}
          isOpen={props.isViewStudySlideUpOpen}
          toggleViewStudyFcn={props.toggleIsViewStudySlideUpOpen}
          viewUrl={viewStudyUrl}
        />
      )}
    </>
  );
};

const UnitsGeneric = ({ isOpen, toggleIsOpen, ...props }) => {
  const isPortraitMode = portraitMode();
  const classes = useStyles({
    ThemeData: props.project.ThemeData,
    isPortraitMode,
    ...props,
  });
  return RenderUnitsGeneric(
    isPortraitMode,
    classes,
    null,
    isOpen,
    toggleIsOpen,
    null,
    props,
  );
};

UnitsGeneric.defaultProps = {
  isToAlwaysAllowSelection: false,
  isToShowDualPaneForTableDisplay: true,
  isToUseEmbeddedVirtualTour: true,
  overrideBackgroundColor: null,
};

export default UnitsGeneric;
