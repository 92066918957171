import React, {
  useState,
} from 'react';

import {
  createUseStyles,
} from 'react-jss';

import {
  motion,
} from 'framer-motion';

import HeaderSimple from './HeaderSimple';
import HeaderComponents from './HeaderComponents';

import navArrowLeftDark from '../img/nav_arrow_left_dark.svg';
import { portraitMode } from 'util/helper';

import 'swiper/swiper.scss';
import 'swiper/modules/pagination/pagination.scss';
import 'swiper/modules/navigation/navigation.scss';
import {
  Controller,
  Pagination,
  Navigation,
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';

const useStyles = createUseStyles(
  {
    wrapper: props => ({
      position: 'fixed',
      top: props.doUseHeader ? 0 : props.isPortraitMode ? 'var(--portrait-header-height)' : '100px',
      left: 0,
      right: 0,

      width: '100%',
      height: props.isPortraitMode ? 'calc( 100vh - var(--portrait-header-height) - var(--header-height) )' : 'calc( 100vh)',
    }),
    header: {
      position: 'absolute',
      top: 0,
      left: 0,

      width: '100%',
      height: 100,

      backgroundColor: props => props.ThemeData.colours.primaryHeaderBg,
      // backgroundColor: ThemeData.colours.white50,

      zIndex: 10,
    },
    backButton: {
      width: 100,
      height: 100,

      cursor: 'pointer',

      color: '#FFF',

      background: 'no-repeat center',
      backgroundImage: `url( ${navArrowLeftDark} )`,
    },
    viewStudy: {
      height: 'calc( 100% )',
    },
    rightPane: props => ({
      width: '100%',
      height: 'calc(100vh - 100px)',
      backgroundColor: props.ThemeData.colours.white,
      // background: "no-repeat center",
      // backgroundSize: "cover",
      // objectFit: "contain",
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center'
    }),
    swiper: props => ({
      '--swiper-theme-color': props.ThemeData.colours.swiperColour || props.ThemeData.colours.primaryBg,
      '--swiper-navigation-size': '',
      width: 'auto',
      height: 'calc (100vh)',
      '& .swiper-button-next, & .swiper-button-prev': {
        marginTop: '',
      },

    }),
  },
  {
    name: 'Carousel',
  }
);

const wrapperAnimVariants = {
  open: {
    y: 0,
    zIndex: 10,
    display: 'block',
  },
  closed: {
    y: '100vh',
    display: 'block',
    transitionEnd: {
      zIndex: -2,
      display: 'none'
    },
  },

};

function RenderCarousel (project, isPortraitMode, classes, error, isOpen, toggleCarouselSlideFcn, viewUrl, backLocation, props) {
  const { ThemeData } = project;
  const siteLogo = project && project.logo.length > 0 ? project.logo[0].link : null;
  const [swiper, setSwiper] = useState();

  const handleToggleOpen = () => {
    swiper?.slideTo(0, 100000);
  };

  return (
    <motion.div
      id="view-study"
      className={classes.wrapper}
      initial={false}
      animate={isOpen ? 'open' : 'closed'}
      variants={wrapperAnimVariants}
      transition={{
        type: 'linear',
        duration: .5,
      }}
    >

      {props.doUseHeader && !isPortraitMode &&
        <HeaderSimple
          project={project}
          backgroundColor={ThemeData.colours.primaryHeaderBg}
          onBackClick={() => {
            handleToggleOpen();
            toggleCarouselSlideFcn();
          }} />
      }
      {props.doUseHeader && isPortraitMode && <HeaderComponents
        project={project}
        siteLogo={siteLogo}
        height={150}
        // height={300}
        noRenderSaveHomes={true}
      />}
      <Swiper
        key={props?.carouselPhoto?.length}
        className={classes.swiper}
        modules={[Controller, Pagination, Navigation]}
        slidesPerView={1}
        buttonWrapperStyle={{ marginTop: '100px' }}
        navigation={{ clickable: true }}
        mousewheel={true}
        onSwiper={(swiper) => setSwiper(swiper)}
      >
        {props?.carouselPhoto?.length > 0 && props?.carouselPhoto?.map((photo, idx) => (
          <SwiperSlide
            className={classes.swiperSlideImg}
            key={idx}
          >
            <div
              className={`${classes.rightPane} `}
              style={{ backgroundImage: `url(${photo.link})` }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      {props.doUseHeader && isPortraitMode && <HeaderSimple
        project={project}
        backgroundColor={ThemeData.colours.primaryHeaderBg}
        onBackClick={() => toggleCarouselSlideFcn()} />}
    </motion.div>
  );
}

const Carousel = ({ isOpen, project, toggleCarouselSlideFcn, viewUrl, backLocation, ...props }) => {
  const isPortraitMode = portraitMode();
  const classes = useStyles({ ...props, ThemeData: project.ThemeData, isPortraitMode });
  return RenderCarousel(project, isPortraitMode, classes, null, isOpen, toggleCarouselSlideFcn, viewUrl, backLocation, props);
};

export default Carousel;
