import { gql } from "@apollo/client";

const QueryData = {};
QueryData.project = gql`
    query Project($slug: String!, $pageCategory: String, $staging: Boolean) {
        project(slug: $slug, pageCategory: $pageCategory, staging: $staging) {
            id
            slug
            name
            mapBox
            disabled
            projectType
            address {
                project
                viewCenter
            }
            contact {
                email
                phone
            }
            socialContact {
                Facebook
                Twitter
                Tiktok
            }
            mainPhoto {
                fileName
                link
                formats {
                    link
                    width
                    height
                    size
                }
            }
            partnerPhoto {
                id
                fileName
                link
            }
            brochure {
                link
                fileName
            }
            allFloorPlans {
                link
                fileName
            }
            logo {
                link
            }
            map {
                link
            }
            photoGallery {
                id
                category
                target
                position
                caption
                link
                width
                height
                formats {
                    link
                    width
                    height
                    size
                }
            }
            settings {
                siteTitle
                threeDUrl
                viewStudyUrl
                showAvailability
                showPrice
                showFeaturesButton
                showUnits
                show3DModel
                show2DModel
                showRenderingButton
                showHamburgerMenu
                showRegisterButton
                showFullTitle
                showEmailButton
                showTextBox
                latitude
                longitude
                siteTitle
                fontName
                colours
                disclaimer
                googleTrackId
                registerUrl
                mapBoxStyleUrl
                twoDModelTitle
                isTownHouse
                unitFilter {
                    availability
                    rooms
                    buildings
                    orentation
                    price {
                        enable
                        min
                        max
                    }
                    size {
                        enable
                        min
                        max
                    }
                }
            }
            filterSettings {
                unitFilter {
                    availability {
                        name
                        enable
                    }
                    rooms {
                        name
                        enable
                    }
                    buildings {
                        name
                        enable
                    }
                    orientation {
                        name
                        enable
                    }
                    floorPlans {
                        name
                        enable
                    }
                    floors {
                        name
                        enable
                    }
                    floorPlanType {
                        name
                        enable
                    }
                    price {
                        enable
                        min
                        max
                    }
                    size {
                        enable
                        min
                        max
                    }
                }
                hideFloorPlanFilter
            }
            labelSettings {
                interiorSize
                exteriorSize
                mezzanineSize
            }
            navs(staging: $staging, category: $pageCategory) {
                id
                name
                slug
                showTopMenu
                pages(slug: $slug, staging: $staging, category: $pageCategory) {
                    id
                    title
                    widgetTitle
                    slug
                    content
                    customUrl
                    form
                    customFunction
                    position
                    showFullSize
                    disableImgLabels
                    buildingId
                    video {
                        link
                    }
                    photo {
                        caption
                        link
                        fileName
                        formats {
                            size
                            link
                        }
                    }
                    child {
                        id
                        title
                        widgetTitle
                        slug
                        content
                        customUrl
                        form
                        customFunction
                        position
                        showFullSize
                        subCategory
                        video {
                            link
                        }
                        photo {
                            caption
                            link
                            fileName
                            formats {
                                size
                                link
                            }
                        }
                    }
                }
            }
            buildings {
                id
                name
                disabled
                type
                siteMaps {
                    id
                    floor
                    fileName
                    link
                    width
                    height
                    formats {
                        size
                        link
                    }
                    areas {
                        id
                        coords
                        shape
                    }
                }
            }
            floorPlans {
                id
                name
                bedRooms
                juniorBedRooms
                bathRooms
                interiorSize
                mezzanineSize
                exteriorSize
                sizeUnit
                studio
                flex
                description
                virtualTour
                viewStudyUrl
                floorPlanType
                floorplanUnitType
                marketingPdf {
                    id
                    fileName
                    link
                }
                units {
                    id
                    status
                    floor
                    threeDId
                }
                photo {
                    id
                    fileName
                    fileTitle
                    filePosition
                    link
                    formats {
                        size
                        link
                    }
                }
                keyPlan {
                    link
                }
                carouselPhoto {
                    id
                    fileName
                    fileTitle
                    filePosition
                    link
                    formats {
                        size
                        link
                    }
                }
                building {
                    id
                    name
                    disabled
                }
            }
            units {
                id
                threeDId
                name
                unitNumber
                price
                status
                floor
                exposures
                sizeUnit
                interiorSize
                exteriorSize
                mezzanineSize
                parking
                virtualTour
                viewStudyUrl
                options {
                    firePlace
                    hasFlex
                    hasDen
                    roofTop
                    inLawSuite
                    garage
                    parking
                    storage
                }
                floorPlan {
                    id
                    name
                    sizeUnit
                    interiorSize
                    exteriorSize
                    mezzanineSize
                    bedRooms
                    juniorBedRooms
                    bathRooms
                    virtualTour
                    studio
                    viewStudyUrl
                    flex
                    description
                    floorPlanType
                    floorplanUnitType
                    marketingPdf {
                        id
                        fileName
                        link
                    }
                    photo {
                        id
                        fileName
                        fileTitle
                        filePosition
                        link
                        formats {
                            size
                            link
                        }
                    }
                    keyPlan {
                        link
                    }
                    carouselPhoto {
                        id
                        fileName
                        fileTitle
                        filePosition
                        link
                        formats {
                            size
                            link
                        }
                    }
                    building {
                        id
                        name
                        disabled
                    }
                }
            }
        }
    }
`;

QueryData.emailSavedHomes = gql`
  mutation EmailSavedHomes(
    $projectId: ID!
    $unitIds: [ID]
    $registration: RegistrationInput
  ) {
    emailSavedHomes(
      projectId: $projectId
      unitIds: $unitIds
      registration: $registration
    ) {
      ... on Success {
        status
      }
    }
  }
`;

QueryData.leadEmail = gql`
  mutation RegisterForLeads($projectId: ID!, $data: LeadInput) {
    registerForLeads(projectId: $projectId, data: $data) {
      ... on Success {
        status
      }
    }
  }
`;

QueryData.emailPdf = gql`
  mutation EmailPdf(
    $projectId: ID!
    $type: String!
    $email: String!
    $fullName: String
    $floorPlan: String
  ) {
    emailPdf(
      projectId: $projectId
      type: $type
      email: $email
      fullName: $fullName
      floorPlan: $floorPlan
    ) {
      ... on Success {
        status
      }
      ... on Error {
        error
      }
    }
  }
`;

export default QueryData;
