import React from 'react';

import { createUseStyles } from 'react-jss';

import { useLocation } from 'react-router-dom';

import ConstantData from 'data/ConstantData';

import useSiteFeatureToggles from 'hooks/useSiteFeatureToggles';

import { ReactComponent as BedIcon } from '../img/icon_bed.svg';
import {
  capitalizeOptions,
  convertBathrooms,
  convertBedrooms,
  getDisplayLabel,
  portraitMode,
} from '../util/helper';
import facingArrowIcon from 'img/icon_facing_arrow.svg';
import { showExterior, showInterior, showTotalSpace } from '../util/helper';
import { Tooltip } from '@mui/material';

const useStyles = createUseStyles(
  {
    wrapper: (props) => ({
      padding: props.isPortraitMode
        ? props.isCompareMiniCard
          ? ''
          : props.isUnitDetailPortrait
            ? '30px 0 0 0'
            : '8px 0 0 0'
        : 'none',
      margin:
        props.isPortraitMode && props.isSaveHome
          ? '0'
          : props.isUnitDetailPortrait
            ? '0 0 0 87px'
            : '0 20px',
      textAlign: 'left',
      display: 'grid',
      gridTemplateColumns:
        props.isPortraitMode && !props.isSaveHome ? '1fr 1fr' : '1fr 1fr 1fr',
      rowGap: 20,
      columnGap: props.isPortraitMode ? 30 : 0,
      marginTop: props.isPortraitMode ? 0 : 10,
      marginRight: props.isPortraitMode ? 30 : 0,
    }),
    detailListItemsRow: {
      marginBlock: (props) => props.marginTopItemsRow,

      display: 'flex',
      justifyContent: 'left',

      listStyleType: 'none',

      textAlign: 'left',
      letterSpacing: '.05em',
      '@media (max-width: 1200px)': {
        marginBlock: (props) =>
          props.isPortraitMode && props.isSaveHome ? 0 : 10,
      },
    },
    detailListItemsRowBottom: (props) => ({
      marginTop: props.isPortraitMode && props.isSaveHome ? 11 : 'none',
    }),
    detailListItem: {
      flex: '0 1 auto',
    },
    detailListItemWider: {
      width: 200,
    },
    detailListItemIcon: {
      color: (props) => props.ThemeData.colours.primaryAccent,
    },
    detailListItemInline: {
      display: 'inline-block',
      marginRight: 10,
    },
    detailListItemTitle: (props) => ({
      color: props.ThemeData.colours.unitListItemTitle
        ? props.ThemeData.colours.unitListItemTitle
        : props.ThemeData.colours.grey,

      fontFamily: props.ThemeData.fonts.titleSub,
      fontSize:
        props.isSaveHome && props.isPortraitMode
          ? 8
          : props.isCompareMiniCard
            ? '5.379px'
            : 12,
      textTransform: props.isCompareHomes ? '' : 'uppercase',
    }),
    detailListItemTitleShort: {
      width: 60,
    },
    detailListItemTitleMedium: {
      width: 80,
    },
    detailListItemData: (props) => ({
      letterSpacing: props.isUnitDetailPortrait ? '3px' : '.05em',
      fontSize:
        props.isSaveHome && props.isPortraitMode
          ? 8
          : props.isCompareMiniCard
            ? '6.723px'
            : 16,
    }),
    detailListItemDataModifier: {
      fontFamily: (props) => props.ThemeData.fonts.copy,
    },
  },
  {
    name: 'UnitInfoIconList',
  },
);

const UnitInfoIconList = ({ dataUnit, project, className, ...props }) => {
  const { ThemeData, labelSettings: labels } = project;
  const isFloorplan = dataUnit.__typename === 'FloorPlan';
  const floorplan = isFloorplan ? dataUnit : dataUnit.floorPlan;
  const isPortraitMode = portraitMode();
  const classes = useStyles({
    ...props,
    ThemeData,
    isPortraitMode,
    isSaveHome: props.isSaveHome,
    isCompareHomes: props.isCompareHomes,
    isCompareMiniCard: props.isCompareMiniCard,
    isUnitDetailPortrait: props.isUnitDetailPortrait,
    showUnits: project.settings.showUnits,
  });
  const location = useLocation();
  const siteFeatureToggles = useSiteFeatureToggles(project && project.settings);
  const totalSpaceData = dataUnit.floorPlan
    ? dataUnit
    : { floorPlan: dataUnit };

  const floorPlanOnFloors = (id) => {
    let floors = [];
    project.units
      .filter((unit) => unit.floorPlan.id === id)
      .forEach((unit) => {
        if (!floors.includes(getDisplayLabel(unit.floor)))
          floors.push(getDisplayLabel(unit.floor));
      });
    floors.sort((a, b) => a - b);

    if (floors.length > 10) {
      return (
        <Tooltip title={floors.join(', ')}>
          Multiple floors
        </Tooltip>
      );
    }

    return floors.join(', ');
  };
  const numFloors = [
    ...new Set(
      project.units
        .filter((u) => u.floor !== undefined && u.floor !== null)
        .map((u) => u.floor),
    ),
  ];

  return (
    <div className={`${classes.wrapper} ${className}`}>
      <>
        {isPortraitMode && props.isSaveHome ? (
          <>
            <div className={classes.detailListItem}>
              <div
                className={`${classes.detailListItemTitle} ${classes.detailListItemTitleShort}`}
              >
                {labels?.interiorSize ? labels?.interiorSize : 'Interior'}
              </div>
              <div className={classes.detailListItemData}>
                {dataUnit && (
                  <>
                    {dataUnit.floorPlan.interiorSize}{' '}
                    <span className={classes.detailListItemDataModifier}>
                      {dataUnit.floorPlan.sizeUnit}
                    </span>
                  </>
                )}
              </div>
            </div>
            {dataUnit && !!dataUnit.floorPlan.mezzanineSize && (
              <div className={classes.detailListItem}>
                <div
                  className={`${classes.detailListItemTitle} ${classes.detailListItemTitleShort}`}
                >
                  {labels?.mezzanineSize ? labels?.mezzanineSize : 'Mezzanine'}
                </div>
                <div className={classes.detailListItemData}>
                  <>
                    {dataUnit.floorPlan.mezzanineSize}{' '}
                    <span className={classes.detailListItemDataModifier}>
                      {dataUnit.floorPlan.sizeUnit}
                    </span>
                  </>
                </div>
              </div>
            )}
            {dataUnit &&
              (dataUnit.exteriorSize || dataUnit.floorPlan.exteriorSize) && (
                <div className={classes.detailListItem}>
                  <div
                    className={`${classes.detailListItemTitle} ${classes.detailListItemTitleShort}`}
                  >
                    {labels?.exteriorSize ? labels?.exteriorSize : 'Exterior'}
                  </div>
                  <div className={classes.detailListItemData}>
                    <>
                      {showExterior(dataUnit)}{' '}
                      <span className={classes.detailListItemDataModifier}>
                        {dataUnit.floorPlan.sizeUnit}
                      </span>
                    </>
                  </div>
                </div>
              )}
            {project && project.settings.showUnits && (
              <>
                {dataUnit && (
                  <div className={classes.detailListItem}>
                    <div
                      className={`${classes.detailListItemTitle} ${classes.detailListItemTitleMedium}`}
                    >
                      Unit Number
                    </div>
                    <div
                      className={`${classes.detailListItemData} ${classes.detailListItemInline}`}
                    >
                      {dataUnit.unitNumber}
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <>
            {project && project.settings.showUnits ? (
              <>
                {dataUnit && (
                  <div className={classes.detailListItem}>
                    <div className={classes.detailListItemTitle}>
                      Unit Number
                    </div>
                    <div
                      className={`${classes.detailListItemData} ${classes.detailListItemInline}`}
                    >
                      {dataUnit.unitNumber}
                    </div>
                  </div>
                )}
              </>
            ) : (
              <></>
            )}
            <div className={classes.detailListItem}>
              <div className={classes.detailListItemTitle}>
                {labels?.interiorSize ? labels?.interiorSize : 'Interior'}
              </div>
              <div className={classes.detailListItemData}>
                {floorplan && (
                  <>
                    {isFloorplan
                      ? floorplan.interiorSize
                      : showInterior(dataUnit)}{' '}
                    <span className={classes.detailListItemDataModifier}>
                      {floorplan.sizeUnit}
                    </span>
                  </>
                )}
              </div>
            </div>

            {floorplan.mezzanineSize > 0 && (
              <div className={classes.detailListItem}>
                <div
                  className={`${classes.detailListItemTitle} ${classes.detailListItemTitleShort}`}
                >
                  {labels?.mezzanineSize ? labels?.mezzanineSize : 'Mezzanine'}
                </div>
                <div className={classes.detailListItemData}>
                  <>
                    {floorplan.mezzanineSize}{' '}
                    <span className={classes.detailListItemDataModifier}>
                      {floorplan.sizeUnit}
                    </span>
                  </>
                </div>
              </div>
            )}
            {dataUnit && (dataUnit.exteriorSize || floorplan.exteriorSize) && (
              <div className={classes.detailListItem}>
                <div className={classes.detailListItemTitle}>
                  {labels?.exteriorSize ? labels?.exteriorSize : 'Exterior'}
                </div>
                <div className={classes.detailListItemData}>
                  <>
                    {isFloorplan
                      ? floorplan.exteriorSize
                      : showExterior(dataUnit)}{' '}
                    <span className={classes.detailListItemDataModifier}>
                      {floorplan.sizeUnit}
                    </span>
                  </>
                </div>
              </div>
            )}
          </>
        )}
        <div className={classes.detailListItem}>
          <div className={classes.detailListItemTitle}>Total Space</div>
          <div className={classes.detailListItemData}>
            {dataUnit && (
              <>
                <span>{showTotalSpace(totalSpaceData, isFloorplan)}</span>
                <span className={classes.detailListItemDataModifier}>
                  {' '}
                  {floorplan.sizeUnit}
                </span>
              </>
            )}
          </div>
        </div>
      </>
      <>
        {!props.isSaveHome && numFloors.length >= 1 && (
          <>
            {!project?.settings?.isTownHouse && (
              <div className={classes.detailListItem}>
                <div className={classes.detailListItemTitle}>Floor</div>
                <div
                  className={`${classes.detailListItemData} ${classes.detailListItemInline}`}
                >
                  {isFloorplan
                    ? floorPlanOnFloors(dataUnit.id)
                    : getDisplayLabel(dataUnit.floor)}
                </div>
              </div>
            )}
            {isFloorplan ? (
              <>
                {dataUnit && dataUnit?.floorPlan?.floorPlanType && (
                  <div className={classes.detailListItem}>
                    <div className={classes.detailListItemTitle}>Type</div>
                    <div
                      className={`${classes.detailListItemData} ${classes.detailListItemInline}`}
                    >
                      {capitalizeOptions(dataUnit?.floorPlan?.floorPlanType)}
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                {dataUnit && dataUnit?.floorPlan?.floorPlanType !== null && (
                  <div className={classes.detailListItem}>
                    <div className={classes.detailListItemTitle}>Type</div>
                    <div
                      className={`${classes.detailListItemData} ${classes.detailListItemInline}`}
                    >
                      {capitalizeOptions(dataUnit?.floorPlan?.floorPlanType)}
                      {/* {(props.calledByUnitDetail || props.isUnitDetailPortrait) && capitalizeOptions(dataUnit.floorPlan.floorPlanType)} */}
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}

        {!project.projectType.includes('industrial') && (
          <>
            {floorplan && floorplan.bedRooms !== null ? (
              <div className={`${classes.detailListItem}`}>
                <div className={classes.detailListItemTitle}>Bedroom</div>
                {!isPortraitMode && (
                  <div
                    className={`${classes.detailListItemIcon} ${classes.detailListItemInline}`}
                  >
                    <BedIcon />
                  </div>
                )}
                <div
                  className={`${classes.detailListItemData} ${classes.detailListItemInline}`}
                >
                  {convertBedrooms(
                    floorplan.bedRooms,
                    floorplan.juniorBedRooms,
                    floorplan.studio,
                    floorplan.flex,
                  )}
                </div>
              </div>
            ) : (
              ''
            )}
            {floorplan && floorplan.bathRooms && (
              <div className={classes.detailListItem}>
                <div className={classes.detailListItemTitle}>Bath</div>
                <div
                  className={`${classes.detailListItemData} ${classes.detailListItemInline}`}
                >
                  {convertBathrooms(floorplan.bathRooms)}
                </div>
              </div>
            )}
          </>
        )}

        {/* {isPortraitMode && (
          <>
            {project.projectType.includes("industrial") &&
              numFloors.length > 1 && (
                <div className={classes.detailListItem}>
                  <div className={classes.detailListItemTitle}>Floor</div>
                  <div
                    className={`${classes.detailListItemData} ${classes.detailListItemInline}`}
                  >
                    {isFloorplan
                      ? floorPlanOnFloors(dataUnit.id)
                      : getDisplayLabel(dataUnit.floor)}
                  </div>
                </div>
              )}
            {isFloorplan ? (
              <>
                {dataUnit && dataUnit?.floorPlanType !== null && (
                  <div className={classes.detailListItem}>
                    <div className={classes.detailListItemTitle}>Type</div>
                    <div
                      className={`${classes.detailListItemData} ${classes.detailListItemInline}`}
                    >
                      {(props.calledByUnitDetail ||
                        props.isUnitDetailPortrait) &&
                        capitalizeOptions(dataUnit.floorPlanType)}
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                {dataUnit && dataUnit?.floorPlan.floorPlanType !== null && (
                  <div className={classes.detailListItem}>
                    <div className={classes.detailListItemTitle}>Type</div>
                    <div
                      className={`${classes.detailListItemData} ${classes.detailListItemInline}`}
                    >
                      {capitalizeOptions(dataUnit.floorPlan.floorPlanType)}
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )} */}
        {dataUnit &&
          project.settings.showUnits === true &&
          dataUnit.options?.parking === true &&
          dataUnit.parking > 0 && (
            <div className={classes.detailListItem}>
              <div className={classes.detailListItemTitle}>Parking</div>
              <div
                className={`${classes.detailListItemData} ${classes.detailListItemInline}`}
              >
                {dataUnit.parking}
              </div>
            </div>
          )}
        {props.doShowFacingItem && dataUnit && dataUnit.unitExposures && (
          <div
            className={`${classes.detailListItem} ${classes.detailListItemWider}`}
          >
            <div
              className={`${classes.detailListItemIcon} ${classes.detailListItemInline}`}
            >
              <img src={facingArrowIcon} alt="Facing Direction Icon" />
            </div>
            <div
              className={`${classes.detailListItemData} ${classes.detailListItemInline}`}
            >
              {dataUnit.unit_exposures &&
                dataUnit.unitExposures.map((unitExposure) => (
                  <span key={unitExposure.id}>{unitExposure.direction}</span>
                ))}{' '}
              <span className={classes.detailListItemDataModifier}>facing</span>
            </div>
          </div>
        )}
        {project &&
        project.settings.showUnits === false &&
        location.pathname.includes('/proximation') ? (
          <></>
        ) : (
          <>
            {siteFeatureToggles.isPricesOn &&
              props.doShowPrice &&
              dataUnit &&
              dataUnit.price && (
                <div
                  className={`${classes.detailListItem} ${classes.detailListItem}`}
                >
                  <div className={classes.detailListItemTitle}>Price</div>
                  <div
                    className={`${classes.detailListItemData} ${classes.detailListItemInline}`}
                  >
                    <span>
                      {ConstantData.dataFormattingFunctions.prices(
                        dataUnit.price,
                      )}
                    </span>
                  </div>
                </div>
              )}
          </>
        )}
      </>
    </div>
  );
};

UnitInfoIconList.defaultProps = {
  marginTopItemsRow: 30,

  doShowFacingItem: false,
  doShowPrice: true,
};

export default UnitInfoIconList;
