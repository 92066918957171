// import {
// 	useEffect,
// } from "react";

import createPersistedState from "use-persisted-state";

import ConstantData from "data/ConstantData";


const useGlobalUnitsFilterResultState = createPersistedState( ConstantData.localStorageKeys.unitsFilterResult );


const useGlobalUnitsFilterResult = () => {

	const [globalUnitsFilterResult, setGlobalUnitsFilterResult] = useGlobalUnitsFilterResultState( {
		unitCustomIdsFiltered: [],
	} );
	return {
		globalUnitsFilterResult: globalUnitsFilterResult,
		unitCustomIdsFiltered: globalUnitsFilterResult.unitCustomIdsFiltered,
		setUnitCustomIdsFiltered: ( toSet ) => {
			setGlobalUnitsFilterResult( {
				...globalUnitsFilterResult,
				unitCustomIdsFiltered: toSet,
			})
		},
	}
}


export default useGlobalUnitsFilterResult;