import React, {
  useState,
  useRef,
} from 'react';

import {
  createUseStyles,
} from 'react-jss';

import {
  motion,
} from 'framer-motion';

import { categorizeNeighbourhood } from 'util/mapBox';
import ButtonCircle from './ButtonCircle';
import { ReactComponent as NavArrowLeft } from '../img/nav_arrow_left.svg';
import { ReactComponent as NavArrowRight } from '../img/nav_arrow_right.svg';

const useStyles = createUseStyles(
  {
    wrapper: {
      width: '100%',
      height: '100%',
      display: props => props.isPortraitMode ? 'flex' : ''
    },
    title: {
      fontFamily: props => props.ThemeData.fonts.title,

      letterSpacing: '0.15em',
      textTransform: 'uppercase',
    },
    category: {
      paddingTop: 10,

      lineHeight: '3em',

      '& $categoryTitle, & $poi': {
        borderRadius: '7px',
      },
    },
    categoryTitle: {
      paddingLeft: 10,

      fontFamily: props => props.ThemeData.fonts.title,

      fontSize: 12,
      letterSpacing: '0.15em',
      textTransform: 'uppercase',
    },
    poi: {
      display: 'flex',
      width: '100%',
      gap: 20,
      fontFamily: props => props.ThemeData.fonts.copy,

      fontSize: 16,
      letterSpacing: 0,
      textTransform: 'uppercase',
    },
    clickableItem: {
      cursor: 'pointer',
    },
    poiNumber: {
      paddingLeft: 10,

      flex: '0 0 auto',

      width: 30,

      letterSpacing: 0,
      textAlign: 'center',
    },
    poiName: {
      flex: '0 1 auto',

      minWidth: 0,
      textTransform: 'capitalize'
    },
    content: props => ({
      flex: '1 1 auto',
      color: props.ThemeData.colours.secondaryFont,
      height: '65%',
      fontFamily: props.ThemeData.fonts.copy,
      fontSize: '1.2em',
      letterSpacing: '0.0125em',
      lineHeight: '2em',

      overflowY: props.isPortraitMode ? 'none' : 'auto',

      ...props.ThemeData.styles.thinScrollbar,
    }),
    contentArea: {
      flex: '0 0 auto',
    },
    buttonLeft: props => ({
      // PORTRAIT
      display: props.isPortraitMode ? 'flex' : 'none',
      alignItems: props.isPortraitMode ? 'center' : '',
      paddingRight: props.isPortraitMode ? '50px' : '',
      paddingBottom: props.isPortraitMode ? (props.slug === 'liveenzo' ? 70 : 0) : 0
    }),
    buttonRight: props => ({
      display: props.isPortraitMode ? 'flex' : 'none',
      alignItems: props.isPortraitMode ? 'center' : '',
      paddingLeft: props.isPortraitMode ? '50px' : '',
      paddingBottom: props.isPortraitMode ? (props.slug === 'liveenzo' ? 70 : 0) : 0
    }),
    contentWrapper: props => ({
      display: 'flex',
      //alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      padding: '40px 0'
    }),
    innerContent: props => ({
      display: props.isPortraitMode ? 'flex' : '',
      width: props.isPortraitMode ? '640px' : '',
      overflow: 'hidden',
    }),
    poiItems: props => ({
      // PORTRAIT
      height: props.isPortraitMode ? (props.slug === 'liveenzo' ? '310px' : '250px') : '',
      width: props.isPortraitMode ? '270px' : '',
      overflowY: props.isPortraitMode ? 'scroll' : '',
      paddingRight: props.isPortraitMode ? '50px' : ''
    }),
    categoryName: {
      paddingLeft: props => props.isPortraitMode ? '18px' : ''
    },
    filterButtonGroup: props => ({
      // PORTRAIT
      display: props.isPortraitMode ? 'flex' : '',
      paddingTop: props.isPortraitMode ? 65 : ''
    }),
  },
  {
    name: 'NeighbourhoodPois',
  }
);

const highlightedPoiVariants = {
  highlighted: (custom) => ({
    backgroundColor: `${custom}33`,
  }),
  normal: (custom) => ({
    backgroundColor: `${custom}00`,
  }),
};

function RenderNeighbourhoodPois (project, classes, neighCategories, error, doShow, props) {
  const scroll = useRef(null);
  const [rightButtonDisplay, setRightButtonDisplay] = useState(true);
  const [leftButtonDisplay, setLeftButtonDisplay] = useState(false);

  const scrollNext = (scrollOffset) => {
    scroll.current.scrollLeft += scrollOffset;

    // Display right button
    if (scroll.current.scrollLeft + scroll.current.clientWidth === scroll.current.scrollWidth) {
      setRightButtonDisplay(false);
    } else {
      setRightButtonDisplay(true);
    }

    // Display left button
    if (scroll.current.scrollLeft === 0) {
      setLeftButtonDisplay(false);
    } else if (scroll.current.scrollLeft !== 0) {
      setLeftButtonDisplay(true);
    }
  };
  const [isMouseDown, setIsMouseDown] = useState(false);

  const handleMouseDown = () => {
    setIsMouseDown(true);
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
  };

  return (
    <>
      {/*
			<div className={classes.filterButtonGroup}>
				<h3 style={{ paddingRight: props.isPortraitMode ? 48 : 0 }}>{`Distance`.toUpperCase()}</h3>

				<DistanceFilters
					project={project}
					distance={props.distance}
					toggleDistance={props.toggleDistance}
					isPortraitMode={props.isPortraitMode}
				/>
			</div>
			*/}
      <div className={`${classes.contentArea} ${classes.content}`}>
        <div className={classes.wrapper}>
          {!props.isPortraitMode && <div className={classes.title}>
            Categories
          </div>}
          <div className={classes.buttonLeft}>
            <ButtonCircle
              ThemeData={project.ThemeData}
              borderColor={leftButtonDisplay ? project.ThemeData.colours.primaryAccent : project.ThemeData.colours.grey10}
              activeBackgroundColor={project.ThemeData.colours.primaryAccent}
              disabled={leftButtonDisplay ? false : true}
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
              onClick={() => scrollNext(-330)}
            >
              <NavArrowLeft
                fill={leftButtonDisplay ? (isMouseDown ? project.ThemeData.colours.white : project.ThemeData.colours.primaryAccent) : project.ThemeData.colours.grey10}
                id="content-page-navigate-left"
              />
            </ButtonCircle>
          </div>
          <div className={classes.contentWrapper}>
            <div className={classes.innerContent} ref={scroll}>
              {Object.keys(neighCategories).length > 0 &&
                Object.entries(neighCategories)
                  .map(([categoryName, item]) =>
                    <div className={classes.category} key={item.CategoryId}>
                      <motion.div
                        className={`${classes.categoryTitle} ${classes.clickableItem}`}
                        animate={props.highlightCatPoi.highCatId === item.CategoryId ? 'highlighted' : 'normal'}
                        custom={item.Color}
                        variants={highlightedPoiVariants}
                        onMouseEnter={() => {
                          props.setHighlightCatPoi({
                            isHighActive: true,
                            highCatId: item.CategoryId,
                            highPoiId: -1,
                          });
                        }}
                        onMouseLeave={() => {
                          props.setHighlightCatPoi({
                            isHighActive: false,
                            highCatId: -1,
                            highPoiId: -1,
                          });
                        }}
                        onTap={() => props.setProcFlyTo({
                          doProcFlyTo: true,
                          catid: item.CategoryId,
                          poiid: -1,
                        })}
                      >
                        <div className={classes.categoryName} style={{ color: `${item.Color}` }}>{categoryName}</div>
                        <div className={classes.poiItems}>

                          {item.Poi.length > 0 && item.Poi.map((poi) => (
                            <motion.div
                              className={`${classes.poi} ${classes.clickableItem}`}
                              animate={props.highlightCatPoi.highPoiId === poi.properties.Number ? 'highlighted' : 'normal'}
                              custom={item.Color}
                              variants={highlightedPoiVariants}
                              key={poi.properties.Number}
                              nlcid={poi.properties.Number}
                              nlid={poi.properties.Number}
                              onMouseEnter={() => props.setHighlightCatPoi({
                                isHighActive: true,
                                highCatId: -1,
                                highPoiId: poi.properties.Number,
                              })}
                              onMouseLeave={() => props.setHighlightCatPoi({
                                isHighActive: false,
                                highCatId: -1,
                                highPoiId: -1,
                              })}
                              onTap={() => props.setProcFlyTo({
                                doProcFlyTo: true,
                                catid: -1,
                                poiid: poi.properties.Number,
                                coordinates: poi.geometry.coordinates,
                              })}
                            >
                              <div className={classes.poiNumber}>{poi.properties.Number}</div>
                              <div className={classes.poiName}>{poi.properties.Name}</div>
                            </motion.div>
                          ))}
                        </div>
                      </motion.div>
                    </div>
                  )}
            </div>
          </div>
          <div className={classes.buttonRight}>
            <ButtonCircle
              ThemeData={project.ThemeData}
              borderColor={rightButtonDisplay ? project.ThemeData.colours.primaryAccent : project.ThemeData.colours.grey10}
              activeBackgroundColor={project.ThemeData.colours.primaryAccent}
              disabled={rightButtonDisplay ? false : true}
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
              onClick={() => scrollNext(330)}
            >
              <NavArrowRight
                fill={rightButtonDisplay ? (isMouseDown ? project.ThemeData.colours.white : project.ThemeData.colours.primaryAccent) : project.ThemeData.colours.grey10}
                id="content-page-navigate-right"
              />
            </ButtonCircle>
          </div>

        </div>
      </div>
    </>
  );
}

const ContentPageNeighbourhoodPois = ({ project, doShow, ...props }) => {
  const classes = useStyles({ ThemeData: project.ThemeData, isPortraitMode: props.isPortraitMode, slug: project.slug });

  const filterMapping = {
    'fiveMinutes': 5,
    'tenMinutes': 10,
    'fifteenMinutes': 15
  };

  let filterDistance = 0;
  props.distance.map((f) =>
    filterMapping[f] > filterDistance ? filterDistance = filterMapping[f] : filterDistance);

  const neighGeo = project.mapBox ? JSON.parse(project.mapBox) : null;
  let neighCategories = filterDistance > 0
    ? categorizeNeighbourhood(neighGeo, project.settings.latitude, project.settings.longitude, filterDistance)
    : categorizeNeighbourhood(neighGeo, project.settings.latitude, project.settings.longitude);

  function filterdCategoriesPortrait () {
    let tmp = {};
    Object.entries(neighCategories).forEach(([name, item]) => {
      if (item.Poi.length > 0) {
        let obj = { [name]: item };
        Object.assign(tmp, obj);
      }
    });
    return tmp;
  }

  const neighCategoriesPortrait = filterdCategoriesPortrait();
  if (props.isPortraitMode) neighCategories = neighCategoriesPortrait;
  return RenderNeighbourhoodPois(project, classes, neighCategories, null, doShow, props);
};

export default ContentPageNeighbourhoodPois;