const NetData = {
	get apiUri() {
		// return "";
		return process.env.NODE_ENV !== 'development' ?
			( process.env.REACT_APP_LOCAL_BUILD ?
				process.env.REACT_APP_BACKEND_URI
				: "" )
			: process.env.REACT_APP_BACKEND_URI
	},
}

NetData.imgUri = cmsImgUri => {
	if ( !cmsImgUri ) return null;

	if ( cmsImgUri.charAt( 0 ) === '/' )
		return `${NetData.apiUri}${cmsImgUri}`;
	else
		return cmsImgUri;
}

export default NetData;