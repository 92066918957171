import React, { useEffect, useState, useContext } from "react";

import { createUseStyles } from "react-jss";

import { motion } from "framer-motion";
import { useHistory, useLocation } from "react-router-dom";

import useOpenClosePane from "hooks/useOpenClosePane";
import useGlobalUnitsFilterResult from "hooks/useGlobalUnitsFilterResult";
import useGlobalUnitsFilter from "hooks/useGlobalUnitsFilter";
import useSavedHomes from "hooks/useSavedHomes";
import UnitFcnContext from "contexts/UnitFcnContext";

import Layout from "./Layout";
import ViewStudy from "./ViewStudy";
import VirtualTour from "./VirtualTour";
import FilterHeader from "./FilterHeader";
import UnitsGeneric from "./UnitsGeneric";
import FilterPane from "./FilterPane";
import ButtonOutline from "./ButtonOutline";
import { ReactComponent as RefreshIcon } from "../img/refresh.svg";
import { getOrdinal } from "util/helper";
import { getImageBySize } from "util/imgFunctions";
import SiteMap from "./SiteMap";
import FooterComponents from "./FooterComponents";

const useStyles = createUseStyles({
  wrapper: {
    display: "flex",
    width: "100%",
    height: "100%",
    flexDirection: "column",
    background: (props) => props.ThemeData.colours.white,
    color: (props) => props.ThemeData.colours.black,
  },
  emptyWrapper: (props) => ({
    // height: 'calc( 100vh - 200px)',
    height: "calc( var( --app-height)+100px)",
    position: "relative",
    backgroundColor: props.ThemeData.colours.white,
    display:
      props.unitDetailOpen || props.isSavedHomesCompareOpen ? "" : "none",
  }),
  topWrapper: (props) => ({
    position: "relative",
    display: "block",
    marginTop: props.isBigScreen ? "" : "150px",
    height:
      props.doShowFilter || props.doShowBottomPane
        ? "calc(100vh - var(--content-pane-height) - var(--portrait-header-height) - var(--footer-height) - var(--accessibility-footer) + 17px)"
        : "calc(100vh - 400px)",
  }),
  modelWrapper: (props) => ({
    position: "relative",
    padding: "0 20px",
    display: props.doShowFilter || props.doShowBottomPane ? "flex" : "block",

    height:
      props.doShowFilter || props.doShowBottomPane
        ? "calc(100vh - var(--content-pane-height) - var(--portrait-header-height) - var(--footer-height) - var(--accessibility-footer))"
        : "calc(100vh - 400px)",
  }),
  canvasWrapper: (props) => ({
    position: "relative",
    width: "100%",
    height: "100%",
  }),
  filterWrapper: (props) => ({
    height: "var(--content-pane-height)",
    background: props.ThemeData.colours.white,
    padding: "0px 40px 0px 40px",
  }),
  contentWrapper: (props) => ({
    height: props.isBigScreen
      ? "calc(var(--content-pane-height) - var(--header-height) - 40px)" // - var(--header-height)
      : "calc(var(--content-pane-height) - var(--header-height) - 40px)",

    // marginTop:'50px',
    background: props.ThemeData.colours.white,
    padding: "40px 40px 0 40px",
    display: "flex",
    gap: 18,

    "& > div": {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      backgroundColor: props.ThemeData.colours.white,
      overflowY: "auto",
      borderRadius: 15,
      border: `1px solid ${
        props.ThemeData.colours.branding || props.ThemeData.colours.vibrantGreen
      }`,
      boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.25)",
    },

    "& $modelWrapper": {
      height: "calc(var(--content-pane-height) - var(--header-height) - 50px)",
    },
  }),
  contentRight: (props) => ({
    position: "relative",
  }),
  refreshButton: (props) => ({
    position: "absolute",
    // bottom: '-50px',
    bottom: 0,
    left: 40,
    borderRadius: 15,
    fontSize: 10,
    /*
    '&:hover': {
      '& svg': {
        stroke: props.ThemeData.colours.vibrantGreen
      }
    }
    */
  }),
  rightButton: {
    left: "auto !important",
    right: 40,
  },
  hide: {
    display: "none !important",
  },
  siteplan: {
    height: "100%",
    width: "100%",
    background: "no-repeat center",
    backgroundSize: "cover",
    objectFit: "contain",
  },
});

const Proximation = ({ proxima, project, proximaContainer, ...props }) => {
  const { ThemeData, units = [], logo = [] } = project;
  const {
    selectedUnitId,
    setSelectedUnitId,
    interactModalFrom,
    setInteractModalFrom,
    isBigScreen,
    currentSectionSlug,
  } = useContext(UnitFcnContext);
  const siteLogo = logo.length > 0 ? logo[0].link : null;
  const history = useHistory();
  const backLocation = currentSectionSlug && `/${currentSectionSlug.slug}`;

  const [isBack, setIsBack] = useState(false);
  const [isOpenSavedHomes, setIsOpenSavedHomes] = useState(false);
  useEffect(() => {
    if (isOpenSavedHomes) props.toggleIsSavedHomesSlideoutOpen();
  }, [isOpenSavedHomes]);
  const handleBackButtonClick = () => {
    if (props.toggleSlideUpIsOpen) {
      props.toggleSlideUpIsOpen();
      props.setIsContentPageCarouselOpen(false);
    } else if (props.isUnitDetailOpen) {
      props.setIsUnitDetailOpen(false);
      props.setIsContentPageCarouselOpen(false);
      // } else if (isFloorplansViewStudySlideUpOpen) {
      //   // setIsFloorplansViewStudySlideUpOpenToClosed();
      //   props.setIsContentPageCarouselOpen(false);
      // } else if (isFloorplansVirtualTourOpen) {
      //   setIsFloorplansVirtualTourOpenToClosed();
      //   props.setIsContentPageCarouselOpen(false);
    } else {
      history.push(backLocation);
      // history.goBack()
    }
  };
  useEffect(() => {
    if (isBack) handleBackButtonClick();
  }, [isBack]);
  const availabilityColours = {
    available: "0CE015A6",
    sold: "FF000099",
    hold: "FBED2FA6",
    inProgress: "FBED2FA6",
  };

  const modelUnits = units.map((u) => ({
    id: u.threeDId,
    color: availabilityColours[u.status] || availabilityColours.sold,
  }));

  let floors = [];
  if (units.length > 0) {
    units
      .filter((u) => u.floor !== undefined && u.floor !== null)
      .forEach((u) => {
        const found = floors.filter((f) => f.id === u.floor);
        if (found.length > 0) return;
        floors.push({
          id: u.floor,
          name: `${getOrdinal(u.floor)} floor`,
          value: u.floor,
        });
      });
  }

  useEffect(() => {
    if (!proxima.current || !proxima.current.canvas) {
      proxima.current = {
        pc: window.pc,
        INPUT_SETTINGS: window.INPUT_SETTINGS,
        CONFIG_FILENAME: window.CONFIG_FILENAME,
        SCENE_PATH: window.SCENE_PATH,
        PRELOAD_MODULES: window.PRELOAD_MODULES,
        loadModules: window.loadModules,
        CONTEXT_OPTIONS: window.CONTEXT_OPTIONS,
        ASSET_PREFIX: window.ASSET_PREFIX,
        SCRIPT_PREFIX: window.SCRIPT_PREFIX,
        SCRIPTS: window.SCRIPTS,
        canvas: null,
        devices: null,
        app: null,
      };

      let pc = proxima.current.pc;
      let INPUT_SETTINGS = proxima.current.INPUT_SETTINGS;
      let CONFIG_FILENAME = proxima.current.CONFIG_FILENAME;
      let SCENE_PATH = proxima.current.SCENE_PATH;
      let PRELOAD_MODULES = proxima.current.PRELOAD_MODULES;
      let loadModules = proxima.current.loadModules;
      let ASSET_PREFIX = proxima.current.ASSET_PREFIX;

      pc.script.legacy = false; //Bao moved this from __settings__.js
      (function () {
        var CANVAS_ID = "application-canvas";

        // var canvas, devices, app;

        var createCanvas = function () {
          // if ( !canvas ) {
          proxima.current.canvas = document.createElement("canvas");
          // 	doAppendCanvas = true;
          // }

          proxima.current.canvas.setAttribute("id", CANVAS_ID);
          proxima.current.canvas.setAttribute("tabindex", 0);
          // canvas.style.visibility = 'hidden';

          // Disable I-bar cursor on click+drag
          proxima.current.canvas.onselectstart = function () {
            return false;
          };

          // if ( doAppendCanvas )
          proximaContainer.current.appendChild(proxima.current.canvas);
          // document.getElementById( "canvasWrapper" ).appendChild( proxima.current.canvas );
          // document.body.appendChild(canvas);

          return proxima.current.canvas;
        };

        var createInputDevices = function (canvas) {
          var devices = {
            elementInput: new pc.ElementInput(canvas, {
              useMouse: INPUT_SETTINGS.useMouse,
              useTouch: INPUT_SETTINGS.useTouch,
            }),
            keyboard: INPUT_SETTINGS.useKeyboard
              ? new pc.Keyboard(window)
              : null,
            mouse: INPUT_SETTINGS.useMouse ? new pc.Mouse(canvas) : null,
            gamepads: INPUT_SETTINGS.useGamepads ? new pc.GamePads() : null,
            touch:
              INPUT_SETTINGS.useTouch && pc.platform.touch
                ? new pc.TouchDevice(canvas)
                : null,
          };

          return devices;
        };

        var configureCss = function (fillMode, width, height) {
          // Configure resolution and resize event
          if (proxima.current.canvas.classList) {
            proxima.current.canvas.classList.add("fill-mode-" + fillMode);
          }

          // css media query for aspect ratio changes
          var css =
            "@media screen and (min-aspect-ratio: " +
            width +
            "/" +
            height +
            ") {";
          css += "    #application-canvas.fill-mode-KEEP_ASPECT {";
          css += "        width: auto;";
          css += "        height: 100%;";
          css += "        margin: 0 auto;";
          css += "    }";
          css += "}";

          // append css to style
          if (document.head.querySelector) {
            document.head.querySelector("style").innerHTML += css;
          }
        };

        var reflow = function () {
          var canvasWrapper = document.getElementById("canvasWrapper");
          if (canvasWrapper) {
            proxima.current.app.resizeCanvas(
              canvasWrapper.clientWidth,
              canvasWrapper.clientHeight
            );
            proxima.current.canvas.style.width = "100%";
            proxima.current.canvas.style.height = "100%";

            var fillMode = proxima.current.app._fillMode;

            if (
              fillMode === pc.FILLMODE_NONE ||
              fillMode === pc.FILLMODE_KEEP_ASPECT
            ) {
              proxima.current.canvas.style.marginTop = "";
            }
          }
        };

        var displayError = function (html) {
          var div = document.createElement("div");

          div.innerHTML = [
            '<table style="background-color: #8CE; width: 100%; height: 100%;">',
            "  <tr>",
            '      <td align="center">',
            '          <div style="display: table-cell; vertical-align: middle;">',
            '              <div style="">' + html + "</div>",
            "          </div>",
            "      </td>",
            "  </tr>",
            "</table>",
          ].join("\n");

          document.body.appendChild(div);
        };

        proxima.current.canvas = createCanvas();
        proxima.current.devices = createInputDevices(proxima.current.canvas);

        try {
          proxima.current.app = new pc.Application(proxima.current.canvas, {
            elementInput: proxima.current.devices.elementInput,
            keyboard: proxima.current.devices.keyboard,
            mouse: proxima.current.devices.mouse,
            gamepads: proxima.current.devices.gamepads,
            touch: proxima.current.devices.touch,
            graphicsDeviceOptions: proxima.current.CONTEXT_OPTIONS,
            assetPrefix: proxima.current.ASSET_PREFIX || "",
            scriptPrefix: proxima.current.SCRIPT_PREFIX || "",
            scriptsOrder: proxima.current.SCRIPTS || [],
          });
        } catch (e) {
          if (e instanceof pc.UnsupportedBrowserError) {
            displayError(
              "This page requires a browser that supports WebGL.<br/>" +
                '<a href="http://get.webgl.org">Click here to find out more.</a>'
            );
          } else if (e instanceof pc.ContextCreationError) {
            displayError(
              "It doesn't appear your computer can support WebGL.<br/>" +
                '<a href="http://get.webgl.org/troubleshooting/">Click here for more information.</a>'
            );
          } else {
            displayError("Could not initialize application. Error: " + e);
          }

          return;
        }

        var configure = function () {
          proxima.current.app.configure(CONFIG_FILENAME, function (err) {
            const app = proxima.current.app;
            if (err) {
              console.error(err);
            }

            configureCss(app._fillMode, app._width, app._height);

            // do the first reflow after a timeout because of
            // iOS showing a squished iframe sometimes
            setTimeout(function () {
              reflow();

              window.addEventListener("resize", reflow, false);
              window.addEventListener("orientationchange", reflow, false);

              app.preload(function (err) {
                if (err) {
                  console.error(err);
                }

                app.loadScene(SCENE_PATH, function (err, scene) {
                  if (err) {
                    console.error(err);
                  }

                  app.start();

                  props.setIsProximaLoaded(true);
                });
              });
            });
          });
        };

        if (PRELOAD_MODULES && PRELOAD_MODULES.length > 0) {
          loadModules(PRELOAD_MODULES, ASSET_PREFIX, configure);
        } else {
          configure();
        }
      })();

      pc.script.createLoadingScreen(function (app) {
        var showSplash = function () {
          // splash wrapper
          var wrapper = document.createElement("div");
          wrapper.id = "application-splash-wrapper";
          document.body.appendChild(wrapper);

          // splash
          var splash = document.createElement("div");
          splash.id = "application-splash";
          wrapper.appendChild(splash);
          splash.style.display = "none";

          var logo = document.createElement("img");
          logo.src = siteLogo ? siteLogo : ASSET_PREFIX + "logo.png";
          splash.appendChild(logo);
          logo.onload = function () {
            splash.style.display = "block";
          };

          var container = document.createElement("div");
          container.id = "progress-bar-container";
          splash.appendChild(container);

          var bar = document.createElement("div");
          bar.id = "progress-bar";
          container.appendChild(bar);
        };

        var hideSplash = function () {
          var splash = document.getElementById("application-splash-wrapper");
          splash && splash.parentElement.removeChild(splash);
        };

        var setProgress = function (value) {
          var bar = document.getElementById("progress-bar");
          if (bar) {
            value = Math.min(1, Math.max(0, value));
            bar.style.width = value * 100 + "%";
          }
        };

        var createCss = function (modalOpen) {
          var css = [
            "body {",
            `    background-color: ${ThemeData.colours.primaryBg};`,
            "}",

            "#application-canvas {",
            "	position: relative",
            "}",

            "#application-canvas:after {",
            'content: "";',
            "position:absolute;",
            "z-index:999;",
            "top:0;",
            "left:0;",
            "width:100%;",
            "height:100%;",
            "cursor: not-allowed;",
            "pointer-events: none;",
            "}",

            "#application-splash-wrapper {",
            "    position: absolute;",
            "    top: 100px;",
            "    left: 0;",
            "    height: calc( 100vh - 100px) !important;",
            "    height: calc( var(--app-height) - 100px);",
            "    width: 100%;",
            `    background-color: ${ThemeData.colours.primaryBg};`,
            "}",

            "#application-splash {",
            "    position: absolute;",
            "    top: 46%;",
            "    width: 512px;",
            "    left: 50%;",
            "		 transform: translate(-50%, -50%);",
            "		 -moz-transform: translate(-50%, -50%);",
            "		 -webkit-transform: translate(-50%, -50%);",
            "}",

            "#application-splash img {",
            "    width: 100%;",
            "    margin-bottom: 25px;",
            "}",

            "#progress-bar-container {",
            "    margin: 20px auto 0 auto;",
            "    height: 2px;",
            "    width: 100%;",
            `    background-color: ${ThemeData.colours.primaryAccentFade}`,
            "}",

            "#progress-bar {",
            "    width: 0%;",
            "    height: 100%;",
            `    background-color: ${ThemeData.colours.primaryAccent};`,
            "}",
            "@media (max-width: 480px) {",
            "    #application-splash {",
            "        width: 170px;",
            "        left: calc(50% - 85px);",
            "    }",
            "}",
          ].join("\n");

          var style = document.createElement("style");
          style.type = "text/css";
          if (style.styleSheet) {
            style.styleSheet.cssText = css;
          } else {
            style.appendChild(document.createTextNode(css));
          }

          document.head.appendChild(style);
          proximaContainer.current.appendChild(style);
        };

        createCss();

        showSplash();

        app.on("preload:end", function () {
          app.off("preload:progress");
        });
        app.on("preload:progress", setProgress);
        app.on("start", hideSplash);

        connectWebGLEvents(app);
      });
    }
  }, [proxima, proximaContainer]);

  const connectWebGLEvents = (pcApp) => {
    pcApp.on("api:onUnitSelected", (unitId) => {
      setInteractModalFrom("modal");
      setSelectedUnitId(unitId);
    });

    pcApp.on("api:onHotspotClicked", (hotspotId) => {
      //openGalleryPhoto(hotspotId);
    });

    pcApp.on("testWebCommuncationButton:testWebEvent", () =>
      console.log("test has been fired")
    );
    pcApp.fire("setUnitColours", modelUnits);
  };

  useEffect(() => {
    if (proxima.current && proxima.current.canvas) {
      proximaContainer.current.appendChild(proxima.current.canvas);
      connectWebGLEvents(proxima.current.app);
    }
  }, [props.isProximaLoaded]);

  const [modelPosition, setModelPosition] = useState("top");

  const { isUnitCardSlideoutOpen } = useOpenClosePane("UnitCardSlideout");

  const {
    isVirtualTourOpen,
    setIsVirtualTourOpenToClosed,
    toggleIsVirtualTourOpen,
  } = useOpenClosePane("VirtualTour");

  const {
    isViewStudySlideUpOpen,
    setIsViewStudySlideUpOpenToClosed,
    toggleIsViewStudySlideUpOpen,
  } = useOpenClosePane("ViewStudySlideUp");

  const [doShowFilter, setDoShowFilter] = useState(false);
  const [doShowBottomPane] = useState(true);

  const [modelType] = useState("3D Model");
  const [floorSelector] = useState({
    isOpen: false,
    floorId: 1,
  });
  const unitDetailOpen = props.isUnitDetailOpen;
  const isSavedHomesCompareOpen = props.isSavedHomesCompareOpen;
  const isSavedHomesSlideoutOpen = props.isSavedHomesSlideoutOpen;
  const isFloorSelectorOpen = floorSelector.isOpen;

  const classes = useStyles({
    doShowBottomPane,
    doShowFilter,
    unitDetailOpen,
    isUnitCardSlideoutOpen,
    isSavedHomesCompareOpen,
    isSavedHomesSlideoutOpen,
    isFloorSelectorOpen,
    modelType,
    ThemeData: project.ThemeData,
    isBigScreen,
  });

  let unit = null;
  if (selectedUnitId && project?.units && project.units.length > 0) {
    unit = project.units.filter((u) => u.threeDId === selectedUnitId)[0];
  }

  // const viewStudyUrl = project.settings?.viewStudyUrl || null
  const viewStudyUrl = unit?.viewStudyUrl
    ? unit.viewStudyUrl
    : unit?.floorPlan.viewStudyUrl
    ? unit.floorPlan.viewStudyUrl
    : project.settings?.viewStudyUrl
    ? project.settings?.viewStudyUrl
    : null;
  // const virtualTourUrl = project.settings?.threeDUrl || null
  const virtualTourUrl = unit?.virtualTour
    ? unit.virtualTour
    : unit?.floorPlan.virtualTour
    ? unit.floorPlan.virtualTour
    : project.settings?.threeDUrl
    ? project.settings?.threeDUrl
    : null;

  const { unitCustomIdsFiltered, setUnitCustomIdsFiltered } =
    useGlobalUnitsFilterResult();
  const globalUnitsFilter = useGlobalUnitsFilter();

  // Send events to WebGL
  useEffect(() => {
    if (interactModalFrom === "modal") {
      if (unitCustomIdsFiltered.length > 0) {
        globalUnitsFilter.resetFilter();
        setUnitCustomIdsFiltered([]);
      }
    } else if (
      interactModalFrom.search(/carousel|sitePlan/) === 0 &&
      selectedUnitId !== -1
    ) {
      proxima.current.app.fire("highlightByUnitIdMany", [selectedUnitId]);
    } else if (unitCustomIdsFiltered && proxima.current.app) {
      proxima.current.app.fire("highlightByUnitIdMany", unitCustomIdsFiltered);
    }
  }, [proxima, selectedUnitId, unitCustomIdsFiltered]);

  useEffect(() => {
    if (project) globalUnitsFilter.applyFilter(project.units);
  }, [project, globalUnitsFilter.globalUnitsFilter]);

  const toggleUnitDetailPane = () => {
    return props.setUnitDetailOpen(!props.isUnitDetailOpen);
  };

  const location = useLocation();
  const selectedUnit = selectedUnitId
    ? project.units.filter((u) => u.threeDId === selectedUnitId)[0]
    : null;

  const { savedHomes, toggleSavedHome } = useSavedHomes();

  const [selectedUnitData, setSelectedUnitData] = useState(null);

  let hooks = {
    savedHomes,
    toggleSavedHome,
    selectedUnitData,
    setSelectedUnitData,
  };

  const renderComponentContent = (position, name = "model") => {
    if (name === "model") {
      return (
        <div className={classes.modelWrapper}>
          <div className={classes.emptyWrapper} />
          <motion.div
            ref={proximaContainer}
            className={classes.canvasWrapper}
            id="canvasWrapper"
            animate={isUnitCardSlideoutOpen ? "partialOpen" : "fullOpen"}
            transition={{
              type: "tween",
              duration: 0.5,
            }}
          />
        </div>
      );
    } else if (name === "floorplan") {
      return (
        <UnitsGeneric
          project={project}
          twoTile={modelPosition !== "left"}
          addFilteredResult={true}
          isToAllowCompare={false}
          isToShowDualPaneForTableDisplay={false}
          isToAlwaysAllowSelection={true}
          selectedUnitId={selectedUnitId}
          setSelectedUnitId={setSelectedUnitId}
          setInteractModalFrom={setInteractModalFrom}
          selectedUnitData={hooks.selectedUnitData}
          setSelectedUnitData={hooks.setSelectedUnitData}
          toggleIsViewStudySlideUpOpenFcn={toggleIsViewStudySlideUpOpen}
          isToUseEmbeddedVirtualTour={false}
          toggleIsVirtualTourOpen={toggleIsVirtualTourOpen}
          toggleUnitDetailPane={toggleUnitDetailPane}
          cardOnClickLeftButton={props.cardOnClickLeftButton}
          calledByCarousel={position === "top" ? true : false}
        />
      );
    } else if (name === "siteplan") {
      const sitemap = project.buildings
        .map((building) => {
          if (building.siteMaps !== null) {
            return building.siteMaps;
          }
          return null;
        })
        .filter((sitemap) => sitemap !== null);

      if (sitemap.length > 0) {
        return (
          <div className={classes.siteplan}>
            <SiteMap
              project={project}
              selectedUnitId={selectedUnitId}
              setSelectedUnitId={setSelectedUnitId}
              unitCustomIdsFiltered={unitCustomIdsFiltered}
              setUnitCustomIdsFiltered={setUnitCustomIdsFiltered}
              interactModalFrom={interactModalFrom}
              setInteractModalFrom={setInteractModalFrom}
              imageWidth="450"
              isProximaLoaded={props.isProximaLoaded}
              availabilityColours={availabilityColours}
            />
          </div>
        );
      } else {
        const siteplan = project.navs
          .filter((nav) => nav.slug === "floorplans")[0]
          .pages.filter((page) => page.slug === "site-plan")[0];
        const photo = getImageBySize(siteplan?.photo, "original");

        return (
          photo && (
            <div
              className={classes.siteplan}
              style={photo && { backgroundImage: `url(${photo.link})` }}
            />
          )
        );
      }
    } else {
      return <></>;
    }
  };

  return (
    <>
      <Layout
        project={project}
        isVirtualTourOpen={isVirtualTourOpen}
        setIsVirtualTourOpenToClosed={setIsVirtualTourOpenToClosed}
        isViewStudySlideUpOpen={isViewStudySlideUpOpen}
        setIsViewStudySlideUpOpenToClosed={setIsViewStudySlideUpOpenToClosed}
        selectedUnitData={selectedUnit}
        isUnitDetailOpen={props.isUnitDetailOpen}
        setUnitDetailOpen={props.setUnitDetailOpen}
        toggleIsSavedHomesSlideoutOpen={props.toggleIsSavedHomesSlideoutOpen}
        isSavedHomesSlideoutOpen={props.isSavedHomesSlideoutOpen}
        toggleIsSavedHomesCompareOpen={props.toggleIsSavedHomesCompareOpen}
        isSavedHomesCompareOpen={props.isSavedHomesCompareOpen}
        toggleIsRegisterOpen={props.toggleIsRegisterOpen}
        footerHidden
      >
        {/* <HeaderComponents
          project={project}
          siteLogo={siteLogo}
          noRenderSaveHomes={true}
          height={150}
        /> */}
        <div className={classes.wrapper}>
          <div className={classes.topWrapper}>
            {renderComponentContent(
              "top",
              modelPosition === "left"
                ? "floorplan"
                : modelPosition === "right"
                ? "siteplan"
                : "model"
            )}
            {!doShowFilter && (
              <>
                <ButtonOutline
                  ThemeData={ThemeData}
                  key="closefilter"
                  padding={16}
                  border={`1px solid ${ThemeData.colours.vibrantGreen}`}
                  textColour={ThemeData.colours.white}
                  hoverTextColour={ThemeData.colours.white}
                  backgroundColour={ThemeData.colours.vibrantGreen}
                  hoverBackgroundColour={ThemeData.colours.vibrantGreen}
                  className={classes.refreshButton}
                  onClick={() =>
                    setModelPosition(modelPosition !== "left" ? "left" : "top")
                  }
                >
                  <RefreshIcon
                    stroke={ThemeData.colours.white}
                    style={{ marginRight: 10 }}
                  />{" "}
                  {modelPosition !== "left" ? "Floorplan" : "3D model"}
                </ButtonOutline>
              </>
            )}
          </div>

          {doShowFilter && (
            <motion.div
              initial={{ y: "40vh" }}
              animate={{ y: 0 }}
              exit={{ y: "40vh" }}
              transition={{ type: "tween", duration: 0.5 }}
              className={classes.filterWrapper}
            >
              <FilterPane
                project={project}
                doShowFilter={doShowFilter}
                setDoShowFilter={setDoShowFilter}
                modelType={modelType}
                location={location}
                interactModalFrom={interactModalFrom}
                setInteractModalFrom={setInteractModalFrom}
                setSelectedUnitId={setSelectedUnitId}
              />
            </motion.div>
          )}
          {doShowBottomPane && (
            <div
              className={`${classes.contentWrapper} ${
                doShowFilter ? classes.hide : ""
              }`}
            >
              <div className={classes.contentLeft}>
                {renderComponentContent(
                  "left",
                  modelPosition === "left" ? "model" : "floorplan"
                )}
              </div>
              <div className={classes.contentRight}>
                {renderComponentContent(
                  "right",
                  modelPosition === "right" ? "model" : "siteplan"
                )}
              </div>
            </div>
          )}
          {!doShowFilter && props.isProximaLoaded && (
            <div className={classes.filterNavigation}>
              <FilterHeader
                project={project}
                hideFilter={true}
                doShowFilter={doShowFilter}
                setDoShowFilter={setDoShowFilter}
                setSelectedUnitId={setSelectedUnitId}
              />
            </div>
          )}
        </div>
        <div className={classes.footer}>
          <FooterComponents
            project={project}
            toggleIsSavedHomesSlideoutOpen={() =>
              props.toggleIsSavedHomesSlideoutOpen()
            }
            setIsContentPageCarousel={props.setIsContentPageCarousel}
            isContentPage={false}
            setIsBack={setIsBack}
            isBack={isBack}
            setIsOpenSavedHomes={setIsOpenSavedHomes}
            isOpenSavedHomes={isOpenSavedHomes}
          />
        </div>
        <ViewStudy
          project={project}
          isOpen={isViewStudySlideUpOpen}
          toggleViewStudyFcn={toggleIsViewStudySlideUpOpen}
          viewUrl={viewStudyUrl && viewStudyUrl}
          doUseHeader={true}
        />
        <VirtualTour
          project={project}
          isOpen={isVirtualTourOpen}
          src={virtualTourUrl && virtualTourUrl}
          toggleVirtualTourFcn={() => toggleIsVirtualTourOpen()}
          doUseHeader={true}
        />
      </Layout>
    </>
  );
};

export default Proximation;
