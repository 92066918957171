const UtilFcns = {
	// Sends anything not a number to 0
	parseFloatSafe: ( toParse ) => {
		if (
			isNaN( toParse ) ||
			toParse === null ||
			( typeof ( toParse.trim ) === "function" && toParse.trim() === "" ) )
			return 0;

		return parseFloat( toParse );
	},
	// https://stackoverflow.com/questions/123999/how-can-i-tell-if-a-dom-element-is-visible-in-the-current-viewport
	isElementInViewport: ( el ) => {

		// Special bonus for those using jQuery
		// if ( typeof jQuery === "function" && el instanceof jQuery ) {
		// 	el = el[0];
		// }

		var rect = el.getBoundingClientRect();

		return (
			rect.top >= 0 &&
			rect.left >= 0 &&
			rect.bottom <= ( window.innerHeight || document.documentElement.clientHeight ) && /* or $(window).height() */
			rect.right <= ( window.innerWidth || document.documentElement.clientWidth ) /* or $(window).width() */
		);
	},
}



export default UtilFcns;
