import React, { useEffect, useState, useContext } from "react";

import { createUseStyles } from "react-jss";

import { motion } from "framer-motion";
import { useHistory, useLocation } from "react-router-dom";

import ConstantData from "data/ConstantData";

import useOpenClosePane from "hooks/useOpenClosePane";
import useGlobalUnitsFilterResult from "hooks/useGlobalUnitsFilterResult";
import useGlobalUnitsFilter from "hooks/useGlobalUnitsFilter";
import UnitFcnContext from "contexts/UnitFcnContext";

import Layout from "./Layout";
import ViewStudy from "./ViewStudy";
import VirtualTour from "./VirtualTour";
import UnitCardSlideout from "components/UnitCardSlideout";
import FilterHeader from "./FilterHeader";
import FilterPane from "./FilterPane";
import ButtonOutline from "./ButtonOutline";
import { FaCaretDown } from "react-icons/fa";
import { ReactComponent as FullModelIcon } from "../img/3d_model_icon.svg";
import { ReactComponent as FullModelIconActive } from "../img/3d_model_icon_active.svg";
import { ReactComponent as FloorplateModelIcon } from "../img/2d_model_icon.svg";
import { ReactComponent as FloorplateModelIconActive } from "../img/2d_model_icon_active.svg";
import { ReactComponent as ChevronRighMedium } from "../img/chevron_left_white_medium.svg";
import { getOrdinal } from "util/helper";
import SiteMap from "./SiteMap";
import FooterComponents from "./FooterComponents";

const useStyles = createUseStyles({
  canvasElementsWrapper: {
    // border:'1px solid red',
    // marginTop: '-700px',
    display: "flex",
    flexDirection: "column",
    width: "100%",
    paddingRight: (props) => (props.isSavedHomesSlideoutOpen ? 450 : 0),
    background: (props) => props.ThemeData.colours.white,
    paddingTop: 10,
    alignItems: "center",
  },
  canvasWrapper: {
    height: "calc( var( --app-height) - 100px)",
    position: "relative",
    width: "100%",
    display: (props) =>
      props.doShowFilter ||
      props.unitDetailOpen ||
      props.isSavedHomesCompareOpen
        ? "none"
        : "",
    "&.hide": {
      display: "none",
    },
  },
  emptyWrapper: {
    height: "calc( var( --app-height) - 200px)",
    position: "relative",
    // width: '100%',
    backgroundColor: (props) => props.ThemeData.colours.white,
    display: (props) =>
      props.doShowFilter ||
      props.unitDetailOpen ||
      props.isSavedHomesCompareOpen
        ? ""
        : "none",
  },
  keyplan: {
    width: 330,
    position: "absolute",
    right: 100,
    "@media (max-width: 1300px)": {
      right: 40,
    },
    top: 680,
    "@media (max-height: 850px)": {
      top: 630,
    },
    display: "flex",
    justifyContent: "center",
  },
  keyplanImg: {
    border: (props) => `1px solid ${props.ThemeData.colours.blackBg15}`,
    backgroundColor: (props) => props.ThemeData.colours.white,
    padding: "10px 20px 5px 20px",
    flex: "0 0 auto",
    maxHeight: 200,
    "@media (max-height: 900px)": {
      maxHeight: 150,
    },
    "@media (max-height: 800px)": {
      maxHeight: 100,
    },
    objectFit: "contain",
    marginBottom: 5,
  },
  slideAndCanvasContainer: {
    display: (props) => (props.unitDetailOpen ? "none" : "flex"),
    flexDirection: "row",
  },
  modelViewSwitch: {
    width: 130,
    margin: "0 auto",
  },
  iconModelSelect2D: {
    height: 50,
    width: 50,
    margin: "0 auto",
    cursor: "pointer",
  },
  iconModelSelect3D: {
    height: 50,
    width: 50,
    margin: "0 auto",
    cursor: "pointer",
  },
  disclaimer: {
    position: "absolute",
    bottom: 80,
    marginLeft: 15,
    marginRight: 15,
    fontStyle: "italic",
    fontWeight: 100,
    textAlign: "center",
    width: 300,
    color: "rgba(0, 0, 0, 0.75)",
    "@media (max-width: 1200px)": {
      left: (props) => props.isUnitSlideooutOpen && 250,
    },
  },
  footer: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    background: "white",
    // zIndex:'1010'
  },
  disclaimerWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  top: {
    flex: "0 0 auto",
    transition: "left 0.5s",
  },
  closeX: {
    // position: 'absolute',
    // left: props => props.slideOutWidth,
    display: "flex",
    alignItems: "center",
    height: 50,
    width: (props) => (props.isUnitCardSlideoutOpen ? 35 : 165),
    backgroundColor: (props) => props.ThemeData.colours.white,
    boxShadow: "0px 4px 10px rgb(0 0 0 / 33%)",
    cursor: "pointer",
    margin: (props) => (props.isUnitCardSlideoutOpen ? "0 25px" : "0 50px"),
    zIndex: 1,
    borderRadius: 5,
  },
  openIcon: {
    flex: "0 0 auto",
    display: "flex",
  },
  openText: {
    flex: "0 0 auto",
    paddingRight: 20,
    color: (props) => props.ThemeData.colours.secondaryAccent,
    fontSize: 12,
    textTransform: "uppercase",
    letterSpacing: 4,
  },
  floorViewSwitch: {
    width: 130,
    margin: "0 auto",
    borderRadius: (props) =>
      props.modelType === "2D Floorplate" && "0px 100px 100px 0px",
  },
  modelViewSwitchButtons: {
    display: "flex",
    flexDirection: "row",
    position: "relative",
    height: 112,
    width: 240,
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  floorSwitchButtonsWrapper: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  floorSwitchButtons: {
    fontFamily: (props) => props.ThemeData.fonts.title,
    color: (props) => props.ThemeData.colours.white,
    backgroundColor: (props) => props.ThemeData.colours.primaryBg,
    border: "none",
    padding: 20,
    //width: 100,
    textTransform: "uppercase",
    textAlign: "left",
    "&:hover": {
      backgroundColor: (props) => props.ThemeData.colours.primaryAccent,
      color: (props) => props.ThemeData.colours.primaryBg,
      cursor: "pointer",
    },
  },
});

function Proximation({ proxima, project, proximaContainer, ...props }) {
  const { ThemeData, units = [], logo = [] } = project;
  const {
    selectedUnitId,
    setSelectedUnitId,
    setIsUnitDetailOpen,
    interactModalFrom,
    setInteractModalFrom,
    // setIsBack,
    // isBack,
    // isOpenSavedHomes,
    // setIsOpenSavedHomes,
  } = useContext(UnitFcnContext);
  const siteLogo = logo.length > 0 ? logo[0].link : null;
  const history = useHistory();

  const [isBack, setIsBack] = useState(false);
  const [isOpenSavedHomes, setIsOpenSavedHomes] = useState(false);
  useEffect(() => {
    if (isOpenSavedHomes) props.toggleIsSavedHomesSlideoutOpen();
  }, [isOpenSavedHomes]);
  const handleBackButtonClick = () => {
    setIsOpenSavedHomes(false);
    setIsBack(false);
    if (props.toggleSlideUpIsOpen) {
      props.toggleSlideUpIsOpen();
      props.setIsContentPageCarouselOpen(false);
    } else if (props.isUnitDetailOpen) {
      props.setIsUnitDetailOpen(false);
      props.setIsContentPageCarouselOpen(false);
    } else {
      history.goBack();
    }
  };
  useEffect(() => {
    if (isBack) handleBackButtonClick();
  }, [isBack]);
  const availabilityColours = {
    available: "0CE015A6",
    sold: "FF000099",
    hold: "FBED2FA6",
    inProgress: "FBED2FA6",
  };
  const modelUnits = units.map((u) => ({
    id: u.threeDId,
    color: availabilityColours[u.status] || availabilityColours.sold,
  }));

  let floors = [];
  if (units.length > 0) {
    units
      .filter((u) => u.floor !== undefined && u.floor !== null)
      .forEach((u) => {
        const found = floors.filter((f) => f.id === u.floor);
        if (found.length > 0) return;
        floors.push({
          id: u.floor,
          name: `${getOrdinal(u.floor)} floor`,
          value: u.floor,
        });
      });
  }

  const sitemap = project.buildings
    .map((building) => {
      if (building.siteMaps !== null) {
        return building.siteMaps;
      }
      return null;
    })
    .filter((sitemap) => sitemap !== null);

  useEffect(() => {
    if (!proxima.current || !proxima.current.canvas) {
      proxima.current = {
        pc: window.pc,
        INPUT_SETTINGS: window.INPUT_SETTINGS,
        CONFIG_FILENAME: window.CONFIG_FILENAME,
        SCENE_PATH: window.SCENE_PATH,
        PRELOAD_MODULES: window.PRELOAD_MODULES,
        loadModules: window.loadModules,
        CONTEXT_OPTIONS: window.CONTEXT_OPTIONS,
        ASSET_PREFIX: window.ASSET_PREFIX,
        SCRIPT_PREFIX: window.SCRIPT_PREFIX,
        SCRIPTS: window.SCRIPTS,
        canvas: null,
        devices: null,
        app: null,
      };

      let pc = proxima.current.pc;
      let INPUT_SETTINGS = proxima.current.INPUT_SETTINGS;
      let CONFIG_FILENAME = proxima.current.CONFIG_FILENAME;
      let SCENE_PATH = proxima.current.SCENE_PATH;
      let PRELOAD_MODULES = proxima.current.PRELOAD_MODULES;
      let loadModules = proxima.current.loadModules;
      let ASSET_PREFIX = proxima.current.ASSET_PREFIX;

      pc.script.legacy = false; //Bao moved this from __settings__.js
      (function () {
        var CANVAS_ID = "application-canvas";

        // var canvas, devices, app;

        var createCanvas = function () {
          // if ( !canvas ) {
          proxima.current.canvas = document.createElement("canvas");
          // 	doAppendCanvas = true;
          // }

          proxima.current.canvas.setAttribute("id", CANVAS_ID);
          proxima.current.canvas.setAttribute("tabindex", 0);
          // canvas.style.visibility = 'hidden';

          // Disable I-bar cursor on click+drag
          proxima.current.canvas.onselectstart = function () {
            return false;
          };

          // if ( doAppendCanvas )
          proximaContainer.current.appendChild(proxima.current.canvas);
          // document.getElementById( "canvasWrapper" ).appendChild( proxima.current.canvas );
          // document.body.appendChild(canvas);

          return proxima.current.canvas;
        };

        var createInputDevices = function (canvas) {
          return {
            elementInput: new pc.ElementInput(canvas, {
              useMouse: INPUT_SETTINGS.useMouse,
              useTouch: INPUT_SETTINGS.useTouch,
            }),
            keyboard: INPUT_SETTINGS.useKeyboard
              ? new pc.Keyboard(window)
              : null,
            mouse: INPUT_SETTINGS.useMouse ? new pc.Mouse(canvas) : null,
            gamepads: INPUT_SETTINGS.useGamepads ? new pc.GamePads() : null,
            touch:
              INPUT_SETTINGS.useTouch && pc.platform.touch
                ? new pc.TouchDevice(canvas)
                : null,
          };
        };

        var configureCss = function (fillMode, width, height) {
          // Configure resolution and resize event
          if (proxima.current.canvas.classList) {
            proxima.current.canvas.classList.add("fill-mode-" + fillMode);
          }

          // css media query for aspect ratio changes
          var css =
            "@media screen and (min-aspect-ratio: " +
            width +
            "/" +
            height +
            ") {";
          css += "    #application-canvas.fill-mode-KEEP_ASPECT {";
          css += "        width: auto;";
          css += "        height: 100%;";
          css += "        margin: 0 auto;";
          css += "    }";
          css += "}";

          // append css to style
          if (document.head.querySelector) {
            document.head.querySelector("style").innerHTML += css;
          }
        };

        var reflow = function () {
          var canvasWrapper = document.getElementById("canvasWrapper");
          if (canvasWrapper) {
            proxima.current.app.resizeCanvas(
              canvasWrapper.clientWidth,
              canvasWrapper.clientHeight
            );
            proxima.current.canvas.style.width = "100%";
            proxima.current.canvas.style.height = "100%";

            var fillMode = proxima.current.app._fillMode;

            if (
              fillMode === pc.FILLMODE_NONE ||
              fillMode === pc.FILLMODE_KEEP_ASPECT
            ) {
              proxima.current.canvas.style.marginTop = "";
            }
          }
        };

        var displayError = function (html) {
          var div = document.createElement("div");

          div.innerHTML = [
            '<table style="background-color: #8CE; width: 100%; height: 100%;">',
            "  <tr>",
            '      <td align="center">',
            '          <div style="display: table-cell; vertical-align: middle;">',
            '              <div style="">' + html + "</div>",
            "          </div>",
            "      </td>",
            "  </tr>",
            "</table>",
          ].join("\n");

          document.body.appendChild(div);
        };

        proxima.current.canvas = createCanvas();
        proxima.current.devices = createInputDevices(proxima.current.canvas);

        try {
          proxima.current.app = new pc.Application(proxima.current.canvas, {
            elementInput: proxima.current.devices.elementInput,
            keyboard: proxima.current.devices.keyboard,
            mouse: proxima.current.devices.mouse,
            gamepads: proxima.current.devices.gamepads,
            touch: proxima.current.devices.touch,
            graphicsDeviceOptions: proxima.current.CONTEXT_OPTIONS,
            assetPrefix: proxima.current.ASSET_PREFIX || "",
            scriptPrefix: proxima.current.SCRIPT_PREFIX || "",
            scriptsOrder: proxima.current.SCRIPTS || [],
          });
          //app.floorPlanManager.setFloorPlanEnabled(-1)
        } catch (e) {
          if (e instanceof pc.UnsupportedBrowserError) {
            displayError(
              "This page requires a browser that supports WebGL.<br/>" +
                '<a href="http://get.webgl.org">Click here to find out more.</a>'
            );
          } else if (e instanceof pc.ContextCreationError) {
            displayError(
              "It doesn't appear your computer can support WebGL.<br/>" +
                '<a href="http://get.webgl.org/troubleshooting/">Click here for more information.</a>'
            );
          } else {
            displayError("Could not initialize application. Error: " + e);
          }

          return;
        }

        var configure = function () {
          proxima.current.app.configure(CONFIG_FILENAME, function (err) {
            const app = proxima.current.app;

            if (err) {
              console.error(err);
            }

            configureCss(app._fillMode, app._width, app._height);

            // do the first reflow after a timeout because of
            // iOS showing a squished iframe sometimes
            setTimeout(function () {
              reflow();

              window.addEventListener("resize", reflow, false);
              window.addEventListener("orientationchange", reflow, false);

              app.preload(function (err) {
                if (err) {
                  console.error(err);
                }

                app.loadScene(SCENE_PATH, function (err) {
                  if (err) {
                    console.error(err);
                  }

                  app.start();

                  props.setIsProximaLoaded(true);
                });
              });
            });
          });
        };

        if (PRELOAD_MODULES && PRELOAD_MODULES.length > 0) {
          loadModules(PRELOAD_MODULES, ASSET_PREFIX, configure);
        } else {
          configure();
        }
      })();

      pc.script.createLoadingScreen(function (app) {
        var showSplash = function () {
          // splash wrapper
          var wrapper = document.createElement("div");
          wrapper.id = "application-splash-wrapper";
          document.body.appendChild(wrapper);

          // splash
          var splash = document.createElement("div");
          splash.id = "application-splash";
          wrapper.appendChild(splash);
          splash.style.display = "none";

          var logo = document.createElement("img");
          logo.src = siteLogo ? siteLogo : ASSET_PREFIX + "logo.png";
          splash.appendChild(logo);
          logo.onload = function () {
            splash.style.display = "block";
          };

          var container = document.createElement("div");
          container.id = "progress-bar-container";
          splash.appendChild(container);

          var bar = document.createElement("div");
          bar.id = "progress-bar";
          container.appendChild(bar);
        };

        var hideSplash = function () {
          var splash = document.getElementById("application-splash-wrapper");
          splash && splash.parentElement.removeChild(splash);
        };

        var setProgress = function (value) {
          var bar = document.getElementById("progress-bar");
          if (bar) {
            value = Math.min(1, Math.max(0, value));
            bar.style.width = value * 100 + "%";
          }
        };

        var createCss = function () {
          var css = [
            "body {",
            `    background-color: ${ThemeData.colours.primaryBg};`,
            "}",

            "#application-canvas {",
            "	position: relative",
            "}",

            "#application-canvas:after {",
            'content: "";',
            "position:absolute;",
            "z-index:999;",
            "top:0;",
            "left:0;",
            "width:100%;",
            "height:100%;",
            "cursor: not-allowed;",
            "pointer-events: none;",
            "}",

            "#application-splash-wrapper {",
            "    position: absolute;",
            "    top: 100px;",
            "    left: 0;",
            "    height: calc( 100vh - 100px) !important;",
            "    height: calc( var(--app-height) - 100px);",
            "    width: 100%;",
            `    background-color: ${ThemeData.colours.primaryBg};`,
            "}",

            "#application-splash {",
            "    position: absolute;",
            "    top: 46%;",
            "    width: 512px;",
            "    left: 50%;",
            "		 transform: translate(-50%, -50%);",
            "		 -moz-transform: translate(-50%, -50%);",
            "		 -webkit-transform: translate(-50%, -50%);",
            "}",

            "#application-splash img {",
            "    width: 100%;",
            "    margin-bottom: 25px;",
            "}",

            "#progress-bar-container {",
            "    margin: 20px auto 0 auto;",
            "    height: 2px;",
            "    width: 100%;",
            `    background-color: ${ThemeData.colours.primaryAccentFade}`,
            "}",

            "#progress-bar {",
            "    width: 0%;",
            "    height: 100%;",
            `    background-color: ${ThemeData.colours.primaryAccent};`,
            "}",
            "@media (max-width: 480px) {",
            "    #application-splash {",
            "        width: 170px;",
            "        left: calc(50% - 85px);",
            "    }",
            "}",
          ].join("\n");

          var style = document.createElement("style");
          style.type = "text/css";
          if (style.styleSheet) {
            style.styleSheet.cssText = css;
          } else {
            style.appendChild(document.createTextNode(css));
          }

          document.head.appendChild(style);
          proximaContainer.current.appendChild(style);
        };

        createCss();

        showSplash();

        app.on("preload:end", function () {
          app.off("preload:progress");
        });
        app.on("preload:progress", setProgress);
        app.on("start", hideSplash);

        connectWebGLEvents(app);
      });
    }
  }, [proxima, proximaContainer]);

  const connectWebGLEvents = (pcApp) => {
    pcApp.on("api:onUnitSelected", (unitId) => {
      setInteractModalFrom("modal");
      setSelectedUnitId(unitId);
      //globalUnitsFilter.resetFilterByUnitIds()
    });

    pcApp.on("api:onHotspotClicked", () => {
      //openGalleryPhoto(hotspotId);
    });

    //pcApp.fire("api:onTestWebEvent");
    pcApp.on("testWebCommuncationButton:testWebEvent", () =>
      console.log("test has been fired")
    );

    // Set highlight colours for each units
    pcApp.fire("setUnitColours", modelUnits);
  };

  useEffect(() => {
    if (proxima.current && proxima.current.canvas) {
      proximaContainer.current.appendChild(proxima.current.canvas);
      connectWebGLEvents(proxima.current.app);
    }
  }, [props.isProximaLoaded]);

  // Process window events from WebGL
  useEffect(() => {
    const handleHotspotClick = (e) => {
      if (
        e.origin === window.location.origin &&
        e.data &&
        e.data.message &&
        e.data.message ===
          ConstantData.webglWindowMessageTypes.webglHotspotClicked
      ) {
      }
    };

    window.addEventListener("message", handleHotspotClick);
    return () => window.removeEventListener("message", handleHotspotClick);
  });

  const {
    isUnitCardSlideoutOpen,
    setIsUnitCardSlideoutOpenToOpen,
    toggleIsUnitCardSlideoutOpen: toggleUnitCardSlideoutPane,
  } = useOpenClosePane("UnitCardSlideout");

  const {
    isVirtualTourOpen,
    setIsVirtualTourOpenToClosed,
    toggleIsVirtualTourOpen,
  } = useOpenClosePane("VirtualTour");

  const {
    isViewStudySlideUpOpen,
    setIsViewStudySlideUpOpenToClosed,
    toggleIsViewStudySlideUpOpen,
  } = useOpenClosePane("ViewStudySlideUp");

  const [doShowFilter, setDoShowFilter] = useState(false);
  const [modelType, setModelType] = useState("3D Model");
  const [floorSelector, setFloorSelector] = useState({
    isOpen: false,
    floorId: floors[0].value,
  });
  const [currViewType, setModleView] = useState(
    ConstantData.webglViewTypes.building3DModel
  );
  const [hover, toggleOnHover] = useState(false);
  const unitDetailOpen = props.isUnitDetailOpen;
  const isSavedHomesCompareOpen = props.isSavedHomesCompareOpen;
  const isSavedHomesSlideoutOpen = props.isSavedHomesSlideoutOpen;
  const isFloorSelectorOpen = floorSelector.isOpen;

  const classes = useStyles({
    doShowFilter,
    unitDetailOpen,
    isUnitCardSlideoutOpen,
    isSavedHomesCompareOpen,
    isSavedHomesSlideoutOpen,
    isFloorSelectorOpen,
    modelType,
    ThemeData: project.ThemeData,
    floorLength: floors.length,
  });

  let unit = null;
  if (selectedUnitId && project?.units && project.units.length > 0) {
    unit = project.units.filter((u) => u.threeDId === selectedUnitId)[0];
  }

  const viewStudyUrl = unit?.viewStudyUrl
    ? unit.viewStudyUrl
    : unit?.floorPlan.viewStudyUrl
    ? unit.floorPlan.viewStudyUrl
    : project.settings?.viewStudyUrl
    ? project.settings?.viewStudyUrl
    : null;
  const virtualTourUrl = unit?.virtualTour
    ? unit.virtualTour
    : unit?.floorPlan.virtualTour
    ? unit.floorPlan.virtualTour
    : project.settings?.threeDUrl
    ? project.settings?.threeDUrl
    : null;

  const { unitCustomIdsFiltered, setUnitCustomIdsFiltered } =
    useGlobalUnitsFilterResult();
  const globalUnitsFilter = useGlobalUnitsFilter();

  useEffect(() => {
    if (sitemap.length > 0 && currViewType === "byFloorplateSimple") return;

    if (interactModalFrom === "modal") {
      if (unitCustomIdsFiltered.length > 0) {
        globalUnitsFilter.resetFilter();
        setUnitCustomIdsFiltered([]);
      }
      if (selectedUnitId !== -1) setIsUnitCardSlideoutOpenToOpen();
    } else if (
      interactModalFrom.search(/carousel|sitePlan/) !== -1 &&
      selectedUnitId !== -1 &&
      proxima.current.app
    ) {
      proxima.current.app.fire("highlightByUnitIdMany", [selectedUnitId]);
    } else if (unitCustomIdsFiltered && proxima.current.app) {
      proxima.current.app.fire("highlightByUnitIdMany", unitCustomIdsFiltered);
    }
  }, [
    floorSelector.floorId,
    currViewType,
    proxima,
    selectedUnitId,
    unitCustomIdsFiltered,
  ]);

  const [browserHeight, setBrowserHeight] = useState(0);
  useEffect(() => {
    const innerHeight = window.innerHeight - 330;
    setBrowserHeight(innerHeight);
  }, [isUnitCardSlideoutOpen]);

  const toggleUnitDetailPane = () => {
    return props.setUnitDetailOpen(!props.isUnitDetailOpen);
  };

  function setViewType(viewType) {
    let webglViewArgument;
    switch (viewType) {
      case ConstantData.webglViewTypes.byFloorplateDetailed:
      case ConstantData.webglViewTypes.byFloorplateSimple:
        setModelType("2D Floorplate");
        setModleView(viewType);
        webglViewArgument = 1;
        break;
      case ConstantData.webglViewTypes.buliding3DModel:
      default:
        setModelType("3D Model");
        setModleView(viewType);
        webglViewArgument = -1;
        break;
    }
    if (window.pc && sitemap.length === 0)
      window.pc.Application.getApplication().floorPlanManager.setFloorPlanEnabled(
        webglViewArgument
      );
  }

  const defaultTransition = {
    type: "tween",
    // bounce: .25,
    duration: 0.5,
  };
  const location = useLocation();

  const selectedUnit = selectedUnitId
    ? project.units.filter((u) => u.threeDId === selectedUnitId)[0]
    : null;

  return (
    <>
      {props.isProximaLoaded && (
        <FilterPane
          project={project}
          doShowFilter={doShowFilter}
          setDoShowFilter={setDoShowFilter}
          modelType={modelType}
          location={location}
          interactModalFrom={interactModalFrom}
          setInteractModalFrom={setInteractModalFrom}
          setSelectedUnitId={setSelectedUnitId}
        />
      )}
      <Layout
        project={project}
        isVirtualTourOpen={isVirtualTourOpen}
        setIsVirtualTourOpenToClosed={setIsVirtualTourOpenToClosed}
        isViewStudySlideUpOpen={isViewStudySlideUpOpen}
        setIsViewStudySlideUpOpenToClosed={setIsViewStudySlideUpOpenToClosed}
        selectedUnitData={selectedUnit}
        isUnitDetailOpen={props.isUnitDetailOpen}
        setUnitDetailOpen={props.setUnitDetailOpen}
        toggleIsSavedHomesSlideoutOpen={props.toggleIsSavedHomesSlideoutOpen}
        isSavedHomesSlideoutOpen={props.isSavedHomesSlideoutOpen}
        toggleIsSavedHomesCompareOpen={props.toggleIsSavedHomesCompareOpen}
        isSavedHomesCompareOpen={props.isSavedHomesCompareOpen}
        toggleIsRegisterOpen={props.toggleIsRegisterOpen}
        footerHidden
      >
        {!project.slug.includes("mpv2") && props.isProximaLoaded && (
          <FilterHeader
            project={project}
            doShowFilter={doShowFilter}
            setDoShowFilter={setDoShowFilter}
            modelType={modelType}
            interactModalFrom={interactModalFrom}
            setInteractModalFrom={setInteractModalFrom}
            hideFilter={true}
            setSelectedUnitId={setSelectedUnitId}
          />
        )}
        <div className={classes.slideAndCanvasContainer}>
          {props.isProximaLoaded && (
            <UnitCardSlideout
              project={project}
              unitId={selectedUnitId}
              isOpen={isUnitCardSlideoutOpen}
              toggleIsOpen={() => toggleUnitCardSlideoutPane()}
              toggleUnitDetailPane={() => toggleUnitDetailPane()}
              cardOnClickLeftButton={() => setIsUnitDetailOpen(true)}
              toggleIsViewStudySlideUpOpenFcn={toggleIsViewStudySlideUpOpen}
              selectedUnitId={selectedUnitId}
              setSelectedUnitId={setSelectedUnitId}
              setInteractModalFrom={setInteractModalFrom}
              toggleIsVirtualTourOpen={toggleIsVirtualTourOpen}
            />
          )}

          <div className={classes.emptyWrapper} />
          <div className={classes.canvasElementsWrapper}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
                height: "54px",
              }}
            >
              {!project.slug.includes("mpv2") && props.isProximaLoaded && (
                <div className={classes.top}>
                  <motion.div
                    className={classes.closeX}
                    onClick={() => toggleUnitCardSlideoutPane()}
                    id="unit-card-slideout-toggle"
                    animate={isUnitCardSlideoutOpen ? "open" : "closed"}
                    transition={defaultTransition}
                  >
                    <motion.div
                      className={classes.openIcon}
                      animate={isUnitCardSlideoutOpen ? "open" : "closed"}
                      variants={{
                        open: {
                          rotate: 0,
                        },
                        closed: {
                          rotate: 180,
                        },
                      }}
                      transition={defaultTransition}
                    >
                      <ChevronRighMedium
                        fill={ThemeData.colours.primaryAccent}
                      />
                    </motion.div>
                    <motion.div className={classes.openText}>
                      {!isUnitCardSlideoutOpen && "Show Plans"}
                    </motion.div>
                  </motion.div>
                </div>
              )}

              {props.isProximaLoaded &&
              (project.settings.show3DModel || project.settings.show2DModel) ? (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <ButtonOutline
                    ThemeData={ThemeData}
                    label={
                      modelType === "2D Floorplate"
                        ? project.settings.twoDModelTitle || "2D Floorplate"
                        : modelType
                    }
                    className={classes.modelViewSwitch}
                    id="model-switch-view"
                    padding={20}
                    textColour={ThemeData.colours.white}
                    hoverTextColour={ThemeData.colours.primaryBg}
                    hoverBackgroundColour={ThemeData.colours.primaryAccent}
                    border="none"
                    isRounded
                    backgroundColour={ThemeData.colours.primaryHeaderBg}
                    popupWindow={
                      <div className={classes.modelViewSwitchButtons}>
                        {project.settings.show3DModel && (
                          <div>
                            {currViewType ===
                            ConstantData.webglViewTypes.building3DModel ? (
                              <FullModelIconActive
                                className={classes.iconModelSelect3D}
                                fill={ThemeData.colours.primaryAccent}
                                stroke={ThemeData.colours.primaryAccent}
                                onClick={() =>
                                  setViewType(
                                    ConstantData.webglViewTypes.building3DModel
                                  )
                                }
                              />
                            ) : (
                              <FullModelIcon
                                className={classes.iconModelSelect3D}
                                fill={"none"}
                                stroke={
                                  hover
                                    ? ThemeData.colours.primaryAccent
                                    : ThemeData.colours.forestGreen
                                }
                                onClick={() => {
                                  setViewType(
                                    ConstantData.webglViewTypes.building3DModel
                                  );
                                  toggleOnHover(false);
                                }}
                                onMouseOver={() => toggleOnHover(true)}
                                onMouseLeave={() => toggleOnHover(false)}
                              />
                            )}
                            <div>3D Model</div>
                          </div>
                        )}
                        {project.settings.show2DModel && (
                          <div id="2d-floorplate-switch">
                            {currViewType ===
                            ConstantData.webglViewTypes.byFloorplateSimple ? (
                              <FloorplateModelIconActive
                                className={classes.iconModelSelect2D}
                                fill={ThemeData.colours.primaryAccent}
                                stroke={ThemeData.colours.primaryAccent}
                                onClick={() =>
                                  setViewType(
                                    ConstantData.webglViewTypes
                                      .byFloorplateSimple
                                  )
                                }
                              />
                            ) : (
                              <FloorplateModelIcon
                                className={classes.iconModelSelect2D}
                                fill={"none"}
                                stroke={
                                  hover
                                    ? ThemeData.colours.primaryAccent
                                    : ThemeData.colours.forestGreen
                                }
                                onClick={() => {
                                  setViewType(
                                    ConstantData.webglViewTypes
                                      .byFloorplateSimple
                                  );
                                  toggleOnHover(false);
                                }}
                                onMouseOver={() => toggleOnHover(true)}
                                onMouseLeave={() => toggleOnHover(false)}
                              />
                            )}
                            <div>
                              {project.settings.twoDModelTitle ||
                                "2D Floorplate"}
                            </div>
                          </div>
                        )}
                      </div>
                    }
                  >
                    &nbsp;
                    <FaCaretDown />
                  </ButtonOutline>
                </div>
              ) : (
                <></>
              )}
              <div style={{ width: isUnitCardSlideoutOpen ? 85 : 215 }}></div>
            </div>
            <motion.div
              ref={proximaContainer}
              className={`${classes.canvasWrapper} ${
                currViewType === "byFloorplateSimple" && sitemap.length > 0
                  ? "hide"
                  : ""
              }`}
              id="canvasWrapper"
              animate={isUnitCardSlideoutOpen ? "partialOpen" : "fullOpen"}
              transition={{
                type: "tween",
                duration: 0.5,
              }}
            />
            {currViewType === "byFloorplateSimple" && (
              <SiteMap
                project={project}
                selectedUnitId={selectedUnitId}
                setSelectedUnitId={setSelectedUnitId}
                unitCustomIdsFiltered={unitCustomIdsFiltered}
                setUnitCustomIdsFiltered={setUnitCustomIdsFiltered}
                interactModalFrom={interactModalFrom}
                setInteractModalFrom={setInteractModalFrom}
                browserHeight={browserHeight}
                isProximaLoaded={props.isProximaLoaded}
                setIsUnitCardSlideoutOpenToOpen={
                  setIsUnitCardSlideoutOpenToOpen
                }
                showNav
                selectedFloor={floorSelector.floorId}
                setSelectedFloor={(floor) =>
                  setFloorSelector((prevState) => ({
                    ...prevState,
                    floorId: floor,
                  }))
                }
                landscape={true}
                floors={floors}
                availabilityColours={availabilityColours}
              />
            )}
            <p className={classes.disclaimer}>
              {ConstantData.disclaimerData.disclaimer_4}
            </p>
          </div>
          <div className={classes.footer}>
            <FooterComponents
              project={project}
              toggleIsSavedHomesSlideoutOpen={() =>
                props.toggleIsSavedHomesSlideoutOpen()
              }
              setIsContentPageCarousel={props.setIsContentPageCarousel}
              isContentPage={false}
              setIsBack={setIsBack}
              isBack={isBack}
              setIsOpenSavedHomes={setIsOpenSavedHomes}
              isOpenSavedHomes={isOpenSavedHomes}
              isNavBar={true}
            />
          </div>
        </div>
        <ViewStudy
          project={project}
          isOpen={isViewStudySlideUpOpen}
          toggleViewStudyFcn={toggleIsViewStudySlideUpOpen}
          viewUrl={viewStudyUrl && viewStudyUrl}
        />
        <VirtualTour
          project={project}
          isOpen={isVirtualTourOpen}
          src={virtualTourUrl && virtualTourUrl}
          toggleVirtualTourFcn={() => toggleIsVirtualTourOpen()}
          doUseHeader={false}
        />
      </Layout>
    </>
  );
}

export default Proximation;
