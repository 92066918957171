import React, { useContext, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { AnimatePresence, motion } from "framer-motion";

import NetData from "../data/NetData";
import ConstantData from "data/ConstantData";

import ButtonGroup from "./ButtonGroup";
import ButtonOutline from "./ButtonOutline";
import ButtonCircle from "./ButtonCircle";
import UnitInfoIconList from "./UnitInfoIconList";
import VirtualTour from "./VirtualTour";
import ViewStudy from "./ViewStudy";
import Carousel from "./Carousel";
import useSavedHomes from "hooks/useSavedHomes";
import useOpenClosePane from "hooks/useOpenClosePane";

import { getImageBySize, getMultipleImageSize } from "util/imgFunctions";
import navArrowLeftDark from "../img/nav_arrow_left_dark.svg";
import { ReactComponent as DownloadIcon } from "../img/icon_download.svg";
import SlideUpGenericHolder from "./SlideUpGenericHolder";
import PageComponents from "./PageComponents";
import { UnitDetailCarousel } from "./UnitDetailCarousel";
import { BookMarkButton } from "./BookMarkButton";
import crossIcon from "../img/cross_icon.svg";

import { sendGoogleAnalyticsEvent } from "util/sendGoogleAnalyticsEvent";
import { portraitMode } from "util/helper";
import SiteMapUnit from "./SiteMapUnit";

import FloorplanPhoto from "./FloorplanPhoto";
import FloorPlanStore from "store/floorPlanStore";
import FooterComponents from "./FooterComponents";
import { useHistory } from "react-router-dom";
import UnitFcnContext from "contexts/UnitFcnContext";

const useStyles = createUseStyles(
  {
    wrapper: (props) => ({
      position: "absolute",
      top: 100,
      left: 0,
      // border:'1px solid red',
      width: props.isPortraitMode ? "-webkit-fill-available" : "100%",
      height: "calc(100vh - 100px)",
      color: props.ThemeData.colours.secondaryFont,
      backgroundColor: props.ThemeData.colours.white,
      fontFamily: props.ThemeData.fonts.title,
      borderRadius: 0,
      overflow: "hidden",
    }),
    buttonGroup: {
      "@media (max-width: 1200px)": {
        margin: "15px 20px 0 20px",
      },
      margin: "10px 20px 0 20px",
      left: "0",
      right: "0",
      top: "-20px",
    },
    header: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: 100,
      backgroundColor: (props) => props.ThemeData.colours.primaryHeaderBg,
    },
    backButton: {
      width: 100,
      height: 100,
      cursor: "pointer",
      color: "#FFF",
      background: "no-repeat center",
      backgroundImage: `url( ${navArrowLeftDark} )`,
    },
    twoColumn: (props) => ({
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      height: "calc(100vh - 200px)",
    }),
    footer: (props) => ({}),
    column: (props) => ({
      flex: "1 0 auto",
      boxSizing: "border-box",
      width: "calc( 50% - 200px )",
    }),
    leftColumn: (props) => ({
      position: "relative",
      backgroundColor: (props) => props.ThemeData.colours.white,
      height: "calc(-200px + 100vh)",
      display: "flex",
      flexDirection: "column",
    }),
    rightColumn: (props) => ({
      padding: "12px 50px 0 50px",
      backgroundColor: props.ThemeData.colours.secondaryBgHighContrast,
      height: "calc(100vh - 100px)",
      overflowY: "auto",
    }),
    floorPlan: () => ({
      height: "90%",
    }),
    floorPlanImg: (props) => ({
      height: "100%",
      marginBottom: 5,
      cursor: "pointer",
    }),
    floorPlanImgExpanded: {
      height: "calc(100vh - 100px)",
      width: "100%",
      objectFit: "contain",
    },
    floorPlanButtons: {
      margin: "10px auto 10px auto",
      maxWidth: 350,
    },
    keyplan: {
      paddingTop: 0,
      display: "flex",
      justifyContent: "center",
      height: 300,
    },
    floorplanModalContainer: {
      display: "flex",
      position: "fixed",
      top: 0,
      left: 0,
      backgroundColor: "rgba(0,0,0,0.8)",
      width: "100%",
      height: "100vh",
      overflow: "hidden",
      zIndex: 99999,
      justifyContent: "center",
      alignItems: "center",
    },
    floorplanModalContent: {
      display: "block",
      justifyContent: "center",
      alignItems: "center",
    },
    floorplanModalHeader: {
      display: "flex",
      width: "100%",
      height: "60px",
      borderBottom: "1px solid #EEEDE6",
      alignItems: "center",
      backgroundColor: (props) => props.ThemeData.colours.white,
      "& > img": {
        alignSelf: "flex-end",
        height: 14,
        width: 14,
        marginRight: 28,
        margin: "auto 0",
        cursor: "pointer",
      },
      "& > div": {
        alignSelf: "center",
        flexGrow: "3",
        textAlign: "center",
        paddingLeft: 42,
        fontSize: 18,
        fontFamily: (props) => props.ThemeData.fonts.title,
        color: (props) => props.ThemeData.colours.primaryBg,
      },
    },
    nArrow: {
      flex: "0 0 auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",

      marginRight: 50,
    },
    nArrowArrow: {
      flex: "0 0 auto",

      width: 20,
      height: 33,
    },
    nArrowN: (props) => ({
      flex: "0 0 auto",

      color: props.ThemeData.colours.primaryBg,
      fontFamily: props.ThemeData.fonts.titleSub,
      fontSize: 23,
    }),
    floorplanName: (props) => ({
      color: props.ThemeData.colours.secondaryTitleFont,

      textAlign: "left",
      fontFamily: props.ThemeData.fonts.title,
      fontSize: 40,
    }),
    hr: {
      // width: '100%',
      "@media (max-width: 1200px)": {
        margin: "15px 20px 0 20px",
      },
      margin: "10px 20px 0 20px",
      borderTop: (props) =>
        `1px ${props.ThemeData.colours.lighterBorderDarkOnLight} solid`,
    },
    actionButtons: {
      width: "100%",
      marginTop: 10,
      marginInline: "auto",
    },
    detailListItems: {
      margin: 0,
      marginTop: 50,
      padding: 0,

      display: "flex",
      justifyContent: "left",

      listStyleType: "none",

      textAlign: "left",
      letterSpacing: ".05em",
    },
    detailList: {},
    detailListItem: {
      width: 150,

      flex: "0 0 auto",
    },
    detailListItemIcon: {},
    detailListItemInline: {
      display: "inline-block",
      marginRight: 10,
    },
    detailListItemTitle: (props) => ({
      color: props.ThemeData.colours.grey,

      fontFamily: props.ThemeData.fonts.titleSub,
      fontSize: 10,
      textTransform: "uppercase",
    }),
    detailListItemData: {
      marginTop: 10,

      letterSpacing: ".05em",
      color: (props) => props.ThemeData.colours.primaryBg,
      fontSize: 16,
    },
    detailListItemDataModifier: {
      // fontFamily: ThemeData.fonts.copy,
    },
    unitDescription: (props) => ({
      margin: "10px 10px 0 10px",

      color: props.ThemeData.colours.grey,

      textAlign: "left",
      lineHeight: "1.9em",
      fontFamily: props.ThemeData.fonts.copy,
      fontSize: 14,
      letterSpacing: ".025em",
    }),
    furtherUnitDetailsButtons: (props) => ({
      margin: "10px auto 10px auto",
      maxWidth: 250,
    }),
    keyplanImg: {
      flex: "0 0 auto",
      minWidth: 160,
      maxWidth: "50%",
      maxHeight: "100%",
      objectFit: "contain",
      marginBottom: 5,
    },
    bookMarkWrap: {
      paddingRight: "100px",
    },
    disclaimer: {
      textAlign: "center",
      fontSize: 6,
      margin: "0 40px",
      letterSpacing: "0.5px",
    },
    modalContainer: {
      position: "fixed",
      backgroundColor: "rgba(0,0,0,0.8)",
      width: "100%",
      height: "100vh",
      top: 0,
      display: "flex",
      color: "black",
      overflow: "hidden",
      zIndex: 5,
      justifyContent: "center",
    },
    unitCarousel: {
      padding: "0 20px",
    },
    planText: {
      fontWeight: "700",
      fontSize: "24px",
      lineHeight: "47px",
      whiteSpace: "nowrap",
      color: "inherrit",
    },
    bedsText: {
      fontWeight: "700",
      fontSize: "10px",
      lineHeight: "15px",
      color: "inherit",
    },
    unitDetails: {
      display: "flex",
      justifyContent: "space-around",
      padding: "0px 30px 0 30px",
      overflowX: "auto",
    },
    button: {
      borderRadius: 5,
    },
    buttonsWrapper: {
      position: "absolute",
      bottom: 0,
      width: "100%",
    },
  },
  {
    name: "UnitDetail",
  }
);

function RenderUnitDetail(
  project,
  unitId,
  unit,
  units,
  classes,
  error,
  isOpen,
  toggleUnitDetailFcn,
  hooks,
  setUnitFromCarousel,
  setGlobalUnit,
  bookAppointmentURL,
  isSavedHomesCompareListOpen,
  isSaveHomesInteractive,
  isCompareHomesInteractive,
  unitDetailRef
) {
  const { ThemeData } = project;
  const [isBack, setIsBack] = useState(false);

  const wrapperAnimVariants = {
    open: {
      y: 0,
      zIndex: isSavedHomesCompareListOpen ? 9999 : 5,
      display: "block",
    },
    closed: {
      y: "100vh",
      display: "block",
      transitionEnd: {
        zIndex: -2,
        display: "none",
      },
    },
  };
  const { disclaimerData } = ConstantData;
  const apiUri = NetData.apiUri;
  const { currentSectionSlug, isUnitDetailOpen, setIsUnitDetailOpen } =
    useContext(UnitFcnContext);
  const history = useHistory();
  const backLocation = currentSectionSlug && `/${currentSectionSlug.slug}`;

  const [isFloorplanModalOpen, setIsFloorplanModalOpen] = useState(false);

  const handleBackButtonClick = () => {
    setIsBack(false);
    setIsUnitDetailOpen(false);
    if (hooks.toggleIsFeaturesSlideUpOpen) {
    } else if (isUnitDetailOpen) {
      setIsUnitDetailOpen(false);
    } else {
      history.push(backLocation);
    }
  };
  useEffect(() => {
    if (isBack) handleBackButtonClick();
  }, [isBack]);

  if (!unit) return null;

  const virtualTourUrl = unit.virtualTour
    ? unit.virtualTour
    : unit.floorPlan.virtualTour
    ? unit.floorPlan.virtualTour
    : project.settings?.threeDUrl
    ? project.settings?.threeDUrl
    : null;
  const viewStudyUrl = unit.viewStudyUrl
    ? unit.viewStudyUrl
    : unit.floorPlan.viewStudyUrl
    ? unit.floorPlan.viewStudyUrl
    : project.settings?.viewStudyUrl
    ? project.settings?.viewStudyUrl
    : null;

  const floorplanPhoto = getImageBySize(unit.floorPlan.photo, "large");
  const keyPlanPhoto = getImageBySize(unit.floorPlan.keyPlan, "small");
  const carouselPhoto = getMultipleImageSize(
    unit.floorPlan?.carouselPhoto,
    "large"
  );
  const floorplanPhotos = getMultipleImageSize(unit.floorPlan?.photo, "large");
  const _renderUnitDetail = () => {
    return (
      <motion.div
        id="unit-details"
        className={classes.wrapper}
        initial={false}
        animate={isOpen ? "open" : "closed"}
        variants={wrapperAnimVariants}
        transition={{
          type: "linear",
          duration: 0.5,
        }}
        ref={
          isSaveHomesInteractive || isCompareHomesInteractive
            ? unitDetailRef
            : null
        }
      >
        <div className={classes.twoColumn}>
          <div className={`${classes.column} ${classes.leftColumn}`}>
            <div className={classes.floorPlan}>
              {unit && unit.floorPlan.photo && (
                <AnimatePresence>
                  <motion.div
                    key="fadein-image"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 1 }}
                    style={{ height: "100%" }}
                  >
                    <div
                      style={{
                        height: `calc(100% - 20px)`,
                      }}
                    >
                      {floorplanPhotos.length > 1 ? (
                        <FloorplanPhoto
                          isOpen={isOpen}
                          ThemeData={project.ThemeData}
                          data={unit}
                        />
                      ) : (
                        <div
                          style={{
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            className={classes.floorPlanImg}
                            src={floorplanPhoto && floorplanPhoto.link}
                            alt={unit.floorPlan.name}
                            width={floorplanPhoto && floorplanPhoto.width}
                            height={floorplanPhoto && floorplanPhoto.height}
                            onClick={() => setIsFloorplanModalOpen(true)}
                          />
                        </div>
                      )}
                    </div>
                    <p className={classes.disclaimer}>
                      {project.settings.disclaimer}
                    </p>
                  </motion.div>
                </AnimatePresence>
              )}
              {(!unit || !unit.floorPlan.photo) && (
                <>
                  <img
                    className={classes.floorPlanImg}
                    src={`${apiUri}/uploads/floorplan_placeholder_large_1680295ea2.png`}
                    alt={"floorplan placeholder large"}
                  />
                  <p className={classes.disclaimer}>
                    {project.settings.disclaimer}
                  </p>
                </>
              )}
            </div>
            <div className={classes.floorPlanButtons}>
              <ButtonGroup
                justifyContent={
                  viewStudyUrl && virtualTourUrl ? "space-between" : "center"
                }
              >
                {viewStudyUrl && (
                  <ButtonOutline
                    ThemeData={ThemeData}
                    className={classes.button}
                    id="enjoy-the-view-unit-details"
                    label="Enjoy the View"
                    padding="5px 10px"
                    textColour={ThemeData.colours.primaryAccent}
                    hoverTextColour={ThemeData.colours.white}
                    borderColour={ThemeData.colours.lighterBorderDarkOnLight}
                    to="."
                    firstInColl
                    onClick={() => hooks.toggleIsViewStudySlideUpOpen()}
                  />
                )}
                {virtualTourUrl && (
                  <ButtonOutline
                    ThemeData={ThemeData}
                    className={classes.button}
                    label="Enter Showhome"
                    padding="5px 10px"
                    textColour={ThemeData.colours.primaryAccent}
                    hoverTextColour={ThemeData.colours.white}
                    borderColour={ThemeData.colours.lighterBorderDarkOnLight}
                    to="."
                    firstInColl
                    onClick={() => hooks.toggleIsVirtualTourOpen()}
                  />
                )}
              </ButtonGroup>
            </div>
          </div>
          <div className={`${classes.column} ${classes.rightColumn}`}>
            {isOpen && (
              <UnitDetailCarousel
                project={project}
                unit={unit}
                units={units}
                setSelectedUnitId={setUnitFromCarousel}
                setGlobalUnit={setGlobalUnit}
              />
            )}
            <div className={classes.actionButtons}>
              <div className={classes.hr} />

              <ButtonGroup
                className={classes.buttonGroup}
                justifyContent="center"
              >
                <div
                  className={
                    unit && unit.floorplan && unit.flooplan.pdf
                      ? classes.bookMarkWrap
                      : ""
                  }
                >
                  <ButtonCircle
                    ThemeData={project.ThemeData}
                    textColour={ThemeData.colours.primaryAccent}
                    borderColor={ThemeData.colours.primaryAccent}
                    widthHeight={40}
                  >
                    <BookMarkButton
                      ThemeData={ThemeData}
                      hooks={hooks}
                      dataUnit={unit}
                      height={20}
                      width={20}
                      isUnitDetail
                    />
                  </ButtonCircle>
                </div>
                {unit && unit.floorplan && unit.flooplan.pdf && (
                  <a
                    href={
                      unit && unit.floorplan.pdf && unit.floorplan.pdf[0].link
                    }
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ButtonCircle
                      ThemeData={ThemeData}
                      alt="Download PDF"
                      textColour={ThemeData.colours.primaryAccent}
                      backgroundColour={
                        ThemeData.colours.secondaryBgHighContrast
                      }
                      hoverTextColour={
                        ThemeData.colours.primaryFontHighestContrast
                      }
                      hoverBackgroundColour={
                        ThemeData.colours.primaryFontHighestConstrast
                      }
                      borderColor={ThemeData.colours.primaryAccent}
                      onClick={() =>
                        sendGoogleAnalyticsEvent(
                          "User Saves Floor Plan as PDF",
                          `User Saves ${unit.floorPlan.name} Unit ${unit.unitNumber}`,
                          `${unit.floorPlan.name} Unit ${unit.unitNumber}`,
                          `${unit.floorPlan.name} Unit ${unit.unitNumber}`
                        )
                      }
                    >
                      <DownloadIcon />
                    </ButtonCircle>
                  </a>
                )}
              </ButtonGroup>
              <div className={classes.hr} />
            </div>

            <UnitInfoIconList
              project={project}
              dataUnit={unit && unit}
              calledByUnitDetail={true}
            />
            {unit && unit.floorPlan.description && (
              <div className={classes.unitDescription}>
                {unit.floorPlan.description}
              </div>
            )}

            {
              <div className={classes.furtherUnitDetailsButtons}>
                <ButtonGroup
                  justifyContent={
                    project?.settings.showFeaturesButton &&
                    carouselPhoto?.length > 0
                      ? "space-between"
                      : "center"
                  }
                >
                  {project?.settings.showFeaturesButton && (
                    <ButtonOutline
                      ThemeData={ThemeData}
                      label="Features"
                      padding={20}
                      textColour={ThemeData.colours.primaryAccent}
                      hoverTextColour={ThemeData.colours.white}
                      borderColour={ThemeData.colours.lighterBorderDarkOnLight}
                      to="."
                      firstInColl
                      onClick={() => hooks.toggleIsFeaturesSlideUpOpen()}
                    />
                  )}
                  {project.settings?.showRenderingButton &&
                    carouselPhoto?.length > 0 && (
                      <ButtonOutline
                        ThemeData={ThemeData}
                        label="Renderings"
                        padding={20}
                        textColour={ThemeData.colours.primaryAccent}
                        hoverTextColour={ThemeData.colours.white}
                        borderColour={
                          ThemeData.colours.lighterBorderDarkOnLight
                        }
                        to="."
                        firstInColl
                        // onClick={() => setIsViewerOpen(true)}
                        onClick={() => hooks.toggleIsCarouselSlideUpOpen()}
                      />
                    )}
                  {/* <ModalGateway>
								{isViewerOpen ? (
									<Modal onClose={() => setIsViewerOpen(false)}>
										<Carousel
											views={
												carouselPhoto?.map( x => ( {
													...x,
													src: x.link,
													caption: x.fileTitle,
												} ) )
											}
										/>
									</Modal>
								): null}
								</ModalGateway> */}
                </ButtonGroup>
              </div>
            }
            {unit.floorPlan.keyPlan ? (
              <div className={classes.keyplan}>
                <img
                  className={classes.keyplanImg}
                  src={keyPlanPhoto && keyPlanPhoto.link}
                  alt={unit.floorPlan.name}
                />
              </div>
            ) : isOpen ? (
              <SiteMapUnit
                project={project}
                selectedUnitId={unit.threeDId}
                floorplan={unit.__typename === "FloorPlan" ? unit : null}
                imageWidth={400}
                detailCard={true}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className={classes.footer}>
          <FooterComponents
            project={project}
            // noRenderSaveHomes={props.noRenderSaveHomes}
            // toggleIsSavedHomesSlideoutOpen={() =>
            //   props.toggleIsSavedHomesSlideoutOpen()
            // }
            // setIsContentPageCarousel={props.setIsContentPageCarousel}
            // isContentPage={true}
            setIsBack={setIsBack}
            isBack={isBack}
            isModalWindow={true}
            // setIsOpenSavedHomes={setIsOpenSavedHomes}
            // isOpenSavedHomes={isOpenSavedHomes}
            // handleBackButtonClick={handleBackButtonClick()}
          />
        </div>
      </motion.div>
    );
  };

  return (
    <>
      {_renderUnitDetail()}

      {isFloorplanModalOpen && unit && unit.floorPlan.photo && (
        <div className={classes.floorplanModalContainer}>
          <div className={classes.floorplanModalContent}>
            <div className={classes.floorplanModalHeader}>
              <div>{unit.floorPlan.name}</div>
              <img
                onClick={() => setIsFloorplanModalOpen(false)}
                src={crossIcon}
                alt="close"
              />
            </div>
            <img
              className={classes.floorPlanImgExpanded}
              src={floorplanPhoto && floorplanPhoto.link}
              alt={unit.floorPlan.name}
              width={floorplanPhoto && floorplanPhoto.width}
              height={floorplanPhoto && floorplanPhoto.height}
            />
            <div>
              <p className={classes.disclaimer}>
                {project.settings.disclaimer}
              </p>
            </div>
          </div>
        </div>
      )}
      <VirtualTour
        project={project}
        isOpen={hooks.isVirtualTourOpen}
        src={virtualTourUrl && virtualTourUrl}
        toggleVirtualTourFcn={() => hooks.toggleIsVirtualTourOpen()}
        doUseHeader={true}
      />

      <SlideUpGenericHolder
        project={project}
        slideUpIsOpen={hooks.isFeaturesSlideUpOpen}
        toggleSlideUpIsOpen={hooks.toggleIsFeaturesSlideUpOpen}
        doUseHeader={true}
      >
        <PageComponents
          project={project}
          currentSection={project.navs.filter((n) => n.slug === "features")[0]}
          currentNav={project.navs.filter((n) => n.slug === "features")[0]}
          overrideUrlContentPageSlug="home-features"
          // overrideUrlContentPageSlug={project.navs.filter((n) => n.slug === "features")[0].slug}
          doRenderHeader={false}
          noRenderSaveHomes={true}
          toggleSlideUpIsOpen={hooks.toggleIsFeaturesSlideUpOpen}
        />
      </SlideUpGenericHolder>
      <ViewStudy
        project={project}
        isOpen={hooks.isViewStudySlideUpOpen}
        toggleViewStudyFcn={hooks.toggleIsViewStudySlideUpOpen}
        viewUrl={viewStudyUrl && viewStudyUrl}
        doUseHeader={true}
      />

      <Carousel
        project={project}
        isOpen={hooks.isCarouselSlideUpOpen}
        toggleCarouselSlideFcn={hooks.toggleIsCarouselSlideUpOpen}
        viewUrl={viewStudyUrl && viewStudyUrl}
        doUseHeader={true}
        carouselPhoto={carouselPhoto}
      />
    </>
  );
}

const UnitDetail = ({
  project,
  unitId,
  floorplanId,
  isOpen,
  toggleUnitDetailFcn,
  setSelectedUnitId,
  isSavedHomesCompareListOpen,
  ...props
}) => {
  const classes = useStyles({
    isOpen,
    ThemeData: project.ThemeData,
    isSiteMapPage: props.isSiteMapPage,
    isPortraitMode: portraitMode(),
  });
  const { savedHomes, toggleSavedHome, savedHomesIncludes } = useSavedHomes();

  const [unitState, setUnit] = useState(unitId);

  const { dataFloorPlansStore, dataUnitsStore } = FloorPlanStore();

  const { isVirtualTourOpen, toggleIsVirtualTourOpen } =
    useOpenClosePane("VirtualTour");
  const { isViewStudySlideUpOpen, toggleIsViewStudySlideUpOpen } =
    useOpenClosePane("ViewStudySlideUp");
  const { isFeaturesSlideUpOpen, toggleIsFeaturesSlideUpOpen } =
    useOpenClosePane("FeaturesSlideUp");

  const { isCarouselSlideUpOpen, toggleIsCarouselSlideUpOpen } =
    useOpenClosePane("CarouselSlideUp");

  let hooks = {
    savedHomes,
    toggleSavedHome,
    savedHomesIncludes,
    isVirtualTourOpen,
    toggleIsVirtualTourOpen,
    isViewStudySlideUpOpen,
    toggleIsViewStudySlideUpOpen,
    isFeaturesSlideUpOpen,
    toggleIsFeaturesSlideUpOpen,
    isCarouselSlideUpOpen,
    toggleIsCarouselSlideUpOpen,
  };

  const bookAppointmentURL =
    "https://calendly.com/oliviabellpm/incrowd-demo?month=2022-06"; // temporary demo link until actual Calendly link is set up

  unitId = unitState === -1 || unitState !== unitId ? unitId : unitState;
  let units = [];

  let unit = null;

  if (dataUnitsStore?.length > 0) {
    if (floorplanId !== -1) {
      let newUnits = [];
      let count = 1;
      dataFloorPlansStore
        ?.filter((f) => f.units && f.units.length > 0)
        .forEach((f) => {
          if (f.building.disabled) {
            if (newUnits.filter((u) => u.floorPlan.name === f.name).length > 0)
              return;
            newUnits.push({
              ...f,
              threeDId: count,
              floorPlan: f,
              unitNumber: null,
              parking: null,
              __typename: "FloorPlan",
            });
            count = count + 1;
          }
        });
      units = newUnits;
      unit = units.filter((unit) => unit.id === floorplanId)[0];
    } else {
      units = [...dataUnitsStore];
      unit = units.filter((unit) => unit.threeDId === unitId)[0];
    }
  } else {
    let newUnits = [];
    let count = 1;
    dataFloorPlansStore.forEach((f) => {
      if (newUnits.filter((u) => u.floorPlan.name === f.name).length > 0)
        return;
      newUnits.push({
        ...f,
        threeDId: count,
        floorPlan: f,
        unitNumber: null,
        parking: null,
        __typename: "FloorPlan",
      });
      count += 1;
    });
    units = newUnits;
    unit = units.filter((unit) => unit.threeDId === unitId)[0];
  }

  const unitDetailRef = props.unitDetailRef;
  const isSaveHomesInteractive = props.isSaveHomesInteractive;
  const isCompareHomesInteractive = props.isCompareHomesInteractive;
  return RenderUnitDetail(
    project,
    unitId,
    unit,
    units,
    classes,
    null,
    isOpen,
    toggleUnitDetailFcn,
    hooks,
    setUnit,
    setSelectedUnitId,
    bookAppointmentURL,
    isSavedHomesCompareListOpen,
    isSaveHomesInteractive,
    isCompareHomesInteractive,
    unitDetailRef
  );
};

export default UnitDetail;
