import {
	useState,
} from "react";


const useOpenClosePane = ( paneName, isInitialValueOpen = false ) => {

	const [isPaneOpen, setIsPaneOpen] = useState( isInitialValueOpen );

	return {
		[`is${paneName}Open`]: isPaneOpen,
		[`setIs${paneName}Open`]: setIsPaneOpen,
		[`setIs${paneName}OpenToOpen`]: () => setIsPaneOpen( true ),
		[`setIs${paneName}OpenToClosed`]: () => setIsPaneOpen( false ),
		[`toggleIs${paneName}Open`]: () => setIsPaneOpen( !isPaneOpen ),
	};

};


export default useOpenClosePane;