import React, {
    useState,
    useEffect,
} from "react";
import {useLocation} from "react-router-dom";
import {
    createUseStyles,
} from "react-jss";

import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

import ButtonOutline from "components/ButtonOutline";
import {sendGoogleAnalyticsEvent} from "util/sendGoogleAnalyticsEvent";


const useStyles = createUseStyles(
    {
        wrapper: {
            padding: '25px 40px 17px 40px',
            backgroundColor: props => props.ThemeData.colours.white,
            textAlign: 'left',
        },
        list: props => ({
            color: props.ThemeData.colours.neuClay,
            backgroundColor: props.ThemeData.colours.white,

        }),
        inputBoxWrapper: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            margin: 0
        },
        inputLabel: {
            position: 'absolute',
            top: 28,
            left: 0
        },
        inputBox: {
            border: '1px solid #EEEDE6',
            height: 40,
            borderRadius: 4,
            margin: 0,
            padding: '12px 0 0 5px',
            fontSize: 14,
            '& > input': {
                paddingLeft: 5,
                paddingTop: 4
            }
        }
    },
    {
        name: "InputRangeSlider",
    }
)


let debounceFilterChangeTimerId;

const FilterRangeInput = ({ThemeData, data, className, adornment, ...props}) => {
    const classes = useStyles({ThemeData});
    const location = useLocation();
    let currLowVal = props.min;
    let currHighVal = props.max;
    if (props.filterSettings && props.filterSettings[props.filterType]) {
        currLowVal = props.filterSettings[props.filterType].low;
        currHighVal = props.filterSettings[props.filterType].high;
    }

    const [values, setValues] = useState([currLowVal, currHighVal]);
    const handleValueChange = (e, newValues) => {
        setValues(newValues);
    };


    const debounceFilterChangeFunc = (funcToDebounce, debounceDelay) => {
        clearTimeout(debounceFilterChangeTimerId);

        debounceFilterChangeTimerId = setTimeout(funcToDebounce, debounceDelay);
    };

    const rangeMarks = [
        {
            value: props.min,
            label: props.displayedDataFormattingFunction(props.min),
        },
        {
            value: props.max,
            label: props.displayedDataFormattingFunction(props.max),
        },
    ];

    const setFilterAfterValueChange = (newValues) => {
        const newFilterSettings = {...props.filterSettings};


        if (newValues[0] === props.min && newValues[1] === props.max) {
            if (newFilterSettings.hasOwnProperty(props.filterType)) {
                delete (newFilterSettings[props.filterType]);
            }
        } else {
            newFilterSettings[props.filterType] = {
                low: newValues[0],
                high: newValues[1],
            };
            sendGoogleAnalyticsEvent(
                `Filter Button Click, ${props.modelType} - ${location.pathname}`,
                `User Clicks Filter - ${props.filterType}, ${newValues[0]}-${newValues[1]}`,
                `Filter - ${props.filterType}, ${newValues[0]}-${newValues[1]}`,
                `${props.filterType}, ${newValues[0]}-${newValues[1]}`
            );
        }
        props.setFilter(newFilterSettings);
    }


    useEffect(() => {
        debounceFilterChangeFunc(() => setFilterAfterValueChange(values), 300);
    }, [values]);

    useEffect(() => {
        if (props.isClear) {
            setValues([props.min, props.max])
        }
    }, [props.filterSettings])

    const onInputChange = (e, lowOrHigh) => {
        let parsedValue = parseInt(e.target.value);
        if (lowOrHigh === "low") {
            if (isNaN(parsedValue)) {
                return setValues([props.min, values[1]])
            }
            setValues([parsedValue, values[1]])
        } else {
            if (isNaN(parsedValue)) {
                return setValues([values[0], props.max])
            }
            setValues([values[0], parsedValue])
        }
    }
    return (
        <div>
            <div className={`${classes.wrapper} ${className}`}>
                <Box>
                    <Slider
                        sx={{
                            color: ThemeData.colours.primaryAccent,
                            '&.MuiSlider-thumb': {
                                color: ThemeData.colours.primaryAccent
                            },
                            '&.MuiSlider-valueLabelOpen': {
                                color: ThemeData.colours.primaryAccent
                            },
                        }}
                        getAriaLabel={() => props.label}
                        value={values}
                        onChange={handleValueChange}
                        getAriaValueText={props.displayedDataFormattingFunction}
                        step={props.step}
                        marks={rangeMarks}
                        min={props.min}
                        max={props.max}
                        valueLabelDisplay='auto'
                        valueLabelFormat={props.displayedDataFormattingFunction}
                    />
                </Box>
            </div>
            {props.isClearButtonVisible &&
                <div className={classes.moreDetailsButtonArea}>
                    <ButtonOutline
                        ThemeData={ThemeData}
                        label="Clear"
                        padding={20}
                        textColour={ThemeData.colours.primaryAccent}
                        hoverTextColour={ThemeData.colours.white}
                        border="none"
                        backgroundColour={ThemeData.colours.clay05}
                        onClick={() => {
                            setValues([props.min, props.max])
                        }}
                    />
                </div>
            }
        </div>
    );
}


export default FilterRangeInput;