import React from 'react';

const pathDefinition = {
    up: 'M3 17.5L18 2.5L33 17.5',
    down: 'M33 2.5L18 17.5L3 2.5',
};

const ChevronVertical = ({disabled, orientation, width = 30, height = 16}) => (
    <svg width={width} height={height} viewBox="0 0 36 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d={pathDefinition[orientation]}
            stroke={disabled ? 'lightgray' : 'black'}
            strokeWidth="5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default ChevronVertical;
