import React, {
  useEffect,
  useState, // useState,
  // useRef,
} from "react";

import { createUseStyles } from "react-jss";

import { motion } from "framer-motion";

import HeaderSimple from "./HeaderSimple";
import HeaderComponents from "./HeaderComponents";

import navArrowLeftDark from "../img/nav_arrow_left_dark.svg";
import { portraitMode } from "util/helper";
import FooterComponents from "./FooterComponents";

const useStyles = createUseStyles(
  {
    wrapper: (props) => ({
      position: "fixed",
      background: "white",
      top: props.doUseHeader
        ? "0px"
        : props.isPortraitMode
        ? "var(--portrait-header-height)"
        : "100px",
      left: 0,
      right: 0,
      width: "100%",
      height: "100vh",
    }),
    header: {
      position: "absolute",
      top: 0,
      left: 0,

      width: "100%",
      height: 100,

      backgroundColor: (props) => props.ThemeData.colours.primaryHeaderBg,
      // backgroundColor: ThemeData.colours.white50,

      zIndex: 10,
    },
    backButton: {
      width: 100,
      height: 100,

      cursor: "pointer",

      color: "#FFF",

      background: "no-repeat center",
      backgroundImage: `url( ${navArrowLeftDark} )`,
    },
    viewStudy: {
      height: "calc(100% - 66px)",
    },
    viewStudyImage: (props) => ({
      position: "relative",
      width: "100%",
      height: props.isPortraitMode
        ? "calc(100vh - var(--portrait-header-height) - var(--footer-height))"
        : "calc(100vh - var(--header-height))",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: "#fff",
    }),
  },
  {
    name: "ViewStudy",
  }
);

const wrapperAnimVariants = {
  open: {
    y: 0,
    zIndex: 10,
    display: "block",
  },
  closed: {
    y: "100vh",
    display: "block",
    transitionEnd: {
      zIndex: -2,
      display: "none",
    },
  },
};

function RenderViewStudy(
  project,
  isPortraitMode,
  classes,
  error,
  isOpen,
  toggleViewStudyFcn,
  viewUrl,
  backLocation,
  props
) {
  const panImage = React.useRef(null);
  const { ThemeData } = project;
  const siteLogo =
    project && project.logo.length > 0 ? project.logo[0].link : null;
  // console.log('[props.doUseHeader]', props.doUseHeader)
  const viewStudyImage = !!viewUrl?.includes("amazonaws");
  const [isBack, setIsBack] = useState(false);
  const handleBackClick = () => {
    setIsBack(false)
    toggleViewStudyFcn();
  };
  useEffect(() => {
    if (isBack) handleBackClick();
  }, [isBack]);
  return (
    <motion.div
      id='view-study'
      className={classes.wrapper}
      initial={false}
      animate={isOpen ? "open" : "closed"}
      variants={wrapperAnimVariants}
      transition={{
        type: "linear",
        duration: 0.5,
      }}>
      {/* { !isPortraitMode &&
        <HeaderSimple
          project={project}
          backgroundColor={ThemeData.colours.primaryHeaderBg}
          onBackClick={() => toggleViewStudyFcn()} />
      } */}
      {props.doUseHeader && isPortraitMode && (
        <HeaderComponents
          project={project}
          siteLogo={siteLogo}
          height={150}
          noRenderSaveHomes={true}
        />
      )}
      <div
        className={viewStudyImage ? classes.viewStudyImage : classes.viewStudy}>
        {viewStudyImage ? (
          <img
            src={viewUrl}
            style={{ maxWidth: "100%", maxHeight: "100%" }}
            alt='View Study'
          />
        ) : (
          <iframe
            title='View Study'
            ref={panImage}
            width='100%'
            height='100%'
            frameBorder='0'
            src={viewUrl}
          />
        )}
      </div>
      <div className={classes.footer}>
        <FooterComponents
          project={project}
          toggleIsSavedHomesSlideoutOpen={() =>
            props.toggleIsSavedHomesSlideoutOpen()
          }
          setIsContentPageCarousel={props.setIsContentPageCarousel}
          // isContentPage={false}
          setIsBack={setIsBack}
          isBack={isBack}
          // setIsOpenSavedHomes={setIsOpenSavedHomes}
          // isOpenSavedHomes={isOpenSavedHomes}
          isNavBar={true}
          isModalWindow={true}
        />{" "}
      </div>
      {props.doUseHeader && isPortraitMode && (
        <HeaderSimple
          project={project}
          backgroundColor={ThemeData.colours.primaryHeaderBg}
          onBackClick={() => toggleViewStudyFcn()}
        />
      )}
    </motion.div>
  );
}

const ViewStudy = ({
  isOpen,
  project,
  toggleViewStudyFcn,
  viewUrl,
  backLocation,
  ...props
}) => {
  const isPortraitMode = portraitMode();
  const classes = useStyles({
    ...props,
    ThemeData: project.ThemeData,
    isPortraitMode,
  });
  return RenderViewStudy(
    project,
    isPortraitMode,
    classes,
    null,
    isOpen,
    toggleViewStudyFcn,
    viewUrl,
    backLocation,
    props
  );
};

export default ViewStudy;
