import React, { useEffect, useState, useMemo, useContext } from "react";
import "./styles/site-map-content-styles.css";

import { createUseStyles } from "react-jss";
import { Fade } from "@mui/material";
import { useParams, useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import "swiper/swiper.scss";
import "swiper/modules/pagination/pagination.scss";
import "swiper/modules/navigation/navigation.scss";
import SwiperCore, {
  Controller,
  Pagination,
  Navigation,
  Mousewheel,
} from "swiper";
import { ReactComponent as FingerTap } from "img/one_finger_tap.svg";
import ConstantData from "../data/ConstantData";
import Map from "components/Map";
import Floorplans from "./Floorplans";
import HeaderComponents from "./HeaderComponents";
import ContentPageNeighbourhoodPoisPortrait from "./ContentPageNeighbourhoodPoisPortrait";
import AccessiblityFooter from "./AccessibilityFooter";
import HeaderContextData from "data/HeaderContextData";
import VirtualTour from "./VirtualTour";
import ExternalLink from "./ExternalLink";
import useOpenClosePane from "hooks/useOpenClosePane";
import ViewStudy from "./ViewStudy";
import contentPageX from "../img/content_page_x.svg";
import contentPageXDark from "../img/content_page_x_dark.svg";
import ButtonOutline from "./ButtonOutline";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import SwiperText from "./SwiperText";
import PhotoGallery from "react-photo-gallery";
import { getImageBySize } from "util/imgFunctions";
import {
  filterAndGroupBySubCategory,
  determineFloorPlanStatus,
  translateUnitStatus,
  sortDataByName,
} from "util/helper";
import FilterPane from "./FilterPane";
import UnitFcnContext from "contexts/UnitFcnContext";
import FilterHeader from "./FilterHeader";
import TwoItemLayout from "@proximahq-dev/components/dist/components/LayoutTwoItems";
import SitemapComponent from "@proximahq-dev/components/dist/components/SitemapComponent/index";
import CardMain from "@proximahq-dev/components/dist/components/CardMain";
import MoreDetails from "@proximahq-dev/components/dist/components/MoreDetailsModal/MoreDetails";
import { getSiteMap, getUnits } from "../graphql/queries";
import useSavedHomes from "../hooks/useSavedHomes";
import FooterComponents from "./FooterComponents";
import { AVAILABLE, FINAL_HOME, FINAL_UNIT } from "../constants";

SwiperCore.use([Mousewheel, Pagination]);

const useStyles = createUseStyles(
  {
    wrapper: (props) => ({
      height: "100%",
      color: props.ThemeData.colours.content,
    }),
    contentPage: () => ({
      display: "flex",
      flexDirection: "column",
      height: "calc( 100vh - var(--header-height) ",
    }),
    footer: () => ({
      display: "block",
      width: "100%",
    }),
    backButtonWrapper: (props) => ({
      width: props.isBigScreen ? "10vw" : "",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: props.isBigScreen
        ? "calc(2 * var(--header-height))"
        : "var(--header-height)",
      borderRight: props.ThemeData.colours.paneBorders,
    }),
    backButton: (props) => ({
      display: "flex",
      height: props.isBigScreen
        ? "calc(2 * var(--header-height))"
        : "var(--header-height)",
      alignItems: "center",
      flex: "0 0 auto",
      cursor: "pointer",
      paddingLeft: 43,
      paddingRight: 43,
      color: props.ThemeData.colours.primaryFont,
      fontSize: props.isBigScreen ? 28 : 14,
      "& > svg": {
        fontSize: props.isBigScreen ? 40 : 20,
        marginRight: 5,
      },
    }),
    contentPane: () => ({
      width: "50%",
      position: "relative",
      marginLeft: 80,
      height:
        "calc(100vh - var(--media-pane-height) - var(--header-height) - var(--portrait-header-height) + 17px)",
      flex: "0 0 auto",
      display: "flex",
      flexDirection: "column",
      textAlign: "left",
    }),
    fullContent: () => ({
      width: "auto",
      marginRight: 80,
    }),
    contentPaneWithMap: (props) => ({
      position: "absolute",
      bottom: "calc(var(--header-height) + var(--accessibility-footer))",
      width: "100%",
      height: "var(--content-pane-height)",
      cursor: "pointer",
      flex: "0 0 auto",
      zIndex: 10,
      display: "flex",
      flexDirection: "column",
      backgroundColor: props.ThemeData.colours.white,
      textAlign: "left",
      "& $contentContainer": {
        padding: "50px 0 30px 0",
        position: "relative",
        bottom: "auto",
      },
      "& $contentArea": {
        height: "auto",
      },
      "& $sectionName, $title": {
        marginLeft: 80,
      },
    }),
    mediaPane: () => ({
      margin: "0 80px 80px 80px",
      height: "calc(var(--media-pane-height) - 80px)",
    }),
    mediaWrapper: () => ({
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: 730,
    }),
    fullMediaPane: () => ({
      "& $mediaWrapper": {},
    }),
    showFullSize: () => ({}),
    contenPaneSplit: () => ({
      flexDirection: "row",
    }),
    contentContainer: () => ({
      position: "absolute",
      bottom: 60,
    }),
    rightPane: (props) => ({
      backgroundColor:
        props.ThemeData.colours.contentPageImageBg ||
        props.ThemeData.colours.white,
      background: "no-repeat center",
      backgroundSize: "cover",
      objectFit: "contain",
      height: "100%",
      width: "100%",
      borderRadius: 20,
    }),
    rightPaneFullsized: () => ({
      display: "flex",
      alignItems: "center",
      "&>img": {
        width: "100%",
        borderRadius: 20,
      },
    }),
    navContainer: () => ({
      height: 150,
      flex: "0 1 auto",
      padding: "0 40px 30px 40px",
    }),
    navArrow: () => ({
      marginTop: 30,
    }),
    swiperSlideImg: () => ({
      width: 10,
    }),
    rightPaneVideo: () => ({
      display: "flex",
      objectFit: "contain",
    }),
    swiper: (props) => ({
      "--swiper-theme-color": props.ThemeData.colours.white,
      "--swiper-navigation-size": "50px",
      borderRadius: 20,
      width: "100%",
      "& .swiper-button-next, & .swiper-button-prev": {
        marginTop: "340px",
        display: "none",
      },
    }),
    contentArea: () => ({
      flex: "0 0 auto",
    }),
    sectionName: (props) => ({
      flex: "0 0 auto",
      color: props.ThemeData.colours.sectionTitle,
      ...props.ThemeData.typography.contentPageSubtitle,
    }),
    title: (props) => ({
      flex: "0 0 auto",
      color: props.ThemeData.colours.contentTitle,
      ...props.ThemeData.typography.contentPageTitle,
    }),
    subTitle: (props) => ({
      fontFamily: props.ThemeData.fonts.title,
      color: props.ThemeData.colours.black,
      textTransform: "capitalized",
      fontSize: 14,
      letterSpacing: 0,
      fontWeight: 700,
      lineHeight: "17.07px",
      minWidth: 150,
      textAlign: "left",
    }),
    content: (props) => ({
      flex: "1 1 auto",
      maxHeight: 300,
      overflowY: "auto",
      ...props.ThemeData.typography.content,
      ...props.ThemeData.styles.thinScrollbar,
    }),
    scrollSection: () => ({
      height: "350px",
      overflowY: "auto",
    }),
    contentPageX: () => ({
      position: "absolute",
      right: 0,
      width: 150,
      height: 150,
      background: "no-repeat center",
      backgroundImage: `url('${contentPageX}')`,
      filter: "drop-shadow(2px 2px 2px rgb(0, 0, 0, .15))",
      "&.isDark": {
        backgroundImage: `url('${contentPageXDark}')`,
      },
    }),
    mapPane: () => ({
      display: "none",
      height:
        "calc(100vh - var(--portrait-header-height) - var(--header-height) - var(--content-pane-height) - var(--accessibility-footer) + 0px)",
      "&.doShow": {
        display: "block",
      },
    }),
    scrollIcon: () => ({
      bottom: "30px !important",
      zIndex: 2,
    }),
    floorplans: () => ({
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
    }),
    categoryName: (props) => ({
      ...props.ThemeData.typography.content,
      fontWeight: 500,
      textTransform: "capitalize",
      borderRadius: 15,
      padding: 10,
      fontSize: 14,
      lineHeight: "22.4px",
      justifyContent: "center",
      color: props.ThemeData.colours.branding,
      border: `1px solid ${props.ThemeData.colours.branding}`,
      "&:hover": {
        backgroundColor: props.ThemeData.colours.branding,
        color: props.ThemeData.colours.brandingText,
      },
      "&.active": {
        backgroundColor: props.ThemeData.colours.branding,
        color: props.ThemeData.colours.brandingText,
      },
    }),
    pageIndicator: (props) => ({
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      margin: "25px 0 20px 0",
      gap: 4,
      "& > span": {
        display: "flex",
        height: 6,
        width: 6,
        borderRadius: 4,
        border: `1px solid ${props.ThemeData.colours.branding}`,
        cursor: "pointer",
      },
    }),
    selectedIndicator: (props) => ({
      backgroundColor: props.ThemeData.colours.branding,
    }),
    pageIndicatorLabel: (props) => ({
      color: props.ThemeData.colours.branding,
      fontSize: 12,
      fontWeight: 700,
      letterSpacing: "1.8px",
    }),
    mediaCaption: (props) => ({
      marginTop: 25,
      ...props.ThemeData.typography.contentPageTitle,
    }),
    colourSchemes: {
      "& $mediaPane": {
        margin: "0 20px 80px 20px",
        height: "var(--media-pane-height)",
      },
      "& $contentContainer": {
        bottom: 20,
      },
      "& $contentPane": {
        height: 580,
        "@media (max-height: 1850px)": {
          height: 540,
        },
      },
      "& $mediaWrapper": {
        height: "620px !important",
        alignItems: "start",
      },
    },
    categoryCaption: (props) => ({
      ...props.ThemeData.typography.content,
      fontSize: 14,
      lineHeight: "normal",
      margin: "30px 0",
    }),
    categoryHeading: (props) => ({
      display: "flex",
      ...props.ThemeData.typography.contentPageTitle,
      fontSize: 20,
      lineHeight: "normal",
      margin: "0 70px 20px 70px",
    }),
    sectionCategories: () => ({
      margin: "0 70px",
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
    }),
    category: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "start",
      margin: "8px 0",
    },
    options: {
      display: "flex",
      flexDirection: "row",
      gap: 24,
      width: "100%",
      alignItems: "start",
      justifyContent: "start",
      "&.col-2 > div": {
        width: "50%",
      },
      "&.col-4 > div": {
        width: "25%",
      },
      "&.col-8 > div": {
        width: "12.5%",
      },
    },
    photOnly: {
      marginTop: 450,
    },
    slideAndCanvasContainer: {
      display: (props) => (props.unitDetailOpen ? "none" : "flex"),
      flexDirection: "row",
    },
    canvasElementsWrapper: {
      display: "flex",
      flexDirection: "column",
      paddingRight: (props) => (props.isSavedHomesSlideoutOpen ? 450 : 0),
      background: (props) => props.ThemeData.colours.white,
      paddingTop: 10,
      alignItems: "center",
    },
    top: {
      flex: "0 0 auto",
      transition: "left 0.5s",
    },
    closeX: {
      display: "flex",
      alignItems: "center",
      height: 50,
      width: (props) => (props.isUnitCardSlideoutOpen ? 35 : 165),
      backgroundColor: (props) => props.ThemeData.colours.white,
      boxShadow: "0px 4px 10px rgb(0 0 0 / 33%)",
      cursor: "pointer",
      margin: (props) => (props.isUnitCardSlideoutOpen ? "0 25px" : "0 50px"),
      zIndex: 1,
      borderRadius: 5,
    },
    openIcon: {
      flex: "0 0 auto",
      display: "flex",
    },
    canvasWrapper: {
      position: "relative",
      width: "100%",
    },
    openText: {
      flex: "0 0 auto",
      paddingRight: 20,
      color: (props) => props.ThemeData.colours.secondaryAccent,
      fontSize: 12,
      textTransform: "uppercase",
      letterSpacing: 4,
    },
  },
  {
    name: "ContentPage",
  }
);

function ContentPagePortrait({ project, currentSection, ...props }) {
  const { ThemeData } = project;
  const {
    isBigScreen,
    setSelectedUnitId,
    interactModalFrom,
    setInteractModalFrom,
    filtredData,
    currentActiveFilters,
    isOpenNavBar,
  } = useContext(UnitFcnContext);
  const classes = useStyles({ ThemeData, isBigScreen });
  const characterLimit = 384;
  let { contentPageSlug } = useParams();
  if (props.overrideUrlContentPageSlug)
    contentPageSlug = props.overrideUrlContentPageSlug;
  const history = useHistory();
  const [isBack, setIsBack] = useState(false);
  const [isOpenSavedHomes, setIsOpenSavedHomes] = useState(false);

  let siteLogo = null,
    contentPage = null;
  if (project && currentSection) {
    siteLogo = project && project.logo.length > 0 ? project.logo[0].link : null;
    contentPage = currentSection.pages.filter(
      (p) => p.slug === contentPageSlug
    )[0];
  }
  let modalDataInit = {
    name: "",
    photo: [],
    bedRooms: 0,
    bathRooms: 0,
    sizeUnit: "",
    isOpen: false,
    description: "",
    interiorSize: 0,
    exteriorSize: "",
    mezzanineSize: 0,
    isType: "",
    floorPlanType: "",
    floor: 0,
  };

  const [currentMain, setCurrentMain] = useState(true);
  const [viewType, setViewType] = useState(true);
  const [activeUnit, setActiveUnit] = useState([]);
  const [siteMap, setSiteMap] = React.useState(null);
  const [units, setUnits] = React.useState({
    units: [],
    projectType: "",
    settings: { isTownHouse: false, colours: "" },
  });
  const [modalData, setModalData] = React.useState(modalDataInit);
  const [filterUnits, setFilterUnits] = useState([]);

  const [dataSiteMap, setDataSiteMap] = React.useState([]);
  const [sortedUnitsByFloor, setSortedUnitsByFloor] = React.useState([]);
  const savedHomesHooks = useSavedHomes();
  const [moreDetailsViewStudyUrl, setMoreDetailsViewStudyUrl] = useState("");
  const [currentFloor, setCurrentFloor] = useState(null);
  const [isIndustrial, setIsIndustrial] = useState(false);

  const handleViewClick = (viewStudyUrl) => {
    setMoreDetailsViewStudyUrl(viewStudyUrl);
    toggleIsViewStudySlideUpOpen();
  };

  useEffect(() => {
    const arrayOfActiveUnits = filterUnits.map((item) => {
      return item.threeDId;
    });
    setActiveUnit(arrayOfActiveUnits);
  }, [filterUnits]);

  useEffect(() => {
    (async () => {
      try {
        const data = await getSiteMap(project.slug);
        setSiteMap(data[0].siteMaps[0]);
        if (data[0].siteMaps.length > 0) {
          let siteMapArr = data[0].siteMaps.map((item) => {
            return item;
          });
          setDataSiteMap(siteMapArr);
        }
        const units = await getUnits(project.slug);

        const groupedUnits = units.units.reduce((acc, unit) => {
          const floorPlanId = unit.floorPlan.id;
          if (!acc[floorPlanId]) {
            acc[floorPlanId] = {
              units: [],
              floorPlanType: unit.floorPlan.floorPlanType,
              status: unit.floorPlan.status,
            };
          }
          acc[floorPlanId].units.push(unit);
          return acc;
        }, {});

        const floorPlanStatuses = Object.keys(groupedUnits).reduce(
          (acc, floorPlanId) => {
            const { units, floorPlanType, status } = groupedUnits[floorPlanId];
            acc[floorPlanId] = determineFloorPlanStatus({
              units,
              floorPlanType,
              status,
            });
            return acc;
          },
          {}
        );

        const modifiedFilteredUnitsData = units.units.map((unit) => ({
          ...unit,
          floorPlanStatus: floorPlanStatuses[unit.floorPlan.id],
          status:
            unit.status === AVAILABLE &&
            (floorPlanStatuses[unit.floorPlan.id] === FINAL_UNIT ||
              floorPlanStatuses[unit.floorPlan.id] === FINAL_HOME)
              ? floorPlanStatuses[unit.floorPlan.id]
              : translateUnitStatus(unit.status),
        }));

        const filteredUnits = {
          ...units,
          units: modifiedFilteredUnitsData,
        };

        const datastr = JSON.parse(units.settings.colours);
        const root = document.documentElement;
        Object.keys(datastr).forEach((key) => {
          root.style.setProperty(`--${key}`, datastr[key]);
        });

        setUnits(filteredUnits);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  useEffect(() => {
    if (dataSiteMap) {
      if (dataSiteMap.length && units.units.length) {
        let sortedUnitsByFloor1 = dataSiteMap.map((item) => {
          return {
            floor: item,
            units: units.units
              .filter((unit) => unit.floor === item.floor)
              .map((item2) => item2),
          };
        });

        setSortedUnitsByFloor(sortedUnitsByFloor1);
      }
    }
  }, [dataSiteMap && units]);

  const filterBySize = (item, sizeFilter) => {
    return (
      item.floorPlan.interiorSize + item.floorPlan.mezzanineSize >
        sizeFilter.low &&
      item.floorPlan.interiorSize + item.floorPlan.mezzanineSize <
        sizeFilter.high
    );
  };

  const filterByUnitCategory = (item, categoryFilter) => {
    const activeCategories = Object.values(categoryFilter).filter(
      (category) => category.doFilterByOr
    );
    if (activeCategories.length === 0) {
      return true;
    } else {
      return activeCategories.some(
        (category) => item.floorPlan.floorPlanType === category.id
      );
    }
  };

  const filterByUnitFloors = (item, floorsFilter) => {
    const activeTypes = Object.values(floorsFilter).filter(
      (floor) => floor.doFilterByOr
    );

    if (activeTypes.length === 0) {
      return true;
    }

    return activeTypes.some((type) => item.floor === type.id);
  };

  const filterFunction = (filterData, units) => {
    if (filterData?.length > 0 && units.units.length > 0) {
      return units.units.filter((item) => {
        return filterData.every((filterObject) => {
          return Object.keys(filterObject).every((key) => {
            switch (key) {
              case "size":
                return filterBySize(item, filterObject[key]);
              case "unitCategoryStates":
                return filterByUnitCategory(item, filterObject[key]);
              case "unitFloors":
                return filterByUnitFloors(item, filterObject[key]);
              default:
                return false;
            }
          });
        });
      });
    }
  };
  useEffect(() => {
    let filterResult = [];
    if (units.projectType === "industrial") {
      setIsIndustrial(true);
      filterResult = filterFunction(filtredData, units);
    } else {
      if (filtredData?.length > 0 && units.units.length > 0) {
        setIsIndustrial(false);
        filterResult = units.units.filter((item1) =>
          filtredData.some((item2) => {
            return item1.floorPlan.id === item2.id;
          })
        );
      }
    }

    if (filterResult) setFilterUnits(filterResult);
  }, [currentActiveFilters]);

  const { toggleIsViewStudySlideUpOpen, isViewStudySlideUpOpen } =
    useOpenClosePane("ViewStudySlideUp");

  const {
    isFloorplansViewStudySlideUpOpen,
    setIsFloorplansViewStudySlideUpOpenToClosed,
    toggleIsFloorplansViewStudySlideUpOpen,
  } = useOpenClosePane("FloorplansViewStudySlideUp");

  const {
    isFloorplansVirtualTourOpen,
    setIsFloorplansVirtualTourOpenToClosed,
    toggleIsFloorplansVirtualTourOpen,
  } = useOpenClosePane("FloorplansVirtualTour");

  const unitFcns = {
    selectedUnitId: props.selectedUnitId,
    setSelectedUnitId: props.setSelectedUnitId,
    selectedFloorplanId: props.selectedFloorplanId,
    isUnitDetailOpen: props.isUnitDetailOpen,
    setIsUnitDetailOpen: props.setIsUnitDetailOpen,
    toggleIsUnitDetailOpen: props.toggleIsUnitDetailOpen,
  };

  const [highlightCatPoi, setHighlightCatPoi] = useState({
    isHighActive: false,
    highCatId: -1,
    highPoiId: -1,
  });

  const [distance, toggleDistance] = useState([]);

  const [procFlyTo, setProcFlyTo] = useState({
    doProcFlyTo: false,
    poiid: -1,
    catid: -1,
    coordinates: -1,
  });

  let doRenderInteractiveMap = false;
  let doOnlyRenderPhotos = false;

  const textMediaPairs = useMemo(() => {
    if (contentPage && contentPage.child.length > 1) {
      return contentPage.child;
    }
    return null;
  }, [contentPage]);

  const doOnlyRenderSingleTextContent = useMemo(() => {
    if (textMediaPairs && textMediaPairs.length > 0) {
      let areAllTMPTextsBlank = true;
      if (textMediaPairs.length > 1) {
        for (let i = 1; i < textMediaPairs.length; i++) {
          if (textMediaPairs[i].content) areAllTMPTextsBlank = false;
        }
      }
      return areAllTMPTextsBlank;
    }
    return false;
  }, [textMediaPairs]);

  const [currentSchemeMedia, setCurrentSchemeMedia] = useState(null);
  const [fadeMedia, setFadeMedia] = useState(false);

  const [activeButton, setActiveButton] = useState("");

  const [mediaSwiper, setMediaSwiper] = useState(null);
  const [swiperSlideIndex, setSwiperSlideIndex] = useState(0);
  const [textSwiper, setTextSwiper] = useState(null);
  const [mapFilteredCategory, setMapFilteredCategory] = useState(null);

  const backLocation = currentSection && `/${currentSection.slug}`;

  doRenderInteractiveMap =
    contentPage &&
    contentPage.customFunction ===
      ConstantData.contentPageCustomFunctions.interactiveMap;
  doOnlyRenderPhotos =
    contentPage &&
    contentPage.customFunction ===
      ConstantData.contentPageCustomFunctions.photosOnly;

  const galleryPhotos = useMemo(() => {
    if (!project) return null;

    const photos = project.photoGallery.filter((p) => p.target === "gallery");

    let galleryPhotos = [{}];
    if (photos && photos.length > 0)
      galleryPhotos = [...photos]
        .sort((phA, phB) => phA.position - phB.position)
        .map((photo) => {
          if (photo.formats && photo.formats.length > 0) {
            const largePhoto = photo.formats.filter(
              (p) => p.size === "large"
            )[0];
            if (largePhoto) photo = largePhoto;
          }
          return {
            src: photo.link,
            height: photo.height,
            width: photo.width,
          };
        });
    return galleryPhotos;
  }, [project]);

  const renderContentPageMediaPane = (contentPageMedia, fullSized) => {
    if (!contentPageMedia || contentPageMedia.length === 0) return;
    if (contentPageMedia[0].fileName.includes(".mp4")) {
      return (
        <div className={`${classes.rightPane} ${classes.rightPaneVideo}`}>
          <video autoPlay muted loop>
            <source src={contentPageMedia.url} type="video/mp4" />
          </video>
        </div>
      );
    } else {
      const photo = getImageBySize(contentPageMedia, "large");

      return doOnlyRenderPhotos || fullSized ? (
        <div className={classes.mediaWrapper}>
          <div className={`${classes.rightPane} ${classes.rightPaneFullsized}`}>
            <img src={photo.link} alt="Media" />
          </div>
        </div>
      ) : (
        <div className={classes.mediaWrapper}>
          <div
            className={`${classes.rightPane} ${
              doOnlyRenderPhotos || fullSized ? classes.rightPaneFullsized : ""
            }`}
            style={
              contentPageMedia && { backgroundImage: `url(${photo.link})` }
            }
          />
        </div>
      );
    }
  };

  const renderSwipePhotos = (contentPairs) => {
    const currentSubpage = contentPairs[swiperSlideIndex] || null;
    return (
      <div className={doOnlyRenderPhotos ? classes.photOnly : ""}>
        <Swiper
          key={contentPairs.length}
          className={classes.swiper}
          modules={[Controller, Pagination, Navigation]}
          onSwiper={setMediaSwiper}
          onSlideChange={(props) => setSwiperSlideIndex(props.activeIndex)}
          controller={{ control: textSwiper }}
          slidesPerView={1}
          buttonWrapperStyle={{ marginTop: "100px" }}
          navigation={{ clickable: true }}
          mousewheel={true}
        >
          {contentPairs.map((tmp, idx) => (
            <SwiperSlide className={classes.swiperSlideImg} key={idx}>
              {renderContentPageMediaPane(tmp.photo, tmp.showFullSize)}
            </SwiperSlide>
          ))}
        </Swiper>
        <div className={classes.pageIndicator}>
          {textMediaPairs.map((tmp, idx) => (
            <span
              key={`indicator-${idx}`}
              className={`${
                swiperSlideIndex === idx ? classes.selectedIndicator : ""
              }`}
              onClick={() => {
                setSwiperSlideIndex(idx);

                if (mediaSwiper) {
                  mediaSwiper.slideTo(idx);
                }
              }}
            ></span>
          ))}
        </div>

        <div className={classes.pageIndicatorLabel}>
          <FingerTap
            width={18}
            height={18}
            stroke={ThemeData.colours.branding}
          />{" "}
          Swipe to see more
        </div>

        {!contentPage?.disableImgLabels && currentSubpage.title && (
          <div className={classes.mediaCaption}>{currentSubpage.title}</div>
        )}
      </div>
    );
  };

  const renderContentMultiChoices = (newTextMediaPairs, customFunction) => {
    const colourSchemes =
      customFunction &&
      customFunction === ConstantData.contentPageCustomFunctions.colourSchemes;
    if (newTextMediaPairs.length === 1) {
      const currentSubpage = textMediaPairs[swiperSlideIndex] || null;
      return (
        <>
          <div
            className={`${classes.contentPane} ${
              currentSubpage && currentSubpage.content.length > characterLimit
                ? classes.fullContent
                : ""
            }`}
          >
            <div className={`${classes.contentContainer}`}>
              <h4 className={`${classes.contentArea} ${classes.sectionName}`}>
                {currentSection && currentSection.name}
              </h4>
              <h2 className={`${classes.contentArea} ${classes.title}`}>
                {contentPage && contentPage.title}
              </h2>
              <div className={`${classes.content}`}>
                <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                  {currentSubpage ? currentSubpage.content : ""}
                </ReactMarkdown>
              </div>
            </div>
          </div>
          <div className={classes.mediaPane}>
            {renderSwipePhotos(textMediaPairs)}
          </div>
        </>
      );
    } else {
      return (
        <div className={`${colourSchemes ? classes.colourSchemes : ""}`}>
          <div
            className={`${classes.contentPane} ${
              currentSchemeMedia &&
              currentSchemeMedia.content.length > characterLimit
                ? classes.fullContent
                : ""
            }`}
          >
            <div className={`${classes.contentContainer}`}>
              <h4 className={`${classes.contentArea} ${classes.sectionName}`}>
                {currentSection && currentSection.name}
              </h4>
              <h2 className={`${classes.contentArea} ${classes.title}`}>
                {contentPage && contentPage.title}
              </h2>
              <div className={`${classes.content}`}>
                <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                  {currentSchemeMedia !== null
                    ? currentSchemeMedia.content
                    : ""}
                </ReactMarkdown>
              </div>
            </div>
          </div>
          <div
            className={`${classes.mediaPane} ${classes.fullMediaPane} ${
              currentSchemeMedia && currentSchemeMedia.showFullSize
                ? classes.showFullSize
                : ""
            }`}
          >
            {currentSchemeMedia && (
              <Fade in={fadeMedia}>
                {renderContentPageMediaPane(
                  currentSchemeMedia.photo,
                  currentSchemeMedia.showFullSize
                )}
              </Fade>
            )}

            {currentSchemeMedia && (
              <div className={classes.categoryCaption}>
                {currentSchemeMedia.subCategory} - {currentSchemeMedia.title}
              </div>
            )}
            {colourSchemes && (
              <div className={classes.categoryHeading}>Colour Schemes</div>
            )}
            <div className={`${classes.sectionCategories}`}>
              {newTextMediaPairs &&
                newTextMediaPairs.map((item) => (
                  <div className={classes.category}>
                    <h3 className={classes.subTitle}>{item.subCategory}</h3>
                    {item.objects.length > 0 && (
                      <div
                        className={`${classes.options} col-${item.objects.length}`}
                      >
                        {item.objects.map((obj, idx) => (
                          <ButtonOutline
                            ThemeData={ThemeData}
                            alignItems={"left"}
                            justifyContent={"left"}
                            className={`${classes.categoryName} ${
                              activeButton === obj.id ||
                              (currentSchemeMedia &&
                                currentSchemeMedia.id === obj.id)
                                ? "active"
                                : ""
                            }`}
                            key={`cateoryname-${idx}`}
                            onClick={() => getSelectedScheme(obj.id, idx)}
                          >
                            {obj.title}
                          </ButtonOutline>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
      );
    }
  };

  const [doShowFilter, setDoShowFilter] = useState(false);

  const { isUnitCardSlideoutOpen } = useOpenClosePane("UnitCardSlideout");

  const [siteMapWidth, setSiteMapWidth] = useState(800);
  const numberOfLevels = dataSiteMap.map(({ floor }) => floor).length;
  const hideLevelPicker = numberOfLevels <= 1;

  useEffect(() => {
    const innerWidth = window.innerWidth;
    const slideoutContainer = document.getElementById("unit-cards-slideout");

    if (slideoutContainer) {
      setSiteMapWidth(
        isUnitCardSlideoutOpen ? innerWidth - 600 : innerWidth - 265
      );
    }
    if (contentPage?.customFunction === "siteMaps") {
      props.setIsSiteMapPage(true);
    }
  }, [setSiteMapWidth, isUnitCardSlideoutOpen, contentPage]);

  const customFunctionContent = useMemo(() => {
    let viewStudyUrl = project.settings?.viewStudyUrl
      ? project.settings.viewStudyUrl
      : null;
    let virtualTourUrl = project.settings?.threeDUrl
      ? project.settings.threeDUrl
      : null;
    let alternativeUrl = null;
    let newTextMediaPairs = [];
    const mappedAreasByFloor = dataSiteMap.reduce((acc, curr) => {
      return { ...acc, [curr.floor]: curr.areas };
    }, {});
    const mappedFloorImagesByFloor = dataSiteMap.reduce((acc, curr) => {
      return { ...acc, [curr.floor]: curr.link };
    }, {});

    if (
      contentPage &&
      contentPage.customFunction ===
        ConstantData.contentPageCustomFunctions.enjoyTheView &&
      contentPage.customUrl
    ) {
      viewStudyUrl = contentPage.customUrl;
    } else if (
      contentPage &&
      contentPage.customFunction ===
        ConstantData.contentPageCustomFunctions.virtualTour &&
      contentPage.customUrl
    ) {
      virtualTourUrl = contentPage.customUrl;
    } else if (
      contentPage &&
      contentPage.customFunction ===
        ConstantData.contentPageCustomFunctions.externalLink &&
      contentPage.customUrl
    ) {
      virtualTourUrl = contentPage.customUrl;
    } else if (
      contentPage &&
      contentPage.customFunction ===
        ConstantData.contentPageCustomFunctions.orange3dModel &&
      contentPage.customUrl
    ) {
      alternativeUrl = contentPage.customUrl;
    } else if (
      contentPage &&
      contentPage.customFunction ===
        ConstantData.contentPageCustomFunctions.colourSchemes
    ) {
      newTextMediaPairs = filterAndGroupBySubCategory(textMediaPairs);
    }

    const sortedUnits = {
      ...units,
      units: sortDataByName(units.units),
    };

    return {
      [ConstantData.contentPageCustomFunctions.colourSchemes]:
        renderContentMultiChoices(
          newTextMediaPairs,
          contentPage && contentPage.customFunction
        ),
      [ConstantData.contentPageCustomFunctions.floorplans]: (
        <div className={classes.floorplans}>
          <Floorplans
            {...unitFcns}
            project={project}
            isViewStudySlideUpOpen={isFloorplansViewStudySlideUpOpen}
            toggleIsViewStudySlideUpOpen={
              toggleIsFloorplansViewStudySlideUpOpen
            }
            isVirtualTourOpen={isFloorplansVirtualTourOpen}
            toggleIsVirtualTourOpen={toggleIsFloorplansVirtualTourOpen}
            setSelectedUnitId={props.setSelectedUnitId}
            setSelectedFloorplanId={props.setSelectedFloorplanId}
            viewUrl={
              project.settings?.viewStudyUrl && project.settings.viewStudyUrl
            }
          />
        </div>
      ),
      [ConstantData.contentPageCustomFunctions.virtualTour]: (
        <VirtualTour
          project={project}
          isOpen={true}
          src={virtualTourUrl && virtualTourUrl}
          toggleVirtualTourFcn={() => history.goBack()}
        />
      ),
      [ConstantData.contentPageCustomFunctions.externalLink]: (
        <ExternalLink
          project={project}
          isOpen={true}
          src={virtualTourUrl && virtualTourUrl}
          toggleVirtualTourFcn={() => history.goBack()}
        />
      ),
      [ConstantData.contentPageCustomFunctions.enjoyTheView]: (
        <ViewStudy
          project={project}
          isOpen={true}
          toggleViewStudyFcn={toggleIsViewStudySlideUpOpen}
          viewUrl={viewStudyUrl && viewStudyUrl}
          backLocation={props.sectionSlug}
        />
      ),
      [ConstantData.contentPageCustomFunctions.externalLink]: (
        <ExternalLink
          project={project}
          isOpen={true}
          src={virtualTourUrl && virtualTourUrl}
          toggleVirtualTourFcn={() => history.goBack()}
        />
      ),
      [ConstantData.contentPageCustomFunctions.orange3dModel]: (
        <ViewStudy
          project={project}
          isOpen={true}
          toggleViewStudyFcn={toggleIsViewStudySlideUpOpen}
          viewUrl={alternativeUrl && alternativeUrl}
          backLocation={props.sectionSlug}
        />
      ),
      [ConstantData.contentPageCustomFunctions.galleryContentPage]: (
        <div className={classes.gallery}>
          <PhotoGallery
            photos={galleryPhotos}
            direction="row"
            columns={3}
            margin={15}
          />
        </div>
      ),
      [ConstantData.contentPageCustomFunctions.siteMaps]: (
        <>
          <div
            className="filterPaneContainer"
            style={{
              visibility: doShowFilter ? "visible" : "hidden",
              opacity: doShowFilter ? 1 : 0,
            }}
          >
            <FilterPane
              project={project}
              doShowFilter={doShowFilter}
              setDoShowFilter={setDoShowFilter}
              interactModalFrom={interactModalFrom}
              setInteractModalFrom={setInteractModalFrom}
              setSelectedUnitId={setSelectedUnitId}
              isSiteMapPage={props.isSiteMapPage}
            />
          </div>
          {siteMap && sortedUnitsByFloor.length && (
            <div className="site-map-wrapper">
              <div
                className={`siteMap ${
                  doShowFilter || modalData.isOpen ? "siteMap-close" : ""
                }`}
              >
                <TwoItemLayout
                  Main={SitemapComponent}
                  Secondary={CardMain}
                  setCurrentMain={setCurrentMain}
                  currentMain={currentMain}
                  setViewType={setViewType}
                  viewType={viewType}
                  activeUnit={activeUnit}
                  mainProps={{
                    imageSrc: siteMap.link,
                    name: "Something",
                    areas: siteMap.areas,
                    width: siteMapWidth,
                    height: 100,
                    currentMain: currentMain,
                    setActiveUnit: setActiveUnit,
                    isSecondaryActive: { activeUnit },
                    sortedUnitsByFloor: sortedUnitsByFloor,
                    currentFloor,
                    setCurrentFloor,
                    brandingColor: ThemeData.colours.branding,
                    hidePicker: hideLevelPicker,
                  }}
                  secondaryProps={{
                    units: sortedUnits,
                    activeUnit: activeUnit,
                    currentMain: currentMain,
                    viewType: viewType,
                    setModalData: setModalData,
                    savedHomesHooks,
                  }}
                  isSecondaryActive={activeUnit.length > 0}
                />
              </div>
              {!modalData.isOpen &&
                (contentPageSlug === "interactive-floor-plate" ||
                  contentPageSlug === "interactive-floor-plates") && (
                  <FilterHeader
                    project={project}
                    activeUnit={activeUnit}
                    isIndustrial={isIndustrial}
                    hideFilter={true}
                    doShowFilter={doShowFilter}
                    setDoShowFilter={setDoShowFilter}
                    isSiteMapPage={props.isSiteMapPage}
                  />
                )}

              <MoreDetails
                data={modalData}
                onClose={() => setModalData(modalDataInit)}
                imageSrc={siteMap.link}
                name="name"
                mappedAreasByFloor={mappedAreasByFloor}
                mappedFloorImagesByFloor={mappedFloorImagesByFloor}
                width={300}
                savedHomesHooks={savedHomesHooks}
                onViewClick={(viewStudyUrl) => handleViewClick(viewStudyUrl)}
                currentFloor={currentFloor}
                isTownHouse={project?.settings?.isTownHouse}
                disabled
                disclaimer={project.settings.disclaimer}
              />
              <ViewStudy
                project={project}
                isOpen={isViewStudySlideUpOpen}
                toggleViewStudyFcn={toggleIsViewStudySlideUpOpen}
                viewUrl={moreDetailsViewStudyUrl}
                backLocation={props.sectionSlug}
                doUseHeader={true}
              />
            </div>
          )}
        </>
      ),
    };
  }, [ConstantData, currentSection, contentPage, project, unitFcns]);

  const getSelectedScheme = (id) => {
    const scheme = textMediaPairs.find((image) => image.id === id);
    setActiveButton(id);
    setFadeMedia(false);
    setTimeout(() => {
      setCurrentSchemeMedia(scheme);
      setFadeMedia(true);
    }, 100);
  };

  useEffect(() => {
    setFadeMedia(true);
    textMediaPairs &&
      textMediaPairs.length > 0 &&
      setCurrentSchemeMedia(textMediaPairs[0]);
  }, [textMediaPairs]);
  useEffect(() => {
    if (isOpenSavedHomes) props.toggleIsSavedHomesSlideoutOpen();
  }, [isOpenSavedHomes]);
  const handleBackButtonClick = () => {
    if (props.toggleSlideUpIsOpen) {
      props.toggleSlideUpIsOpen();
      props.setIsContentPageCarouselOpen(false);
    } else if (props.isUnitDetailOpen) {
      props.setIsUnitDetailOpen(false);
      props.setIsContentPageCarouselOpen(false);
    } else if (isFloorplansViewStudySlideUpOpen) {
      setIsFloorplansViewStudySlideUpOpenToClosed();
      props.setIsContentPageCarouselOpen(false);
    } else if (isFloorplansVirtualTourOpen) {
      setIsFloorplansVirtualTourOpenToClosed();
      props.setIsContentPageCarouselOpen(false);
    } else {
      history.push(backLocation);
    }
  };
  useEffect(() => {
    if (isBack) handleBackButtonClick();
  }, [isBack]);

  if (!project || !contentPage) return <div></div>;

  return (
    <>
      <motion.div
        className={classes.wrapper}
        id={contentPage.id}
        initial={{ y: "100vh" }}
        animate={{ y: 0 }}
        exit={{ y: "100vh" }}
        transition={{ type: "tween", duration: 0.5 }}
      >
        {!isOpenNavBar && (
          <HeaderComponents
            headerContext={HeaderContextData.contentPageHeaderContext}
            setIsUnitDetailOpen={props.setIsUnitDetailOpen}
            toggleIsSavedHomesSlideoutOpen={
              props.toggleIsSavedHomesSlideoutOpen
            }
            toggleIsRegisterOpen={props.toggleIsRegisterOpen}
            project={project}
            siteLogo={siteLogo}
            noRenderSaveHomes={true}
            height={150}
          >
            {/* {renderBackButton()} */}
          </HeaderComponents>
        )}

        <div className={classes.contentPage}>
          {contentPage.customFunction &&
          !(doRenderInteractiveMap || doOnlyRenderPhotos) ? (
            customFunctionContent[contentPage.customFunction]
          ) : (
            <>
              {!doOnlyRenderPhotos && (
                <div
                  className={`${
                    contentPage.content.length > characterLimit
                      ? classes.fullContent
                      : ""
                  } ${
                    doRenderInteractiveMap
                      ? classes.contentPaneWithMap
                      : classes.contentPane
                  } `}
                >
                  <div className={classes.contentContainer}>
                    <h4
                      className={`${classes.contentArea} ${classes.sectionName}`}
                    >
                      {currentSection.name}
                    </h4>
                    <h2 className={`${classes.contentArea} ${classes.title}`}>
                      {contentPage.title}
                    </h2>
                    {doRenderInteractiveMap ? (
                      <ContentPageNeighbourhoodPoisPortrait
                        distance={distance}
                        toggleDistance={toggleDistance}
                        highlightCatPoi={highlightCatPoi}
                        procFlyTo={procFlyTo}
                        setHighlightCatPoi={setHighlightCatPoi}
                        setProcFlyTo={setProcFlyTo}
                        mapFilteredCategory={mapFilteredCategory}
                        setMapFilteredCategory={setMapFilteredCategory}
                        project={project}
                      />
                    ) : (
                      <div className={`${classes.content}`}>
                        {!doOnlyRenderSingleTextContent &&
                        textMediaPairs &&
                        textMediaPairs.length > 0 ? (
                          <SwiperText
                            classes={classes}
                            textMediaPairs={textMediaPairs}
                            mediaSwiper={mediaSwiper}
                            setTextSwiper={setTextSwiper}
                          />
                        ) : (
                          <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                            {contentPage.content}
                          </ReactMarkdown>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div
                className={`${classes.rightPane} ${classes.mapPane} ${
                  doRenderInteractiveMap ? "doShow" : ""
                }`}
              >
                {doRenderInteractiveMap && (
                  <Map
                    project={project}
                    doShow={doRenderInteractiveMap}
                    highlightCatPoi={highlightCatPoi}
                    setHighlightCatPoi={setHighlightCatPoi}
                    procFlyTo={procFlyTo}
                    isPortraitMode={true}
                    mapFilteredCategory={mapFilteredCategory}
                  />
                )}
              </div>
              {!doRenderInteractiveMap && (
                <>
                  {doOnlyRenderSingleTextContent &&
                  textMediaPairs &&
                  textMediaPairs.length > 0 &&
                  currentSchemeMedia !== null ? (
                    <div className={classes.mediaPane}>
                      {renderSwipePhotos(textMediaPairs)}
                    </div>
                  ) : (
                    <div className={classes.mediaPane}>
                      {contentPage.child &&
                      contentPage.child.length === 1 &&
                      contentPage.child[0].photo
                        ? renderContentPageMediaPane(
                            contentPage.child[0].photo,
                            contentPage.child[0].showFullSize ? true : false
                          )
                        : renderContentPageMediaPane(
                            contentPage.photo && contentPage.photo
                          )}
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
        <div className={classes.footer}>
          <FooterComponents
            project={project}
            noRenderSaveHomes={props.noRenderSaveHomes}
            toggleIsSavedHomesSlideoutOpen={() =>
              props.toggleIsSavedHomesSlideoutOpen()
            }
            setIsContentPageCarousel={props.setIsContentPageCarousel}
            isContentPage={true}
            setIsBack={setIsBack}
            isBack={isBack}
            setIsOpenSavedHomes={setIsOpenSavedHomes}
            isOpenSavedHomes={isOpenSavedHomes}
          />
        </div>
      </motion.div>
      <AccessiblityFooter project={project} />
    </>
  );
}

ContentPagePortrait.defaultProps = {
  doRenderHeader: true,
};

export default ContentPagePortrait;
