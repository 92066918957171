import {
    DISABLED, IN_PROGRESS, FINAL_HOME, FINAL_UNIT, AVAILABLE, HOLD, SOLD, UNAVAILABLE,
} from "../constants";

export const convertBathrooms = (baths = 0) => {
    baths = parseFloat(baths);
    const full = parseInt(baths);
    return (`${full} ${full > 1 ? `Baths` : `Bath`}` + (baths > full ? ", 1 Powder" : ""));
};

export const convertBedrooms = (beds = 0, jrBed = false, studio = false, flex = false) => {
    // beds = parseFloat(beds)
    // const full = parseInt(beds)
    const [full, decimal] = String(beds).split(".");

    let result = (jrBed ? " Jr. " : "") + `${full} ${full > 1 ? `Beds` : `Bed`}` + (decimal === "5" ? " + 1 Den" : "") + (flex ? " + Flex" : "");
    if (studio) {
        result = "Studio";
    }
    return result;
};

export const showExterior = (unit) => {
    if (unit.exteriorSize === 0) return 0;
    if (unit.floorPlan.exteriorSize === "0") return 0;
    return unit.exteriorSize ? unit.exteriorSize : unit.floorPlan.exteriorSize;
};

export const showInterior = (unit) => {
    if (unit.interiorSize === 0) return 0;
    if (unit.floorPlan.interiorSize === "0") return 0;
    return unit.interiorSize ? unit.interiorSize : unit.floorPlan.interiorSize;
};

export const showTotalSpaceOld = (unit) => {
    let exterior = 0;
    const interior = unit.interiorSize || unit.floorPlan.interiorSize || 0;
    const mezzanine = unit.floorPlan.mezzanineSize || 0;
    let totalSpace = [];

    if (!unit.exteriorSize) {
        totalSpace.push(interior + exterior + mezzanine);
    } else if (unit.floorPlan.exteriorSize !== "") {
        const spaces = unit.floorPlan.exteriorSize.split(/\s*-\s*/) || [];
        spaces.forEach((ex) => {
            if (!isNaN(ex)) {
                totalSpace.push(interior + parseFloat(ex) + mezzanine);
            }
        });
    }
    return totalSpace.join("-");
};

export const showTotalSpace = (unit, isFloorPlan) => {
    // determines where to pull the data from. Floor plans and units have different data sets
    if (isFloorPlan) {
        const {
            floorPlan: {exteriorSize, interiorSize, mezzanineSize},
        } = unit;
        const exteriorSizeRange = exteriorSize
            .split(/\s*-\s*/)
            .map((stringSize) => parseFloat(stringSize) ? parseFloat(stringSize) : 0);

        return exteriorSizeRange
            .map((size) => interiorSize + size + mezzanineSize)
            .join("-");
    }
    const {interiorSize, mezzanineSize, exteriorSize} = unit;
    return interiorSize + mezzanineSize + exteriorSize;
};

export const getOrdinal = (n) => {
    if (n === 0) return "Ground";
    if (n < 0) return `Lower Level ${Math.abs(n)}`;

    let ord = "th";
    if (n % 10 === 1 && n % 100 !== 11) ord = "st"; else if (n % 10 === 2 && n % 100 !== 12) ord = "nd"; else if (n % 10 === 3 && n % 100 !== 13) ord = "rd";

    return `${n}${ord}`;
};

const proximaViewModeName = "proximaViewMode";
export const getLocalItem = (name) => {
    if (typeof localStorage !== "undefined") {
        return localStorage.getItem(name);
    } else if (typeof sessionStorage !== "undefined") {
        return sessionStorage.getItem(name);
    } else {
        return;
    }
};

export function filterAndGroupBySubCategory(inputArray) {
    const resultArray = [];

    inputArray.forEach((item) => {
        const existingSubCategory = resultArray.find((entry) => entry.subCategory === item.subCategory);

        if (existingSubCategory) {
            existingSubCategory.objects.push(item);
        } else {
            resultArray.push({subCategory: item.subCategory, objects: [item]});
        }
    });

    return resultArray;
}

export const switchViewMode = (mode = "landscape") => {
    if (typeof localStorage !== "undefined") {
        localStorage.setItem(proximaViewModeName, mode);
    } else if (typeof sessionStorage !== "undefined") {
        sessionStorage.setItem(proximaViewModeName, mode);
    }
};

export const shortenString = (str, maxLength = 300) => {
    if (str.length <= maxLength) return str;

    let trimmedStr = str.substr(0, str.lastIndexOf(" ", maxLength));
    return trimmedStr;
};

export function convertSlug(slug) {
    let title = "";
    if (slug === "thedaly") {
        title = "The Daly";
    } else if (slug === "liveenzo") {
        title = "Live Enzo";
    } else if (slug === "neuonthird") {
        title = "Neu";
    } else if (slug === "vonefortynine") {
        title = "Vone";
    }

    return title.length > 0 ? title : slug;
}

export function filterConvertBedrooms(beds, studio = false) {
    if (studio) {
        return "Studio";
    } else {
        const [full, decimal] = String(beds).split(".");
        let result = full + `${full > 1 ? ` Beds` : ` Bed`}` + `${decimal === "5" ? " + Den" : decimal === "1" ? " + Flex" : ""}`;

        return result;
    }
}

export function capitalizeOptions(str = "") {
    const options = str.split(",").map((opt) => capitalize(opt));
    return options.join(", ");
}

export const portraitMode = () => getLocalItem(proximaViewModeName) === "portrait";
export const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export const sortDataByName = (data) => {
    const compareUnits = (unitA, unitB) => {
        const nameA = unitA.name.trim();
        const nameB = unitB.name.trim();

        // Regular expression to match numeric and alphabetic parts
        const regex = /(\d+)|([a-zA-Z]+)/g;

        // Extract parts for unit A
        const partsA = [];
        let match;
        while ((match = regex.exec(nameA)) !== null) {
            partsA.push(match[0]);
        }

        // Extract parts for unit B
        const partsB = [];
        while ((match = regex.exec(nameB)) !== null) {
            partsB.push(match[0]);
        }

        // Compare parts of the names
        for (let i = 0; i < Math.max(partsA.length, partsB.length); i++) {
            const partA = partsA[i];
            const partB = partsB[i];

            if (partA === undefined) {
                return -1;
            } else if (partB === undefined) {
                return 1;
            }

            const isNumA = !isNaN(partA);
            const isNumB = !isNaN(partB);

            if (isNumA && isNumB) {
                const numA = parseInt(partA, 10);
                const numB = parseInt(partB, 10);
                if (numA !== numB) {
                    return numA - numB;
                }
            } else if (!isNumA && !isNumB) {
                const comparison = partA.localeCompare(partB);
                if (comparison !== 0) {
                    return comparison;
                }
            } else {
                // Prioritize alphabetic parts over numeric parts
                return isNumA ? 1 : -1;
            }
        }

        return 0;
    };

    // Sort units based on the custom comparison function
    return data.slice().sort(compareUnits);
};

export const getStatusClass = (status) => {
    switch (status) {
        case SOLD:
            return "soldTag";
        case UNAVAILABLE:
        case DISABLED:
        case HOLD:
            return "unavailableTag";
        case IN_PROGRESS:
            return "inProgressTag";
        case FINAL_UNIT:
            return "finalUnitTag";
        case FINAL_HOME:
            return "finalHomeTag";
        default:
            return "";
    }
};

export const isAvailableStatus = (status) => {
    return (status === AVAILABLE || status === FINAL_UNIT || status === FINAL_HOME || status === "");
};

const commercialTypes = new Set(["creative manufacturing", "commercial", "healthcare", "industrial", "office", "retail", "warehouse", "creative industrial",]);

const residentialTypes = new Set(["residential", "studio"]);

export const determineFloorPlanStatus = (floorPlan) => {
    const units = floorPlan && floorPlan.units ? floorPlan.units : [];

    // Handle the case where the floor plan itself is a unit
    if (units.length === 0) {
        if (floorPlan?.status) {
            return floorPlan.status;
        }
        return "";
    }

    // Flatten the units array to include all nested units if necessary
    const allUnits = units.flatMap((unit) => (unit.units ? unit.units : unit));

    const statusCounts = allUnits.reduce((counts, unit) => {
        if (!unit.status) return counts;
        counts[unit.status] = (counts[unit.status] || 0) + 1;
        return counts;
    }, {});

    const totalUnits = allUnits.length;
    const availableUnits = statusCounts.available || 0;
    const inProgressUnits = statusCounts.inProgress || 0;
    const soldUnits = statusCounts.sold || 0;
    const unavailableUnits = statusCounts.unavailable || 0;
    const holdUnits = statusCounts.hold || 0;
    const disabledUnits = statusCounts.disabled || 0;

    // Determine final status based on floorPlanType

    const types = floorPlan.floorPlanType ? floorPlan.floorPlanType.split(",") : [];

    const finalStatus = types.some((type) => commercialTypes.has(type)) ? "finalUnit" : types.length === 0 || types.some((type) => residentialTypes.has(type)) ? "finalHome" : "finalHome";
    // If there are multiple available or in-progress units
    if (availableUnits + inProgressUnits > 1) {
        return "";
    }

    // If there is only one available or in-progress unit
    if (availableUnits + inProgressUnits === 1) {
        return finalStatus;
    }

    if (soldUnits === totalUnits) {
        return SOLD;
    }

    if (soldUnits + unavailableUnits + holdUnits + disabledUnits === totalUnits) {
        return UNAVAILABLE;
    }

    return "";
};

export const getDisplayLabel = (floor) => {
    if (floor === 0) return "G";
    if (floor < 0) return `LL${Math.abs(floor)}`;
    return floor.toString();
};

export const translateUnitStatus = (status) => {
    switch (status) {
        case SOLD:
            return SOLD;
        case UNAVAILABLE:
        case HOLD:
        case DISABLED:
            return UNAVAILABLE;
        case IN_PROGRESS:
            return IN_PROGRESS;
        case FINAL_UNIT:
            return FINAL_UNIT;
        case FINAL_HOME:
            return FINAL_HOME;
        default:
            return "";
    }
};

export const isTouchScreen = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || window.matchMedia('(pointer: coarse)').matches;

export function sortItemsByPrefixAndNumber(items) {
    /**
     * Extracts the prefix and the first number from an item.
     *
     * @param {string} item - The item string to parse.
     * @returns {Object} An object containing the prefix and the number.
     */
    function parseItem(item) {
        const firstPart = item.split('/')[0];
        const match = firstPart.match(/^([A-Za-z]+)(\d+)$/);

        if (match) {
            return {
                prefix: match[1].toUpperCase(),
                number: parseInt(match[2], 10)
            };
        } else {
            return {
                prefix: null,
                number: null
            };
        }
    }

    /**
     * Comparator function to sort items.
     */
    function compareItems(a, b) {
        const parsedA = parseItem(a.name);
        const parsedB = parseItem(b.name);

        if (parsedA.prefix && parsedA.number !== null && parsedB.prefix && parsedB.number !== null) {
            if (parsedA.prefix < parsedB.prefix) return -1;
            if (parsedA.prefix > parsedB.prefix) return 1;
            return parsedA.number - parsedB.number;
        } else if (parsedA.prefix && parsedA.number !== null) {
            return -1;
        } else if (parsedB.prefix && parsedB.number !== null) {
            return 1;
        } else {
            return 0;
        }
    }

    // Return a new sorted array
    return items.slice().sort(compareItems);
}