import { createUseStyles } from "react-jss";
import { useEffect, useState, useContext, useMemo } from "react";
import ImageMapper from "react-img-mapper";
import UnitFcnContext from "contexts/UnitFcnContext";
import LevelPicker from "./LevelPicker/LevelPicker";

const useStyles = createUseStyles(
  {
    siteMapWrapper: () => ({
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: 35,
    }),
    wrapper: () => ({
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "auto",
      "&.show": {
        opacity: "100%",
      },
    }),
    sitemap: (props) => ({
      display: "flex",
      width: `${props.imageWidth || 450}px`,
    }),
    navbar: (props) => ({
      display: "flex",
      flexDirection: "column",
      gap: 5,

      "& button": {
        border: `1px solid ${props.ThemeData.colours.branding}`,
        background: props.ThemeData.colours.white,
        fontSize: 12,
        padding: 5,
        borderRadius: 5,
        color: props.ThemeData.colours.branding,
        cursor: "pointer",

        "&:hover, &:active, &.active": {
          backgroundColor: props.ThemeData.colours.branding,
          color: props.ThemeData.colours.white,
        },
      },
    }),
  },
  { name: "SiteMapUnit" }
);

const SiteMap = ({ project, ...props }) => {
  const { ThemeData } = project;
  const classes = useStyles({ ThemeData, ...props });
  const floorPlanUnits = useMemo(() => {
    return project.units.filter(
      (unit) => unit.floorPlan.name === props?.floorplan?.name
    );
  }, []);

  const levels = [...new Set(floorPlanUnits.map((unit) => unit?.floor))].sort((a, b) => a - b);
  const highlightColor =
    ThemeData.colours.modelHighlight || "rgba(66, 230, 245, .60)";
  const [highlightedAreas, setHighlightedAreas] = useState([]);
  const [imageSource, setImageSource] = useState("");
  const [currentFloor, setCurrentFloor] = useState(
    floorPlanUnits[0]?.floor ?? 1
  );

  const { selectedUnitId } = useContext(UnitFcnContext);

  const siteMaps = useMemo(() => {
    return project.buildings?.[0]?.siteMaps
      ?.filter((map) => map.areas.length > 0)
      ?.map((siteMap) => ({
        ...siteMap,
        areas: siteMap.areas.map((area) => ({
          ...area,
          id: parseInt(area.id),
          fillColor: highlightColor,
        })),
      }));
  }, []);

  useEffect(() => {
    if (!siteMaps || siteMaps.length === 0) {
      return;
    }
    let areas = [];
    // If it's a floorPlan
    if (props.floorplan) {
      const floorSitemap = siteMaps.filter(
        (sm) => sm.floor === currentFloor
      )[0];
      const threeDIds = floorPlanUnits.map((unit) => unit.threeDId);
      if (floorSitemap) {
        areas = floorSitemap.areas
          .filter((area) => threeDIds.indexOf(area.id) !== -1)
          .map((area) => ({
            ...area,
            preFillColor: highlightColor,
            disabled: true,
          }));
      }
      const imageSource = siteMaps.filter(
        (map) => map.floor === currentFloor
      )[0];
      if (imageSource) {
        setImageSource(imageSource.link);
      }
    }
    // if it's a unit
    else if (selectedUnitId !== -1) {
      const unitFloor = project.units.filter(
        (unit) => unit.threeDId === props.selectedUnitId
      )[0];

      const currentFloor =
        unitFloor.floor !== undefined || unitFloor.floor !== null
          ? unitFloor.floor
          : 1;

      const floorSitemap = siteMaps.filter(
        (sm) => sm.floor === currentFloor
      )[0];
      if (floorSitemap) {
        areas = floorSitemap.areas
          .filter((area) => area.id === selectedUnitId)
          .map((area) => ({ ...area, preFillColor: highlightColor }));
      }
      const imageSource = siteMaps.filter(
        (map) => map.floor === currentFloor
      )[0];
      if (imageSource) {
        setImageSource(imageSource.link);
      }
    }
    setHighlightedAreas(areas);
  }, [
    currentFloor,
    selectedUnitId,
    props.selectedUnitId,
    props.isUnitDetailOpen,
  ]);

  const renderImageMapper = (areas) => {
    if (!imageSource || areas.length === 0) {
      return <></>;
    }

    return (
      <div className={classes.siteMapWrapper}>
        <div className={`${classes.wrapper}`}>
          <div className={classes.sitemap}>
            <ImageMapper
              src={imageSource}
              map={{ name: project.slug, areas }}
              stayHighlighted={true}
              responsive={true}
              parentWidth={props.imageWidth || 450}
            />
          </div>
        </div>
        {levels.length > 1 && (
          <LevelPicker
            hasBackground={false}
            levels={levels}
            brandingColor={ThemeData.colours.branding}
            onLevelClick={(level) => setCurrentFloor(level)}
            levelsPerPage={3}
          />
        )}
      </div>
    );
  };

  return renderImageMapper(highlightedAreas);
};

export default SiteMap;
