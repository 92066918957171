import { Link, useHistory, useLocation } from 'react-router-dom';

import { createUseStyles } from 'react-jss';
import SlugData from '../data/SlugData';
import { portraitMode } from 'util/helper';
import { useContext, useEffect } from 'react';
import UnitFcnContext from 'contexts/UnitFcnContext';
import SavedHomesButton from '@proximahq-dev/components/dist/components/Buttons/SavedHomesButton/SavedHomesButton';
import FloorPlanStore from '../store/floorPlanStore';

const useStyles = createUseStyles({
  nav: (props) => ({
    margin: 0,
    padding: 0,

    fontFamily: props.ThemeData.fonts.title,
    ...props.ThemeData.styles.linkColourRemoval,

    backgroundColor: props.isPortraitMode
      ? props.ThemeData.colours.navBg
      : 'black',
    // backgroundColor:'red',
    backdropFilter: props.isPortraitMode ? 'blur(6px)' : 'none',
    display: 'flex',
    '& > *': {
      flex: '0 1 auto',
    },

    '& ul': {
      margin: 0,
      padding: 0,
      height: props.isPortraitMode
        ? 'var(--navigation-height)'
        : props.isBigScreen
          ? 200
          : 'var(--navigation-height)',

      flex: '1 1 auto',

      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      '& li': {
        '@media (min-width: 1250px)': {
          width: '20%',
        },
        flex: '1 1 auto',
        // border: '1px solid red',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        listStyleType: 'none',

        '&:hover a': {
          opacity: 1,
          fontSize: (props) => (props.isBigScreen ? 28 : 14),
          transition: 'all .4s ease',
        },
        '& div a': {
          color: props.isPortraitMode
            ? `${props.ThemeData.colours.content} !important`
            : props.ThemeData.colours.primaryFont,
          opacity: props.isPortraitMode ? 1 : 0.5,
          textTransform: props.isPortraitMode ? 'capitalize' : 'uppercase',
          fontSize: props.isPortraitMode
            ? props.isBigScreen
              ? 32
              : 16
            : props.isBigScreen
              ? 28
              : 14,
          fontWeight: props.isPortraitMode ? 700 : 400,
          textDecoration: 'none',
          lineHeight: props.isPortraitMode
            ? 'normal'
            : props.isBigScreen
              ? '44px'
              : '22px',
          letterSpacing: props.isPortraitMode ? '10%' : 'normal',
        },
        '&.firstItem div$navDivider': {
          borderLeft: 'none',
        },
        '&.lastItem div$navDivider': {
          //borderRight: `2px ${props.ThemeData.colours.vibrantGreen} solid`,
        },
        '& .currentUnderline': {
          position: 'relative',
          display: 'none',
          top: 10,
          width: props.isBigScreen ? 80 : 40,
          borderTop: `4px ${props.ThemeData.colours.navCurrentUnderline} solid`,
          margin: '-4px auto 0 auto',
          padding: 0,
        },
        '&.currentItem .currentUnderline': {
          display: 'block',
        },
        '@media (max-width: 1250px)': {
          '&.longName': {
            width: '20%',
          },
          '&.shortName': {
            width: '10%',
          },
          '&.mediumName': {
            width: '15%',
          },
        },
      },
      '& li.currentItem div a': {
        opacity: 1,
      },
    },
  }),
  navPortrait: (props) => ({
    position: 'absolute',
    bottom: 0,
    zIndex: 1000,
    width: '100%',
  }),
  navDivider: (props) => ({
    height: props.isBigScreen ? 44 : 22,

    display: 'block',

    flex: '0 1 auto',

    borderLeft: props.isPortraitMode
      ? `2px ${props.ThemeData.colours.vibrantGreen} solid`
      : `2px ${props.ThemeData.colours.thinBorder} solid`,
  }),
  savedHomeIcon: (props) => ({
    cursor: 'pointer',
    marginTop: '-4px',
    margin: props.isPortraitMode ? '0 20px' : '',
    opacity: props.isSavedHomesSlideoutOpen ? '1.0' : '0.5',
    '&:hover ': {
      opacity: '1.0',
    },
  }),

  arrowEnclosure: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 60,
    height: 100,

    '& a': {
      flex: '0 0 auto',
    },
  },
  arrow: {
    flex: '0 0 auto',
    height: 25,
    width: 25,

    fontSize: 25,
    lineHeight: '25px',
    transition: 'all 0.66s ease',

    cursor: 'pointer',

    '& .imageHolder': {
      width: '100%',
      height: '100%',
    },

    '&.arrowDisabled': {
      cursor: 'default',

      background: 'rgba( 0, 0, 0, 0 )',

      '& .imageHolder': {
        opacity: 0.4,
      },
    },
  },
  leftArrowEnclosure: (props) => ({
    borderRight: (props) => `1px ${props.ThemeData.colours.thinBorder} solid`,

    ...props.ThemeData.ux.fillToLeft,
  }),
  rightArrowEnclosure: (props) => ({
    borderLeft: (props) => `1px ${props.ThemeData.colours.thinBorder} solid`,

    ...props.ThemeData.ux.fillToRight,
  }),
});

function Nav ({
  project,
  navItems,
  navLocation,
  isNavBar,
  toggleIsSavedHomesSlideoutOpen,
  ...props
}) {
  const isPortraitMode = portraitMode();
  const history = useHistory();
  const { projectSlug } = FloorPlanStore();

  const { isBigScreen, savedHomesCount, isSavedHomesSlideoutOpen } =
    useContext(UnitFcnContext);
  const classes = useStyles({
    ThemeData: project.ThemeData,
    isPortraitMode,
    isBigScreen,
    isSavedHomesSlideoutOpen,
  });

  const location = useLocation();
  const currentLocationPathname = SlugData.determineCurrentLocation(
    location.pathname,
  );

  function genSectionLink (nav) {
    if (nav.page && nav.pages[0].customUrl) return `/${nav.pages[0].customUrl}`;

    return '/' + (nav.slug === SlugData.HOME_SLUG_NAME ? '' : nav.slug);
  }

  useEffect(() => {}, [isNavBar]);

  const showSavedHomes = projectSlug !== 'helpstpauls';

  const getDisplayName = ({ name }) => {
    if (name === 'About') {
      return ['ortus', '1000craigflower'].includes(projectSlug) ? 'Team' : projectSlug === 'helpstpauls' ? 'Overview' : name;
    }

    if (name === 'Overview') {
      const customOverviewSlugs = ['ortus', 'bmp', 'helpstpauls', '1000craigflower'];
      return customOverviewSlugs.includes(projectSlug) ? 'Home' : name;
    }

    if (name === 'Neighbourhood') {
      return projectSlug === '120fifthavebk' ? 'Neighborhood' : name;
    }

    if (name === 'Features') {
      return projectSlug === 'helpstpauls' ? 'Video Tours' : name;
    }

    return name;
  };

  return (
    <nav
      id={navLocation}
      className={`${classes.nav} ${isPortraitMode ? classes.navPortrait : ''}`}
    >
      {navItems && (
        <ul>
          {!isNavBar && showSavedHomes && (
            <li className="firstItem">
              <div className={classes.navDivider}>
                <div
                  className={classes.savedHomeIcon}
                  onClick={toggleIsSavedHomesSlideoutOpen}
                >
                  <SavedHomesButton
                    count={savedHomesCount}
                    isBigScreen={isBigScreen}
                    isNavBar={true}
                  />
                </div>
              </div>
            </li>
          )}
          {navItems.map((navItem, idx) => {
            return (
              <li
                onClick={() => history.push(genSectionLink(navItem))}
                className={`${idx === 0 && (isNavBar || !showSavedHomes) ? 'firstItem' : ''} ${
                  idx === navItems.length - 1 ? 'lastItem' : ''
                } ${
                  navItem.name.length > 10
                    ? 'longName'
                    : navItem.name.length < 6
                      ? 'shortName'
                      : 'mediumName'
                } ${
                  ((navItem.pages.length > 0 && navItem.pages[0].customUrl) ||
                    navItem.slug) === currentLocationPathname
                    ? 'currentItem'
                    : ''
                }`}
                key={navItem.id}
              >
                <div className={classes.navDivider}>
                  <Link to={genSectionLink(navItem)}>{getDisplayName(navItem)}</Link>
                </div>
                <div className="currentUnderline"></div>
              </li>
            );
          })}
        </ul>
      )}
    </nav>
  );
}

export default Nav;
